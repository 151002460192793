import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { GoPlus } from "react-icons/go";
import { fetchAllData, fetchData, postData } from "../../utility/fetcher";
import { HiMiniXMark } from "react-icons/hi2";
import { Toaster, toast } from "sonner";
import { copyToClipboard } from "../../utility/copyToClipboard";
import { LuCopy } from "react-icons/lu";

export const Individual = () => {
    const [loading, setLoading] = useState(true);
    const [userCitations, setUserCitations] = useState([]);
    const [userData, setUserData] = useState(JSON.parse(sessionStorage.getItem("individualUser")));
    const orgData = JSON.parse(localStorage.getItem("orgData"));
    const currentOrg = JSON.parse(localStorage.getItem("orgData")).orgName;
    const { email, permits, settings } = userData || {};
    const activePermits = permits.filter(permit => typeof permit.expirationDate !== "object" || new Date(permit.expirationDate) >= new Date());
    const vehicles = settings.vehicles;
    const personalInfo = settings.personalInfo;
    const { firstName, lastName } = personalInfo;
    const orgPermits = orgData.permitTypes;

    const getNewData = async () => {
        const userDataResponse = await fetchData("/general/spotUsers?email=" + email);
        sessionStorage.setItem("individualUser", JSON.stringify(userDataResponse));
        setUserData(userDataResponse);
        // `/general/citations?LicensePlate=${currentPlate}&State=${vehicles[i].state}`;

        const allVehicles = userDataResponse.settings.vehicles;

        let urls = {};
        for (let i in allVehicles) {
            urls[`${allVehicles[i].licensePlate}`] = `/general/citations?LicensePlate=${allVehicles[i].licensePlate}&State=${allVehicles[i].state}`;
        }

        let vehicleCitations = [];
        const citationResponse = await fetchAllData(urls);

        for (let i in citationResponse) {
            vehicleCitations.push(citationResponse[i]);
        };

        if (vehicleCitations[0].LicensePlate) {
            setUserCitations(vehicleCitations);
        } else {
            setUserCitations([]);
        };

        setLoading(false);
    };

    // Initial get
    useEffect(() => {
        getNewData();
        // eslint-disable-next-line
    }, []);

    // Handlers
    const handleDeletePermit = async (permit) => {
        const newPermits = permits.filter(p => p.permitNumber !== permit.permitNumber);
        userData.permits = newPermits;
        console.log(newPermits)

        sessionStorage.setItem("individualUser", JSON.stringify(userData));

        // Save to DB
        const response = await postData("/general/spotUsers?email=" + email, userData);
        console.log(response)

        getNewData();
    }

    const handleDeleteVehicle = async (permit) => {
        const newVehicles = vehicles.filter(p => p.vin !== permit.vin);
        userData.settings.vehicles = newVehicles;
        console.log(newVehicles)

        sessionStorage.setItem("individualUser", JSON.stringify(userData));

        // Save to DB
        const response = await postData("/general/spotUsers?email=" + email, userData);
        console.log(response)

        getNewData();
    };

    const handleSaveNewPermit = async () => {
        if (!newPermit.description || !showDates) {
            if (!showDates) {
                toast.error("Please select an interval.");
                return;
            } else {
                toast.error("Please select a permit type.");
                return;
            };
        };

        const orgPermitObj = orgPermits.find(permit => permit.description === newPermit.description);
        const permitLetter = orgPermitObj.permitType;

        const newPermitObj = {
            ...newPermit,
            activationDate: dates.officialStartDate,
            permitNumber: (Math.floor(Math.random() * 1000000000)).toString(),
            expirationDate: dates.officialEndDate,
            organization: currentOrg,
            type: permitLetter,
            price: '0.00',
        }

        userData.permits.push(newPermitObj);
        sessionStorage.setItem("individualUser", JSON.stringify(userData));

        // Save to DB
        const response = await postData("/general/spotUsers?email=" + email, userData);
        console.log(response)

        setShowModal(false);
    };

    const handleDeleteCitation = async (plate, state, citation) => {
        console.log(plate, state, citation);
        const updatedCitations = userCitations.map(vehicleCitation => {
            if (vehicleCitation.LicensePlate === plate) {
                return {
                    ...vehicleCitation,
                    Citations: vehicleCitation.Citations.filter(c => c.citationID !== citation.citationID)
                };
            }
            return vehicleCitation;
        });

        setUserCitations(updatedCitations);
        const currentPlateObj = updatedCitations.find(vehicleCitation => vehicleCitation.LicensePlate === plate)

        // Save to DB
        const response = await postData(`/general/citations?LicensePlate=${plate}&State=${state}`, currentPlateObj);
        console.log(response);

        getNewData();
    };

    // Tiles
    const vehicleTile = (vehicle) => {
        return (
            <div key={`${vehicle.make}-${vehicle.model}-${vehicle.color}`} className="flex flex-col mt-4 border min-w-fit border-gray-100 rounded-xl shadow-md p-8 w-full group relative">
                <div
                    className="absolute border -top-2 -right-2 rounded-full bg-white p-1 opacity-0 group-hover:opacity-100 transition-all hover:bg-red-100 hover:border-red-100 cursor-pointer duration-100"
                    onClick={() => handleDeleteVehicle(vehicle)}
                >
                    <HiMiniXMark size={24} />
                </div>
                <h3 className="lg:text-2xl text-lg w-fit font-semibold mb-1">{vehicle.make} {vehicle.model}</h3>
                <div
                    className="flex flex-row justify-start gap-2 w-fit items-center cursor-pointer mb-1"
                    onClick={() => copyToClipboard(vehicle.licensePlate)}
                    aria-label="Copy Plate Number"
                >
                    <h2 className="text-lg"><strong className="cursor-default">Plate:</strong> {vehicle.licensePlate}</h2>
                    <LuCopy size={15} className="text-gray-500" />
                </div>
                <h2 className="text-lg w-fit"><strong className="cursor-default mb-1">Color:</strong> {vehicle.color}</h2>
                <h2 className="text-lg w-fit"><strong className="cursor-default mb-1">Type:</strong> {vehicle.type}</h2>
                <div
                    className="flex flex-row justify-start gap-2 w-fit items-center cursor-pointer mb-1"
                    onClick={() => copyToClipboard(vehicle.vin)}
                    aria-label="Copy Vin"
                >
                    <h2 className="text-sm font-mono"><strong className="cursor-default">VIN:</strong> {vehicle.vin}</h2>
                    <LuCopy size={15} className="text-gray-500" />
                </div>
                <Toaster />
            </div>
        )
    }

    const permitTile = (permit) => {
        const orgPermitColor = orgPermits.find(orgPermit => orgPermit.description === permit.description).color

        return (
            <div
                className="flex flex-col relative group gap-4 mt-4 border border-gray-100 rounded-xl shadow-md p-8 w-full"
                aria-label="Permit"
                key={permit.permitNumber}
            >
                <div
                    className={`absolute top-0 right-0
                        border-b-0
                        border-r-0
                        border-l-[80px]
                        border-t-[80px]
                        border-solid
                        border-transparent
                        rounded-tr-[6px]
                        bg-transparent`
                    }
                    style={{
                        borderTopColor: orgPermitColor,
                    }}
                ></div>
                <div
                    className="absolute border -top-2 -right-2 rounded-full bg-white p-1 opacity-0 group-hover:opacity-100 transition-all hover:bg-red-100 hover:border-red-100 cursor-pointer duration-100"
                    onClick={() => handleDeletePermit(permit)}
                >
                    <HiMiniXMark size={24} />
                </div>
                <h3 className="text-2xl w-fit font-semibold">{permit.description} ({permit.type})</h3>
                <div className="flex flex-col justify-start">
                    <h2 className="text-xl w-fit font-bold">Activation:</h2>
                    <h2 className="text-xl w-fit">{new Date(permit.activationDate).toLocaleDateString()}</h2>
                </div>
                <div className="flex flex-col justify-start">
                    <h2 className="text-xl w-fit font-bold">Expiration:</h2>
                    <h2 className="text-xl w-fit">{
                        ['Indefinite', 'N/A'].includes(permit.expirationDate) ?
                            permit.expirationDate :
                            new Date(permit.expirationDate).toLocaleDateString()
                    }</h2>
                </div>
                {permit.confirmationNumber && (
                    <div className="flex flex-col justify-start">
                        <h2 className="text-xl w-fit font-bold">Confirmation Number:</h2>
                        <h2 className="text-xl w-fit">{permit.confirmationNumber}</h2>
                    </div>
                )}
            </div>
        );
    }

    const citationTile = (vehicleCitation) => {
        const singleCitation = (citation) => {
            let color = "bg-gray-100";
            let status = "UNPAID";

            if (citation.paid) {
                color = "bg-green-100";
                status = "Paid";
            } else if (citation.appeals && citation.appeals.length > 0) {
                const lastAppeal = citation.appeals[citation.appeals.length - 1];
                if (lastAppeal.status === "Approved") {
                    status = "Appeal Approved";
                } else if (lastAppeal.status === "Denied") {
                    color = "bg-red-100";
                    status = "Appeal Denied";
                } else {
                    color = "bg-yellow-100";
                    status = "Appeal Pending";
                }
            } else {
                color = "bg-red-100";
            }

            return (
                <div
                    className={`flex flex-col gap-1 mt-4 border relative group border-gray-100 hover:shadow-md cursor-pointer rounded-md p-6 w-full ${color}`}
                >
                    <div
                        className="absolute border -top-2 -right-2 rounded-full bg-white p-1 opacity-0 group-hover:opacity-100 transition-all hover:bg-red-100 hover:border-red-100 cursor-pointer duration-100"
                        onClick={(e) => {
                            e.stopPropagation();
                            handleDeleteCitation(vehicleCitation.LicensePlate, vehicleCitation.State, citation);
                        }}
                    >
                        <HiMiniXMark size={24} />
                    </div>
                    <Link
                        aria-label="Citation"
                        to={`/analytics/citations/${citation.citationID}`}
                        onClick={() => sessionStorage.setItem("currentCitation", JSON.stringify(citation))}
                        className="flex flex-col gap-1 w-full"
                    >
                        <h3 className="text-2xl w-fit font-semibold pb-2">{citation.type}</h3>
                        <h3 className="text-lg w-fit"><strong>Citation ID:</strong> {citation.citationID}</h3>
                        <h2 className="text-lg w-fit"><strong>Date:</strong> {new Date(citation.timeWritten).toLocaleDateString()}</h2>
                        <h2 className="text-lg w-fit"><strong>Spot:</strong> {citation.lot}-{citation.spot}</h2>
                        <h2 className="text-lg w-fit"><strong>Fee:</strong> ${parseFloat(citation.fee).toFixed(2)}</h2>
                        <h2 className="text-lg w-fit"><strong>Status:</strong> {status}</h2>
                    </Link>
                </div>
            );
        }
        return (
            <div className={`flex flex-col gap-1 mt-6 border border-gray-100 rounded-xl shadow-md p-6 w-full`}>
                <p className="font-semibold text-2xl">
                    {vehicleCitation.LicensePlate} ({vehicleCitation.State})
                </p>
                {vehicleCitation.Citations?.map(citation => (
                    singleCitation(citation)
                ))}
            </div>
        )
    };

    const [showModal, setShowModal] = useState(false);

    const customInvtervals = Object.keys(orgData.customDurations);
    const customPermits = orgData.permitTypes.filter(permit => customInvtervals.includes(permit.interval)).map(permit => permit.description);

    const emptyPermit = {
        activationDate: "",
        confirmationNumber: "",
        description: "",
        expirationDate: "",
        organization: "",
        price: "",
        purchaseDate: new Date(),
        type: ""
    }

    // New States
    const [newPermit, setNewPermit] = useState(emptyPermit);
    const [currentDurations, setCurrentDurations] = useState([]);
    const [chosenInterval, setChosenInterval] = useState("");
    const [showDates, setShowDates] = useState(false);
    const [dates, setDates] = useState({});

    useEffect(() => {
        const permitInterval = orgPermits.find(permit => permit.description === newPermit.description)?.interval;
        const customDurations = orgData.customDurations[permitInterval] || [];
        setCurrentDurations(customDurations);

        if (customPermits.includes(newPermit.description) || newPermit.description === "") {
            setShowDates(false)
            setDates({
                officialStartDate: new Date(),
                officialEndDate: ""
            });
        } else {
            const orgPermitData = orgPermits.find(permit => permit.description === newPermit.description);
            const duration = orgPermitData?.interval;
            let expirationDate = new Date();

            if (duration === "1 Hour") {
                expirationDate.setHours(expirationDate.getHours() + 1);
            } else if (duration === "2 Hours") {
                expirationDate.setHours(expirationDate.getHours() + 2);
            } else if (duration === "1 Day") {
                expirationDate.setDate(expirationDate.getDate() + 1);
            } else if (duration === "1 Weeek") {
                expirationDate.setDate(expirationDate.getDate() + 7);
            } else if (duration === "1 Monthy") {
                expirationDate.setMonth(expirationDate.getMonth() + 1);
            } else if (duration === "1 Year") {
                expirationDate.setFullYear(expirationDate.getFullYear() + 1);
            } else if (duration === "Indefinite") {
                expirationDate = "Indefinite";
            } else {
                expirationDate = "N/A";
            }

            setDates({
                officialStartDate: new Date(),
                officialEndDate: expirationDate
            });
            setShowDates(true)
        }
        // eslint-disable-next-line
    }, [newPermit.description]);

    useEffect(() => {
        const intervalObj = currentDurations.find(duration => duration.name === chosenInterval) || {};
        const startDay = intervalObj.startDay;
        const endDay = intervalObj.endDay;
        const startMonth = intervalObj.startMonth;
        const endMonth = intervalObj.endMonth;

        const year = new Date().getFullYear();
        let officialStartDate = new Date(`${startMonth} ${startDay} ${year}`);
        let officialEndDate = new Date(`${endMonth} ${endDay} ${year}`);

        if (officialStartDate > officialEndDate) {
            if (officialEndDate < new Date()) {
                officialStartDate = new Date(`${startMonth} ${startDay} ${year + 1}`);
                officialEndDate = new Date(`${endMonth} ${endDay} ${year + 2}`);
            } else {
                officialEndDate = new Date(`${endMonth} ${endDay} ${year + 1}`);
            }
        }

        setDates({
            officialStartDate,
            officialEndDate
        });
        setShowDates(true);
        // eslint-disable-next-line
    }, [chosenInterval]);

    const closeModal = () => {
        setChosenInterval("");
        setShowDates(false);
        setCurrentDurations([]);
        setNewPermit(emptyPermit);
        setDates({});
        setShowModal(false);
    }

    return (
        <div className="flex flex-col ml-24 p-8 h-screen font-rubik">
            <div className="flex flex-col w-full py-8 border-b border-gray-300">
                <h1 className="font-bold text-4xl w-fit pb-2">{firstName} {lastName}</h1>
                <h2 className="text-2xl w-fit">{email}</h2>
            </div>
            <div className="flex flex-row h-full">
                <div key={"car-tiles"} className="flex flex-col gap-2 flex-1 w-full mt-8">
                    <h1 className="font-bold text-xl lg:text-2xl xl:text-3xl w-fit pb-2">Vehicles</h1>
                    {vehicles.length === 0 ?
                        <h1 className="xl:text-2xl text-lg w-full text-left pt-4">No vehicles found</h1> :
                        vehicles.map(vehicle => (
                            vehicleTile(vehicle)
                        ))}
                </div>
                <div className="border-l border-gray-300 mx-6 mt-12"></div>
                <div className="flex flex-col gap-2 flex-1 w-full mt-8">
                    <h1 className="font-bold w-full text-left text-xl lg:text-2xl xl:text-3xl pb-2">Active Permits</h1>
                    {activePermits.map(permit => (
                        permitTile(permit)
                    ))}
                    <div className="border w-24 h-24 mt-4 rounded-lg cursor-pointer fcc hover:shadow-md transition-all duration-100" onClick={() => setShowModal(true)}>
                        <GoPlus className="text-6xl m-auto text-gray-400" />
                    </div>
                </div>
                <div className="border-l border-gray-300 mx-6 mt-12"></div>
                <div className="flex flex-col flex-1 w-full mt-8">
                    <h1 className="font-bold text-xl lg:text-2xl xl:text-3xl w-fit pb-2">Citations</h1>
                    {userCitations.length === 0 ?
                        loading ? <h1 className="xl:text-2xl text-lg w-full text-center pt-4">Loading...</h1> :
                            <h1 className="xl:text-2xl text-lg w-full text-left pt-4">No citations found</h1> :
                        userCitations.map(vehicleCitation => (
                            citationTile(vehicleCitation)
                        ))
                    }
                </div>
            </div>

            {/* NEW PERMIT MODAL */}
            {showModal && (
                <div>
                    <div onClick={closeModal} className="fixed top-0 left-0 w-screen h-screen bg-black bg-opacity-50 z-[100]"></div>
                    <div className="fixed top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 w-[500px] bg-white shadow-lg rounded-lg z-[101] p-4">
                        <h1 className="text-4xl font-bold w-full text-center py-4">New Permit</h1>
                        <div className="flex flex-col gap-4 p-4">
                            <label className="text-xl font-semibold">Permit Type</label>
                            <select
                                className="border border-gray-300 outline-spotYellow rounded-lg p-2"
                                onChange={(e) => setNewPermit({ ...newPermit, description: e.target.value })}
                            >
                                <option value={""}></option>
                                {orgPermits.map(permit => (
                                    <option value={permit.description}>{permit.description}</option>
                                ))}
                            </select>
                            {customPermits.includes(newPermit.description) && (
                                <div className="flex flex-col gap-4 justify-center">
                                    <label className="text-xl font-semibold">Interval</label>
                                    <select
                                        className="border border-gray-300 outline-spotYellow rounded-lg p-2"
                                        onChange={(e) => setChosenInterval(e.target.value)}
                                    >
                                        <option value={""}></option>
                                        {currentDurations.map(duration => (
                                            <option value={duration.name}>{duration.name}</option>
                                        ))}
                                    </select>
                                </div>
                            )}
                            {showDates && (
                                <div className="flex flex-row justify-between mx-10">
                                    <div className="flex flex-col gap-2 justify-center">
                                        <label className="text-xl font-semibold">Activation Date</label>
                                        <p>
                                            {Object.keys(dates).length !== 0 ? dates.officialStartDate.toLocaleString('en-US', {
                                                month: 'long',
                                                day: 'numeric',
                                                year: 'numeric'
                                            }) : new Date().toLocaleString('en-US', {
                                                month: 'long',
                                                day: 'numeric',
                                                year: 'numeric'
                                            })}
                                        </p>
                                    </div>
                                    <div className="flex flex-col gap-2 justify-center">
                                        <label className="text-xl font-semibold">Expiration Date</label>
                                        <p>
                                            {Object.keys(dates).length !== 0 ? dates.officialEndDate.toLocaleString('en-US', {
                                                month: 'long',
                                                day: 'numeric',
                                                year: 'numeric'
                                            }) :
                                                orgPermits.find(permit => permit.description === newPermit.description)?.interval
                                            }
                                        </p>
                                    </div>
                                </div>
                            )}
                            <button onClick={handleSaveNewPermit} className="bg-gray-400 hover:bg-spotYellow hover:text-spotGray text-white font-semibold rounded-lg mt-4 p-2 transition-all duration-100">Create Permit</button>
                        </div>
                    </div>
                </div>
            )}
            <Toaster richColors />
        </div>
    );
}