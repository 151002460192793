import './App.css';
import React, { useState, useCallback } from 'react';
import Dashboard from './dashboard/Dashboard';
import MapView from './liveMap/MapView';
import { CurrentViolations } from "./analytics/currentViolations/CurrentViolations";
import { PlateHistory } from "./analytics/plateHistory/PlateHistory";
import { History } from "./analytics/plateHistory/History";
import ViolationEditor from "./analytics/plateHistory/ViolationEditor";
import { MyAccount } from './settings/MyAccount';
import { Users } from './settings/Users';
import { Lots } from './settings/Lots';
import { Permits } from './settings/Permits';
import { Announcements } from './settings/Announcements';
import { Help } from './settings/Help';
import Signup from './login/SignUp';
import Login from './login/Login';
import ResetPassword from './login/ResetPassword';
import ContactUs from './login/ContactUs';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Cookies from 'js-cookie';
import Sidebar from './Sidebar';
import { Citations } from './analytics/Citations';
import { SinglePermit } from './settings/SinglePermit';
import { Individual } from './settings/individual/Individual';
import { Appeals } from './analytics/Appeals';
import { SingleCitation } from './analytics/SingleCitation';
import { Cameras } from './settings/Cameras';
import { ViolationTypes } from './settings/ViolationTypes';
import { ConfirmEmail } from './login/ConfirmEmail';
import SpeedCite from './analytics/currentViolations/SpeedCite';
import { Payments } from './settings/Payments';
import { CustomDurations } from './settings/CustomDurations';
import { GoogleOAuthProvider } from '@react-oauth/google';

function App() {
    const token = Cookies.get("token");
    const [topLoading, setTopLoading] = useState(false);

    // Memoize the setter function
    const handleSetValue = useCallback((newValue) => {
        setTopLoading(newValue);
    }, []);

    const allowedPaths = ["/login", "/contact-us", "/reset-temp-password", "/reset-password", "/signup", "/confirmEmail"];

    if (token === undefined) {
        sessionStorage.clear();
        localStorage.clear();
        Cookies.remove("token");

        if (!allowedPaths.includes(window.location.pathname)) {
            window.location.href = "/login";
        };
    };

    return (
        <GoogleOAuthProvider clientId="403367791110-0ohc25riqv9o3tqtepvlvajgkpqms8ha.apps.googleusercontent.com">
            <Router>
                {!allowedPaths.includes(window.location.pathname) && !topLoading ? <Sidebar /> : null}
                <Routes>
                    <Route exact path="/" element={<Dashboard topLoading={topLoading} setTopLoading={handleSetValue} />} />
                    <Route path="/map" element={<MapView />} />
                    <Route path="/analytics/current-violations" element={<CurrentViolations />} />
                    <Route path="/analytics/speed-cite" element={<SpeedCite />} />
                    <Route path="/analytics/history" element={<History />} />
                    <Route path="/analytics/plate-history/" element={<PlateHistory />} />
                    <Route path="/analytics/plate-history/edit" element={<ViolationEditor />} />
                    <Route path="/analytics/citations" element={<Citations />} />
                    <Route path="/analytics/citations/:citationID" element={<SingleCitation />} />
                    <Route path="/analytics/appeals" element={<Appeals />} />
                    <Route path="/announcements" element={<Announcements />} />
                    {/* Login Stuff */}
                    <Route path="/signup" element={<Signup />} />
                    <Route path="/confirmEmail" element={<ConfirmEmail />} />
                    <Route path="/login" element={<Login />} />
                    <Route path="/reset-password" element={<ResetPassword />} />
                    <Route path="/contact-us" element={<ContactUs />} />
                    {/* Settings */}
                    <Route path="/settings/my-account" element={<MyAccount />} />
                    <Route path="/settings/my-account/violation-types" element={<ViolationTypes />} />
                    <Route path="/settings/my-account/payments" element={<Payments />} />
                    <Route path="/settings/users" element={<Users />} />
                    <Route path="/settings/users/:user" element={<Individual />} />
                    <Route path="/settings/lots" element={<Lots />} />
                    <Route path="/settings/lots/cameras" element={<Cameras />} />
                    <Route path="/settings/permits" element={<Permits />} />
                    <Route path="/settings/customDurations" element={<CustomDurations />} />
                    <Route path="/settings/permits/:permitType" element={<SinglePermit />} />
                    <Route path="/help" element={<Help />} />
                </Routes>
            </Router>
        </GoogleOAuthProvider>
    );
}

export default App;
