import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Toaster, toast } from "sonner"
import { fetchAllData, postData } from "../utility/fetcher";
import { IoMdPerson } from "react-icons/io";

export const Users = () => {
    const [initUserData, setInitUserData] = useState([]); // All Spot Users
    const [userData, setUserData] = useState([]); // Spot Users for the current organization
    const [changedUsers, setChangedUsers] = useState([]);
    const [saveMessage, setSaveMessage] = useState("Save");
    const [checkedIndexes, setCheckedIndexes] = useState([]);
    const [loading, setLoading] = useState(true);
    const currentOrg = JSON.parse(localStorage.getItem("orgData")).orgName;
    const userMainEmail = localStorage.getItem("email");

    const storeData = async () => {
        try {
            const response = await fetchAllData({ "employeeData": "/general/spotUsers" });
            setInitUserData(response.employeeData);
        } catch (error) {
            console.error("Error fetching data:", error);
        }
        setLoading(false);
    };

    const handleCheck = (index) => {
        const updatedIndexes = [...checkedIndexes];
        if (updatedIndexes.includes(index)) {
            updatedIndexes.splice(updatedIndexes.indexOf(index), 1);
        } else {
            updatedIndexes.push(index);
        }
        setCheckedIndexes(updatedIndexes);
    };

    const handleEdit = (index, field, value) => { //TODO This doesn't work for some reason only for emails
        // Update the tableData array with the new value
        if (field === "firstName" || field === "lastName") {
            const updatedUserData = [...userData];
            updatedUserData[index].settings.personalInfo[field] = value;
            setUserData(updatedUserData);
        } else if (field === "email") {
            const updatedUserData = [...userData];
            updatedUserData[index].email = value;
            setUserData(updatedUserData);
        } else {
            const updatedTableData = [...userData];
            updatedTableData[index][field] = value;
            setUserData(updatedTableData);
        }
    };

    const handlePermissionChange = (index, app) => {
        const newData = [...userData];
        const userEmail = newData[index].email;
        newData[index].apps[currentOrg][app] = !newData[index].apps[currentOrg][app]; // Toggle the permission
        setUserData(newData);
        const currentChanges = [...changedUsers, userEmail];
        const uniqueChanges = [...new Set(currentChanges)];
        setChangedUsers(uniqueChanges);
    }

    // This saves the current table data to DynamoDB
    //^ In the future I'll implement cognito stuff in order to adjust emails.
    const saveButton = () => {
        const saveData = async () => {

            if (changedUsers.length === 0) {
                toast.info("No changes detected.");
                return;
            }

            setSaveMessage("Saving...");
            for (let i in changedUsers) {
                const newUserData = userData.filter(user => user.email === changedUsers[i])[0];
                try {
                    const path = "/general/spotUsers";
                    const response = await postData(path, newUserData);
                    console.log("Response:", response.message);
                } catch (error) {
                    console.error("Error saving data:", error);
                }
            }

            setChangedUsers([]);
            toast.success("User data saved successfully!")
            setSaveMessage("Saved");

            setTimeout(() => {
                setSaveMessage("Save");
            }, 2000);
        };

        return (
            <div>
                <button
                    className="w-[150px] h-8 rounded-lg flex justify-center items-center shadow-md yellow-gradient hover:dark-yellow-gradient"
                    onClick={saveData}
                    aria-label="Save Changes"
                >
                    {saveMessage}
                </button>
            </div>
        );
    };

    // // Add new user with blank data that needs to be filled in.
    // const addButton = () => {
    //     const addNewUser = () => {
    //         const newUser = {
    //             email: "",
    //             settings: {
    //                 personalInfo: {
    //                     firstName: "",
    //                     lastName: ""
    //                 }
    //             },
    //             apps: {
    //                 [currentOrg]: {
    //                     nav: false,
    //                     enforcement: false,
    //                     admin: false
    //                 }
    //             }
    //         };
    //         const updatedTableData = [...userData];
    //         updatedTableData.push(newUser);
    //         setUserData(updatedTableData);
    //     };

    //     return (
    //         <div>
    //             <button className="block w-[150px] h-8 rounded-lg shadow-md bg-gray-100 hover:bg-gray-200" onClick={addNewUser}>
    //                 Add User
    //             </button>
    //         </div>
    //     );
    // }

    // const removeButton = () => {
    //     const removeUser = () => {
    //         const updatedTableData = userData.filter((_, index) => !checkedIndexes.includes(index));
    //         setTableData(updatedTableData);

    //         //TODO: Tell dean to make the delete function
    //         const API_URL = "https://aoe89ig17j.execute-api.us-east-1.amazonaws.com/default/spotUsers";
    //         const API_KEY = process.env.REACT_APP_API_KEY;
    //         checkedIndexes.forEach((index) => {
    //             const email = userData[index].email;
    //             fetch(`${API_URL}`, {
    //                 method: 'DELETE',
    //                 body: JSON.stringify({ email: email }),
    //                 headers: {
    //                     'Content-Type': 'application/json',
    //                     'x-api-key': API_KEY
    //                 },
    //             })
    //                 .then(response => {
    //                     if (response.ok) {
    //                         console.log(`User with email: ${email} deleted successfully.`);
    //                         console.log(response.json())
    //                     } else {
    //                         console.error(`Failed to delete user with email: ${email}.`);
    //                     }
    //                 })
    //                 .catch(error => {
    //                     console.error(`An error occurred while deleting user with email ${email}.`, error);
    //                 });
    //         });

    //         setCheckedIndexes([]);
    //     };

    //     return (
    //         <div className="block ml-2 mr-auto">
    //             <button className="block w-[150px] h-8 rounded-lg shadow-md bg-red-200 hover:bg-red-300" onClick={removeUser}>
    //                 Remove User
    //             </button>
    //         </div>
    //     );
    // }

    useEffect(() => {
        storeData();
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        let objBuild = [];
        for (let i in initUserData) {
            const apps = initUserData[i].apps;
            // eslint-disable-next-line
            Object.keys(apps).forEach(app => {
                if (app === currentOrg) {
                    objBuild.push(initUserData[i]);
                }
            })
        }

        // Sort by last name
        objBuild.sort((a, b) => a.settings.personalInfo.lastName.localeCompare(b.settings.personalInfo.lastName));
        objBuild = objBuild.filter(user => user.apps[currentOrg].admin === true); //TODO Remove this when it goes live
        setUserData(objBuild);
        // eslint-disable-next-line
    }, [initUserData]);

    return (
        <div>
            <Toaster richColors />
            <div className="flex flex-col m-4 ml-20 font-rubik">
                <h1 className="font-bold text-3xl m-4 justify-center">Users</h1>
                {loading ? <h1 className="text-center text-2xl mt-10">Loading...</h1> :
                    userData.length === 0 ? <h1 className="text-center text-2xl">No users found.</h1> :
                        <div className="flex flex-col w-full">
                            <table>
                                <thead>
                                    <tr className="table-auto text-xl">
                                        <th className="p-2">Select</th>
                                        <th className="p-2 w-48 flex justify-center mt-[14px] text-center">First Name</th>
                                        <th className="p-2">Last Name</th>
                                        <th className="p-2">Email</th>
                                        <th className="p-2">Student App</th>
                                        <th className="p-2">Employee App</th>
                                        <th className="p-2">Admin Portal</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {userData.map((user, index) => (
                                        <tr key={index}>
                                            <td className={`p-2 border-b border-gray-300 ${checkedIndexes.includes(index) && "bg-yellow-100"}`}>
                                                <div className="flex justify-center p-2 items-center">
                                                    <input
                                                        type="checkbox"
                                                        alt="Select"
                                                        className={`w-[15px] h-[15px] cursor-pointer ${checkedIndexes.includes(index) ? "accent-spotGray" : "accent-spotYellow"}`}
                                                        onChange={(e) => handleCheck(index)}
                                                    />
                                                </div>
                                            </td>
                                            <td className={`p-2 border-b frc w-full border-gray-300 ${checkedIndexes.includes(index) && "bg-yellow-100"}`}>
                                                <Link
                                                    to={`/settings/users/${userData[index].settings.personalInfo.firstName}-${userData[index].settings.personalInfo.lastName}`}
                                                    onClick={() => sessionStorage.setItem("individualUser", JSON.stringify(userData[index]))}
                                                    aria-label="View User"
                                                >
                                                    <IoMdPerson className="w-5 h-5 text-spotGray cursor-pointer" />
                                                </Link>
                                                <input
                                                    type="text"
                                                    alt="First Name"
                                                    value={user.settings.personalInfo.firstName}
                                                    className={`p-2 text-left w-24 outline-spotYellow ${checkedIndexes.includes(index) && "bg-yellow-100"}`}
                                                    onChange={(e) => handleEdit(index, "firstName", e.target.value)}
                                                />
                                            </td>
                                            <td className={`p-2 border-b border-gray-300 ${checkedIndexes.includes(index) && "bg-yellow-100"}`}>
                                                <input
                                                    type="text"
                                                    alt="Last Name"
                                                    value={user.settings.personalInfo.lastName}
                                                    className={`p-2 text-center w-full outline-spotYellow ${checkedIndexes.includes(index) && "bg-yellow-100"}`}
                                                    onChange={(e) => handleEdit(index, "lastName", e.target.value)}
                                                />
                                            </td>
                                            <td className={`p-2 border-b border-gray-300 ${checkedIndexes.includes(index) && "bg-yellow-100"}`}>
                                                <input
                                                    type="text"
                                                    alt="Email"
                                                    value={user.email}
                                                    className={`p-2 text-center w-full outline-spotYellow ${checkedIndexes.includes(index) && "bg-yellow-100"}`}
                                                    onChange={(e) => handleEdit(index, "email", e.target.value)}
                                                />
                                            </td>
                                            <td className={`p-2 border-b border-gray-300 ${checkedIndexes.includes(index) && "bg-yellow-100"}`}>
                                                <div className="flex justify-center p-2 items-center">
                                                    <input
                                                        type="checkbox"
                                                        alt="Student App"
                                                        checked={user.apps[currentOrg].nav}
                                                        className={`w-[15px] h-[15px] cursor-pointer ${checkedIndexes.includes(index) ? "accent-spotGray" : "accent-spotYellow"}`}
                                                        onChange={(e) => handlePermissionChange(index, "nav")}
                                                    />
                                                </div>
                                            </td>
                                            <td className={`p-2 border-b border-gray-300 ${checkedIndexes.includes(index) && "bg-yellow-100"}`}>
                                                <div className="flex justify-center p-2 items-center">
                                                    <input
                                                        type="checkbox"
                                                        alt="Employee App"
                                                        checked={user.apps[currentOrg].enforcement}
                                                        className={`w-[15px] h-[15px] cursor-pointer ${checkedIndexes.includes(index) ? "accent-spotGray" : "accent-spotYellow"}`}
                                                        onChange={(e) => handlePermissionChange(index, "enforcement")}
                                                    />
                                                </div>
                                            </td>
                                            <td className={`p-2 border-b border-gray-300 ${checkedIndexes.includes(index) && "bg-yellow-100"}`}>
                                                <div className="flex justify-center p-2 items-center">
                                                    <input
                                                        type="checkbox"
                                                        alt="Admin Portal"
                                                        checked={user.apps[currentOrg].admin}
                                                        className={`w-[15px] h-[15px] ${user.email !== userMainEmail && "cursor-pointer"} ${checkedIndexes.includes(index) ? "accent-spotGray" : "accent-spotYellow"}`}
                                                        disabled={user.email === userMainEmail}
                                                        onChange={(e) => handlePermissionChange(index, "admin")}
                                                    />
                                                </div>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                            <div className="flex flex-row justify-between mt-6 mx-4 font-bold">
                                <div className="frc gap-4">
                                    {/* {addButton()} */}
                                    {/* {removeButton()} */}
                                </div>
                                {saveButton()}
                            </div>
                        </div>
                }
            </div>
        </div>
    );
};
