import React, { useEffect, useState } from "react";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { Link } from "react-router-dom";
import { Toaster, toast } from "sonner";
import { BsPassFill } from "react-icons/bs";
import { fetchAllData, postData } from "../utility/fetcher";
import { isObjDifferent } from "../utility/isObjDifferent";
import { GoPlus } from "react-icons/go";

export const Permits = () => {
    const [tableData, setTableData] = useState([]);
    const [saveMessage, setSaveMessage] = useState("Save");
    const [checkedIndexes, setCheckedIndexes] = useState([]);
    const [different, setDifferent] = useState(false);
    const [loading, setLoading] = useState(true);
    const [violationData, setViolationData] = useState([]);
    const [errorMessage, setErrorMessage] = useState("");
    const orgData = JSON.parse(localStorage.getItem("orgData"));
    const orgID = orgData.orgID;

    const handleEdit = (index, field, value) => {
        const updatedTableData = [...tableData];
        updatedTableData[index][field] = value;
        setTableData(updatedTableData);
    };

    const handleCheck = (index) => {
        const updatedIndexes = [...checkedIndexes];
        if (updatedIndexes.includes(index)) {
            updatedIndexes.splice(updatedIndexes.indexOf(index), 1);
        } else {
            updatedIndexes.push(index);
        }
        setCheckedIndexes(updatedIndexes);
    };

    const onDragEnd = (result) => {
        if (!result.destination) return;

        const reorderedData = [...tableData];
        const [movedItem] = reorderedData.splice(result.source.index, 1);
        reorderedData.splice(result.destination.index, 0, movedItem);

        console.log(reorderedData)

        setTableData(reorderedData);
    };

    const checkForDuplicates = () => {
        if (tableData.some(permit => permit.description === newPermit.description)) {
            setErrorMessage("Permit title already exists.");
            toast.error("Permit title already exists.");
            return;
        } else if (tableData.some(permit => permit.permitType === newPermit.permitType)) {
            setErrorMessage("Permit letter already exists.");
            toast.error("Permit letter already exists.");
            return;
        } else if (tableData.some(permit => permit.color === newPermit.color)) {
            setErrorMessage("Permit color already exists.");
            toast.error("Permit color already exists.");
            return;
        };
        setErrorMessage("");
    };

    const handleAddPermit = async () => {
        if (newPermit.description === "" ||
            newPermit.permitType === "" ||
            newPermit.price === "" ||
            newPermit.color === "" ||
            newPermit.platesPerPermit === ""
        ) {
            toast.error("Please fill out all fields.");
            return;
        };

        checkForDuplicates();

        if (tableData.some(permit => permit.description === newPermit.description)) {
            toast.error("Permit title already exists.");
            return;
        } else if (tableData.some(permit => permit.permitType === newPermit.permitType)) {
            toast.error("Permit letter already exists.");
            return;
        } else if (tableData.some(permit => permit.color === newPermit.color)) {
            toast.error("Permit color already exists.");
            return;
        };

        const updatedTableData = [...tableData];
        updatedTableData.push(newPermit);
        setTableData(updatedTableData);
        setSaveMessage("Saving...");
        setShowPermitEditor(false);

        const newData = orgData;
        newData.permitTypes = updatedTableData;

        console.log("DATA TO POST:", newData);
        const path = "/general/organizations";
        const response = await postData(path, newData);
        console.log(response.message);

        if (response.message === "Successfully wrote item to DynamoDB") {
            setSaveMessage("Saved");
            setTimeout(() => {
                setSaveMessage("Save");
            }, 2000);
        } else {
            toast.error("Permit data failed to save. Please try again.");
        };
    };

    const removePermit = async () => {
        const updatedTableData = tableData.filter((_, index) => !checkedIndexes.includes(index));
        setTableData(updatedTableData);

        setSaveMessage("Saving...");
        const newData = orgData;
        newData.permitTypes = updatedTableData;

        console.log("DATA TO DELETE:", newData);
        const path = "/general/organizations";
        const response = await postData(path, newData);
        console.log(response.message);

        setSaveMessage("Saved");
        setTimeout(() => {
            setSaveMessage("Save");
        }, 2000);

        setCheckedIndexes([]);
    };

    const handleSaveClick = async () => {
        setSaveMessage("Saving...");
        const toastId = toast.loading("Saving data...");
        const newData = orgData;

        const different = isObjDifferent(newData.permitTypes, tableData);

        if (!different) {
            toast.info("No changes detected.", { id: toastId });
            setSaveMessage("Save");
            return;
        }

        newData.permitTypes = tableData;

        try {
            console.log("Data to save:", newData);
            const path = "/general/organizations";
            const response = await postData(path, newData);
            localStorage.setItem("orgData", JSON.stringify(newData));
            toast.success("Permit data saved successfully!", { id: toastId });
            console.log(response.message)

            setSaveMessage("Saved");
            setTimeout(() => {
                setSaveMessage("Save");
            }, 2000);
        } catch (error) {
            console.error("Error saving data:", error);
            toast.error("Permit data failed to save. Please try again.", { id: toastId });
        }

    };

    useEffect(() => {
        const different = isObjDifferent(tableData, JSON.parse(sessionStorage.getItem("permitTypes")));
        setDifferent(different);
    }, [tableData]);

    useEffect(() => {
        const storeData = async () => {
            try {
                const pathObj = {
                    "orgData": `/general/organizations?orgID=${orgID}`,
                    "violationData": `/${orgID}/spots`,
                };
                const response = await fetchAllData(pathObj);
                const permitTypes = response.orgData.permitTypes;
                setTableData(permitTypes);
                setViolationData(response.violationData);
                sessionStorage.setItem("permitTypes", JSON.stringify(permitTypes));
                sessionStorage.setItem("orgData", JSON.stringify(response.orgData));
                sessionStorage.setItem("violationData", JSON.stringify(response.violationData));
                setLoading(false);
            } catch (error) {
                console.error("Error getting data:", error);
                toast.error("Data failed to save. Please try again.");
            }
        };

        const handleKeyDown = (event) => {
            if (event.key === "Escape" && showPermitEditor) {
                closeEditor();
            }
        };

        storeData();

        window.addEventListener("keydown", handleKeyDown);
        return () => {
            window.removeEventListener("keydown", handleKeyDown);
        };
        // eslint-disable-next-line
    }, []);

    const allLots = orgData.lots.map((lot) => parseInt(lot.lotNum));
    const allLotsString = allLots.sort().join(", ");

    const handleColorEdit = (index, field) => {
        // Validate brightness
        const isBright = validateBrightness(tempColor);
        if (isBright) {
            const updatedTableData = [...tableData];
            updatedTableData[index][field] = tempColor;
            setTableData(updatedTableData);
        } else {
            toast.error("Color must be bright enough to be visible.");
        }
    };

    const validateBrightness = (hexColor) => {
        const rgb = hexToRgb(hexColor);
        const brightness = Math.sqrt(
            0.299 * (rgb.r ** 2) +
            0.587 * (rgb.g ** 2) +
            0.114 * (rgb.b ** 2)
        );
        return brightness > 100;
    };

    const hexToRgb = (hex) => {
        const match = hex.match(/^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i);
        return match
            ? {
                r: parseInt(match[1], 16),
                g: parseInt(match[2], 16),
                b: parseInt(match[3], 16),
            }
            : { r: 0, g: 0, b: 0 };
    };

    const blankPermit = {
        description: "",
        permitType: "",
        price: "",
        public: false,
        color: "#fff",
        platesPerPermit: "",
    }

    const [tempColor, setTempColor] = useState("");
    const [showPermitEditor, setShowPermitEditor] = useState(false);
    const [newPermit, setNewPermit] = useState(blankPermit);
    const [duration, setDuration] = useState("");

    const calculateExpiration = (duration) => {
        const now = new Date();
        switch (duration) {
            case "hour":
                return new Date(now.setHours(now.getHours() + 1));
            case "twoHour":
                return new Date(now.setHours(now.getHours() + 2));
            case "day":
                return new Date(now.setDate(now.getDate() + 1));
            case "week":
                return new Date(now.setDate(now.getDate() + 7));
            case "month":
                return new Date(now.setMonth(now.getMonth() + 1));
            case "year":
                return new Date(now.setFullYear(now.getFullYear() + 1));
            default:
                return new Date();
        };
    };

    const closeEditor = () => {
        setNewPermit(blankPermit);
        setDuration("");
        setErrorMessage("");
        setShowPermitEditor(false);
    }

    const imageLinks = {
        'Brigham Young University': {
            light: 'https://logos-world.net/wp-content/uploads/2021/08/BYU-Emblem.png',
            dark: 'https://orientation.byu.edu/media/1120/logo-white-update.png'
        },
        'iHub': {
            light: 'https://assets.softr-files.com/applications/0a6c3713-c7a0-4fac-af97-b8e030c3e964/assets/044e62a2-9ac2-4435-98c9-db877ad0dced.jpeg',
            dark: 'https://assets.softr-files.com/applications/0a6c3713-c7a0-4fac-af97-b8e030c3e964/assets/044e62a2-9ac2-4435-98c9-db877ad0dced.jpeg'
        },
        'Gonzaga University': {
            light: 'https://content.sportslogos.net/logos/31/691/full/gonzaga_bulldogs_logo_wordmark_20114028.png',
            dark: 'https://home.gonzaga.edu/ui/images/logo-public.png'
        },
        'University of Utah': {
            light: 'https://logos-world.net/wp-content/uploads/2023/05/University-of-Utah-Logo.png',
            dark: 'https://templates.utah.edu/_main-v2/_images/header/logo/uu-logo.png'
        },
    };

    return (
        <div className="flex justify-center font-rubik ml-20 h-screen overflow-hidden">
            <Toaster richColors />
            <div>
                <h1 className="text-3xl font-bold mt-8">Permits</h1>
                {loading ? (
                    <h1 className="p-8">Loading...</h1>
                ) : (
                    <DragDropContext onDragEnd={onDragEnd}>
                        <Droppable droppableId="permits">
                            {(provided) => (
                                <table
                                    className="table-auto mx-8 relative"
                                    ref={provided.innerRef}
                                    {...provided.droppableProps}
                                >
                                    <thead>
                                        <tr className="table-auto text-xl">
                                            <th className="text-center"></th>
                                            <th className="text-center">Select</th>
                                            <th className="text-center"></th>
                                            <th className="text-center">Info</th>
                                            <th className="text-center">Type</th>
                                            <th className="text-center">Description</th>
                                            <th className="text-center">Color</th>
                                            <th className="text-center">Used At</th>
                                            <th className="text-center">Plates Per Permit</th>
                                            <th className="text-center">Interval</th>
                                            <th className="text-center">Price</th>
                                            <th className="text-center">Public</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {tableData.map((row, index) => {
                                            let usedAt = [];

                                            for (let i in violationData) {
                                                if (violationData[i].permitType === row.permitType) {
                                                    if (!usedAt.includes(violationData[i].lot)) {
                                                        usedAt.push(violationData[i].lot);
                                                    };
                                                };
                                            };

                                            const usedAtSet = new Set(usedAt);
                                            const usedAtString = Array.from(usedAtSet).sort().join(", ");

                                            return (
                                                <Draggable
                                                    key={`${row.permitType}-${index}`}
                                                    draggableId={`${row.permitType}-${index}`}
                                                    index={index}
                                                >
                                                    {(provided) => (
                                                        <tr
                                                            ref={provided.innerRef}
                                                            {...provided.draggableProps}
                                                            {...provided.dragHandleProps}
                                                            className="p-2 border-b border-gray-300 group"
                                                        >
                                                            <td className="p-2 text-center">
                                                                <div className="opacity-0 group-hover:opacity-100">
                                                                    <svg width="24" height="11" viewBox="0 0 48 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                        <rect x="1" y="2" width="46" height="5" rx="2.5" fill="#D9D9D9" />
                                                                        <rect x="1" y="15" width="46" height="5" rx="2.5" fill="#D9D9D9" />
                                                                    </svg>
                                                                </div>
                                                            </td>
                                                            <td className="p-2 text-center">
                                                                <input
                                                                    type="checkbox"
                                                                    alt="Select"
                                                                    onChange={() => handleCheck(index)}
                                                                />
                                                            </td>
                                                            <td className="p-2 text-center">
                                                            </td>
                                                            <td className="p-2 text-center">
                                                                <Link
                                                                    to={`/settings/permits/${row.permitType}`}
                                                                    onClick={() =>
                                                                        sessionStorage.setItem(
                                                                            "currentPermit",
                                                                            JSON.stringify(row)
                                                                        )
                                                                    }
                                                                >
                                                                    <BsPassFill size={20} className="text-gray-500" />
                                                                </Link>
                                                            </td>
                                                            <td className="p-2 text-center">
                                                                <input
                                                                    type="text"
                                                                    value={row.permitType}
                                                                    onChange={(e) =>
                                                                        handleEdit(index, "permitType", e.target.value)
                                                                    }
                                                                    className="text-center ml-4 px-2 w-16 outline-spotYellow"
                                                                />
                                                            </td>
                                                            <td className="p-2 text-center">
                                                                <input
                                                                    type="text"
                                                                    value={row.description}
                                                                    onChange={(e) =>
                                                                        handleEdit(index, "description", e.target.value)
                                                                    }
                                                                    className="text-center outline-spotYellow"
                                                                />
                                                            </td>
                                                            <td className="p-2 text-center">
                                                                <input
                                                                    type="color"
                                                                    value={row.color}
                                                                    onChange={(e) => setTempColor(e.target.value)}
                                                                    onBlur={(e) =>
                                                                        handleColorEdit(index, "color")
                                                                    }
                                                                    className="cursor-pointer"
                                                                />
                                                            </td>
                                                            <td className="p-2 text-center">
                                                                {usedAtString === allLotsString ? "All" : usedAtString}
                                                            </td>
                                                            <td className="p-2 text-center">
                                                                <input
                                                                    type="text"
                                                                    value={row.platesPerPermit}
                                                                    onChange={(e) =>
                                                                        handleEdit(index, "platesPerPermit", e.target.value)
                                                                    }
                                                                    className="text-center outline-spotYellow"
                                                                />
                                                            </td>
                                                            <td className="p-2 text-center">
                                                                <select
                                                                    value={row.interval}
                                                                    onChange={(e) => handleEdit(index, "interval", e.target.value)}
                                                                    className="text-center w-24 outline-spotYellow"
                                                                >
                                                                    <option key={index} value={""}>{""}</option>
                                                                    <option value="N/A">N/A</option>
                                                                    <option value="1 Hour">1 Hour</option>
                                                                    <option value="2 Hours">2 Hours</option>
                                                                    <option value="1 Day">1 Day</option>
                                                                    <option value="1 Week">1 Week</option>
                                                                    <option value="1 Month">1 Month</option>
                                                                    <option value="1 Year">1 Year</option>
                                                                    <option value="Indefinite">Indefinite</option>
                                                                    {Object.keys(orgData.customDurations).map((duration, index) => (
                                                                        <option key={index} value={duration}>{duration}</option>
                                                                    ))}
                                                                </select>
                                                            </td>
                                                            <td className="p-2 text-center">
                                                                <input
                                                                    type="text"
                                                                    value={row.price}
                                                                    onChange={(e) =>
                                                                        handleEdit(index, "price", e.target.value)
                                                                    }
                                                                    className="text-center outline-spotYellow"
                                                                />
                                                            </td>
                                                            <td className="p-2 text-center">
                                                                <input
                                                                    type="checkbox"
                                                                    checked={row.public}
                                                                    onChange={(e) =>
                                                                        handleEdit(index, "public", e.target.checked)
                                                                    }
                                                                />
                                                            </td>
                                                        </tr>
                                                    )}
                                                </Draggable>
                                            )
                                        })}
                                        {provided.placeholder}
                                    </tbody>

                                    {/* ADD BUTTON */}
                                    <div
                                        className="absolute -bottom-16 right-0 border w-24 h-12 rounded-lg cursor-pointer fcc hover:shadow-md transition-all duration-100"
                                        onClick={() => setShowPermitEditor(true)}
                                        aria-label="Add Permit"
                                    >
                                        <GoPlus className="text-6xl m-auto text-gray-400" />
                                    </div>

                                    {/* DELETE BUTTON */}
                                    {checkedIndexes.length > 0 && (
                                        <div className="absolute -bottom-16 right-28 ml-2 mr-auto">
                                            <button
                                                className="block px-3 h-12 font-bold rounded-lg shadow-md bg-red-200 hover:bg-red-300"
                                                onClick={removePermit}
                                                aria-label="Remove Permit"
                                            >
                                                {checkedIndexes.length > 1 ? 'Remove Permits' : 'Remove Permit'}
                                            </button>
                                        </div>
                                    )}
                                </table>
                            )}
                        </Droppable>
                    </DragDropContext>
                )}
                {different && (
                    <div className="absolute bottom-10 right-10">
                        <button
                            className="block px-6 h-12 rounded-lg shadow-md yellow-gradient font-bold"
                            onClick={handleSaveClick}
                            aria-label="Save Permit Data"
                        >
                            {saveMessage}
                        </button>
                    </div>
                )}
            </div>

            {showPermitEditor && (
                <div className="absolute h-screen flex justify-center items-center z-[1000]">
                    <div onClick={closeEditor} className="fixed top-0 left-0 w-screen h-screen bg-black bg-opacity-50"></div>
                    <div className="flex flex-row justify-center items-center gap-10">
                        <div className="w-[500px] bg-white shadow-xl rounded-lg z-[101] p-4">
                            <h1 className="text-4xl font-bold w-full text-center py-4">New Permit</h1>
                            <div className="flex flex-col gap-4 p-4">
                                <div className="flex flex-row justify-between items-center mx-2">
                                    <div className="flex flex-col gap-2">
                                        <label className="text-xl font-semibold">Permit Title</label>
                                        <input
                                            type="text"
                                            placeholder="Permit Title (ex. Student)"
                                            value={newPermit.description}
                                            onBlur={checkForDuplicates}
                                            onChange={(e) => {
                                                const value = e.target.value;
                                                const lettersOnly = value.replace(/[^a-zA-Z]/g, ''); // Allow only letters
                                                setNewPermit({ ...newPermit, description: lettersOnly });
                                            }}
                                            className="text-left pl-2 border rounded-lg grow outline-spotYellow"
                                        />
                                    </div>
                                    <div className="flex flex-col gap-2">
                                        <label className="text-xl font-semibold">Permit Code</label>
                                        <input
                                            type="text"
                                            placeholder="Permit Code (ex. G)"
                                            value={newPermit.permitType}
                                            onBlur={checkForDuplicates}
                                            onChange={(e) => {
                                                const value = e.target.value;
                                                const lettersOnly = value.replace(/[^a-zA-Z]/g, '').toUpperCase(); // Allow only letters
                                                const singleLetter = lettersOnly.slice(0, 2);
                                                setNewPermit({ ...newPermit, permitType: singleLetter });
                                            }}
                                            className="text-left pl-2 border rounded-lg grow outline-spotYellow"
                                        />
                                    </div>
                                </div>
                                <div className="flex flex-row justify-between items-center gap-4 mx-2">
                                    <div className="flex flex-col gap-2 w-full">
                                        <label className="text-xl font-semibold">Interval</label>
                                        <select
                                            className="border rounded-lg pl-2 py-1 w-full outline-spotYellow"
                                            value={newPermit.duration}
                                            // eslint-disable-next-line
                                            onChange={(e) => (setNewPermit({ ...newPermit, interval: e.target.value }), setDuration(e.target.value))}
                                        >
                                            <option value=""></option>
                                            <option value="hour">1 Hour</option>
                                            <option value="twoHour">2 Hours</option>
                                            <option value="day">1 Day</option>
                                            <option value="week">1 Week</option>
                                            <option value="month">1 Month</option>
                                            <option value="year">1 Year</option>
                                            {Object.keys(orgData.customDurations).map((duration, index) => (
                                                <option key={index} value={duration}>{duration}</option>
                                            ))}
                                        </select>
                                    </div>
                                    <div className="flex flex-col gap-2">
                                        <label className="text-xl font-semibold">Price</label>
                                        <input
                                            type="text"
                                            placeholder="60.00"
                                            value={newPermit.price}
                                            onBlur={(e) => {
                                                if (e.target.value === "") {
                                                    setNewPermit({ ...newPermit, price: "" });
                                                } else {
                                                    setNewPermit({ ...newPermit, price: Number(e.target.value).toFixed(2) });
                                                }
                                            }}
                                            onChange={(e) => {
                                                const inputValue = e.target.value;
                                                const numericValue = inputValue.replace(/[^0-9.]/g, ''); // Remove non-numeric and non-decimal characters
                                                setNewPermit({ ...newPermit, price: numericValue });
                                            }}
                                            className="text-left pl-2 border rounded-lg grow outline-spotYellow"
                                        />
                                    </div>
                                </div>
                                <div className="flex flex-row justify-between items-center gap-4 mx-2">
                                    <div className="flex flex-row justify-evenly w-full mr-10">
                                        <div className="flex flex-col gap-2">
                                            <label className="text-xl font-semibold">Color</label>
                                            <input
                                                type="color"
                                                onMouseUp={checkForDuplicates}
                                                value={newPermit.color}
                                                onChange={(e) => setNewPermit({ ...newPermit, color: e.target.value })}
                                                className="cursor-pointer w-16 h-8 rounded-md p-1"
                                            />
                                        </div>
                                        <div className="flex flex-col gap-2">
                                            <label className="text-xl font-semibold">Public</label>
                                            <input
                                                type="checkbox"
                                                checked={newPermit.public}
                                                onChange={(e) => setNewPermit({ ...newPermit, public: !newPermit.public })}
                                                className="text-left accent-spotYellow border rounded-lg grow outline-spotYellow"
                                            />
                                        </div>
                                    </div>
                                    <div className="flex flex-col gap-2 w-full">
                                        <label className="text-xl font-semibold">Plates/Permit</label>
                                        <input
                                            type="number"
                                            placeholder="2"
                                            min={1}
                                            max={5}
                                            value={newPermit.platesPerPermit}
                                            onChange={(e) => setNewPermit({ ...newPermit, platesPerPermit: e.target.value })}
                                            className="text-left pl-2 border rounded-lg grow outline-spotYellow"
                                        />
                                    </div>
                                </div>
                                <p className="text-xl text-red-400 h-8 text-center">{errorMessage}</p>
                                <button onClick={handleAddPermit} className="bg-gray-500 hover:bg-spotYellow hover:text-spotGray text-white font-semibold rounded-lg p-2 transition-all duration-100">Create Permit</button>
                            </div>
                        </div>

                        <svg
                            className="z-[10001] drop-shadow-xl"
                            width="350"
                            height="100%"
                            viewBox="0 0 900 2056"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path d="M871.121 0C881.548 0 890 8.4524 890 18.879V148.006C890 155.401 884.644 161.709 877.347 162.909L587.463 210.561C581.607 211.524 575.689 209.243 571.994 204.599L513.441 131.016C476.777 84.9393 407.045 84.1551 369.354 129.395C312.721 197.371 368.216 299.631 456.074 289.194L577.979 274.713L872.366 224.68C881.586 223.113 890 230.217 890 239.57L890 2037.12C890 2047.55 881.548 2056 871.121 2056H18.879C8.45242 2056 0 2047.55 0 2037.12L0 18.8789C0 8.45232 8.4524 0 18.879 0L871.121 0Z" fill="white" />
                            <foreignObject x="0" y="300" width="890" height="1756">
                                <div
                                    className="bg-transparent h-full text-center"
                                    style={{ zoom: 3, }}
                                >
                                    <div className="p-2 mt-4 flex flex-col gap-4">
                                        {newPermit.description && (
                                            <div>
                                                <img
                                                    src={imageLinks[orgData.orgName].light}
                                                    alt="Organization Logo"
                                                    className="w-32 m-auto"
                                                />
                                                <div>
                                                    <p className="text-sm text-gray-400">Permit Type</p>
                                                    <p className="text-2xl font-bold text-center">
                                                        {newPermit.description} {newPermit.permitType ? `(${newPermit.permitType})` : ""}
                                                    </p>
                                                </div>
                                            </div>
                                        )}
                                        {newPermit.expirationDate && (
                                            <div className="flex flex-row justify-between items-center mx-6">
                                                <div>
                                                    <p className="text-sm text-gray-400">Purchase Date</p>
                                                    <p className="text-lg font-bold text-center">
                                                        {new Date().toLocaleDateString()}
                                                    </p>
                                                </div>
                                                <div>
                                                    <p className="text-sm text-gray-400">Expiration Date</p>
                                                    <p className="text-lg font-bold text-center">
                                                        {calculateExpiration(duration).toLocaleDateString()}
                                                    </p>
                                                </div>
                                            </div>
                                        )}
                                        {newPermit.price && (
                                            <div className="flex flex-row justify-between items-center mx-6">
                                                <div>
                                                    <p className="text-sm text-gray-400">Price</p>
                                                    <p className="text-lg font-bold text-center">
                                                        ${newPermit.price !== "" ? newPermit.price : "0.00"}
                                                    </p>
                                                </div>
                                                <div>
                                                    <p className="text-sm text-gray-400">Confirmation Number</p>
                                                    <p className="text-lg font-bold text-center">
                                                        936690151607
                                                    </p>
                                                </div>
                                            </div>
                                        )}
                                    </div>

                                    <div
                                        className={`absolute bottom-0 right-0 border-b-0 border-r-[70px] border-t-[70px] border-l-0 border-solid border-transparent border-b-transparent border-t-transparent border-l-transparent rounded-br-[6px] bg-transparent`}
                                        style={{
                                            borderRightColor: newPermit.color + '90',
                                        }}
                                    ></div>
                                    <div
                                        className={`absolute bottom-0 right-0 border-b-0 border-r-[70px] border-t-[70px] border-l-0 border-solid border-transparent border-b-transparent border-t-transparent border-l-transparent rounded-br-[6px] bg-transparent`}
                                        style={{
                                            borderRightColor: newPermit.color + '50',
                                            borderRightWidth: 180,
                                            borderTopWidth: 100,
                                        }}
                                    ></div>
                                    <div
                                        className={`absolute bottom-0 right-0 border-b-0 border-r-[70px] border-t-[70px] border-l-0 border-solid border-transparent border-b-transparent border-t-transparent border-l-transparent rounded-br-[6px] bg-transparent`}
                                        style={{
                                            borderLeftColor: newPermit.color + '40',
                                            borderLeftWidth: 250,
                                            borderTopWidth: 80,
                                            borderBottomLeftRadius: 7,
                                        }}
                                    ></div>
                                    <div
                                        className={`absolute bottom-0 right-0 border-b-0 border-r-[70px] border-t-[70px] border-l-0 border-solid border-transparent border-b-transparent border-t-transparent border-l-transparent rounded-br-[6px] bg-transparent`}
                                        style={{
                                            borderLeftColor: newPermit.color + '20',
                                            borderLeftWidth: 300,
                                            borderTopWidth: 200,
                                            borderBottomLeftRadius: 7,
                                        }}
                                    ></div>

                                </div>
                            </foreignObject>
                        </svg>
                    </div>
                </div>
            )}
        </div >
    );
};