import React, { useState } from "react";
import { Toaster, toast } from "sonner";
import { useNavigate } from "react-router-dom";
import { signUp } from "../utility/awsMethods";

const Signup = () => {
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [errorMessage, setErrorMessage] = useState("");
    const navigate = useNavigate();

    const validateEmail = () => {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (!emailRegex.test(email)) {
            setErrorMessage("Invalid email address format.");
            return;
        } else {
            setErrorMessage("");
        }
    }


    const validatePassword = () => {
        if (password !== confirmPassword) {
            setErrorMessage("Passwords do not match!");
            return;
        } else {
            setErrorMessage("");
        }
    }

    const handleSignUp = async () => {
        if (!firstName || !lastName || !email || !password || !confirmPassword) {
            setErrorMessage('Please fill in all fields.');
            return;
        }

        if (password !== confirmPassword) {
            alert('Passwords do not match');
            return;
        }

        try {
            const attributes = [
                { Name: 'given_name', Value: firstName },
                { Name: 'family_name', Value: lastName },
                { Name: 'email', Value: email },
            ];

            localStorage.setItem('email', email);
            localStorage.setItem('given_name', firstName);
            localStorage.setItem('family_name', lastName);
            const response = await signUp(email, password, attributes);

            console.log(response)
        } catch (error) {
            if (error.message === 'User already exists') {
                setErrorMessage('Email already in use.');
            } else {
                console.error('Error signing up', error);
                setErrorMessage('An error occurred. Please try again later.');
            }
        }
    };

    const signUpSection = () => {
        return (
            <div className="flex flex-col justify-center items-center bg-white rounded-3xl shadow-lg p-12 z-50 gap-4">
                <div className="flex flex-col gap-0 justify-center items-center">
                    <h1 className="text-5xl font-bold text-spotGray mb-0 mt-[-10px]">Sign Up</h1>
                    <p className="flex justify-center items-center w-full text-center text-xl text-spotGray">Please enter an email and a password</p>
                </div>
                <div className="w-full">
                    <form aria-label="Sign Up Form" className="flex flex-col justify-center items-center w-full" onSubmit={handleSignUp}>
                        <label className="w-full">
                            <div className="text-xl font-bold mb-2 text-spotGray">
                                First Name
                            </div>
                            <input
                                type="text"
                                alt="First Name"
                                className="w-full h-[40px] rounded-lg border border-gray-300 mb-2 pl-2 text-xl outline-spotYellow text-spotGray"
                                value={firstName}
                                onChange={(e) => setFirstName(e.target.value)}
                            />
                        </label>
                        <label className="w-full">
                            <div className="text-xl font-bold mb-2 text-spotGray">
                                Last Name
                            </div>
                            <input
                                type="text"
                                alt="Last Name"
                                className="w-full max-w-[500px] h-[40px] rounded-lg border border-gray-300 mb-2 pl-2 text-xl text-spotGray outline-spotYellow"
                                value={lastName}
                                onChange={(e) => setLastName(e.target.value)}
                            />
                        </label>
                        <label className="w-full">
                            <div className="text-xl font-bold mb-2 text-spotGray">
                                Email
                            </div>
                            <input
                                type="text"
                                alt="Email"
                                className="w-full max-w-[500px] h-[40px] rounded-lg border border-gray-300 mb-2 pl-2 text-xl text-spotGray items-center outline-spotYellow"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                                onBlur={validateEmail}
                            />
                        </label>
                        <label className="w-full">
                            <div className="text-xl font-bold mb-2 text-spotGray">
                                Password
                            </div>
                            <input
                                type="password"
                                alt="Password"
                                className="w-full max-w-[500px] h-[40px] rounded-lg border border-gray-300 mb-2 pl-2 text-xl text-spotGray outline-spotYellow"
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                            />
                        </label>
                        <label className="w-full">
                            <div className="text-xl font-bold mb-2 text-spotGray">
                                Confirm Password
                            </div>
                            <input
                                type="password"
                                alt="Confirm Password"
                                className="w-full max-w-[500px] h-[40px] rounded-lg border border-gray-300 mb-2 pl-2 text-xl text-spotGray outline-spotYellow"
                                value={confirmPassword}
                                onChange={(e) => (setConfirmPassword(e.target.value), validatePassword())}
                                onBlur={validatePassword}
                            />
                        </label>
                        {errorMessage !== "" ? <p className="text-red-500 text-lg mt-1 mb-[-15px]">{errorMessage}</p> : null}
                        <button
                            className="flex items-center justify-center rounded-2xl font-rubik font-bold text-2xl text-spotGray shadow-xl yellow-gradient p-2 px-4 mt-4"
                            type="submit"
                            aria-label="Sign Up Button"
                        >
                            Sign Up
                        </button>
                    </form>
                    <div className="flex justify-center mt-5 text-sm text-spotGray">
                        Already have an account? <a className="underline ml-1" href="/login"> Login</a>
                    </div>
                </div>
            </div>
        )
    }

    const square = (width, height, x, y, rotation) => {
        return (
            <div style={{
                position: "absolute",
                width: `${width * .75}%`,
                height: `${height * 1.25}%`,
                backgroundColor: "#D9D9D9",
                transform: `rotate(${rotation}deg)`,
                borderRadius: "45px",
                opacity: "0.2",
                boxShadow: "0 0px 36px 18px rgba(0, 0, 0, 1.45)",
                top: `${y}vh`,
                left: `${x}vw`,
                zIndex: "0",
            }}></div>
        );
    }

    return (
        <div className="flex justify-center items-center" style={{
            background: "linear-gradient(to top, #FFE374, #474747)",
            height: "100vh",
        }}>
            {signUpSection()}
            <div style={{
                position: "absolute",
                width: "100%",
                height: "100%",
                zIndex: "1",
                overflow: "hidden",
            }}>
                {square(50, 50, 20, -25, 40)}
                {square(70, 70, 0, 80, -20)}
                {square(70, 70, 90, 40, -20)}
            </div>
            <Toaster />
        </div>
    );
}

export default Signup;