import React, { useState } from "react";
import emailjs from "emailjs-com";
import { Toaster, toast } from "sonner";
import "./settings.css";

export const Help = () => {
    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [phone, setPhone] = useState("");
    const [organization, setOrganization] = useState("");
    const [problem, setProblem] = useState("");
    const [description, setDescription] = useState("");
    const [buttonText, setButtonText] = useState("Submit");

    const handleSubmit = (e) => {
        e.preventDefault();

        if (!name || !email || !phone || !organization || !problem || !description) {
            toast.error("Please fill in all fields.");
            setButtonText("Submit");
            return;
        }

        setButtonText("Submitting...");

        const templateParams = {
            name,
            email,
            phone,
            organization,
            problem,
            description,
        };

        emailjs
            .send(
                "service_dsu84xg",
                "template_auk2coh",
                templateParams,
                "_mhIZPOql0BHuIkf1"
            )
            .then((response) => {
                console.log("Email sent successfully!");
            })
            .catch((error) => {
                console.error("Error sending email:", error);
                toast.error("Help ticket submission failed. Please try again.");
            });

        // Reset form fields
        setName("");
        setEmail("");
        setPhone("");
        setOrganization("");
        setProblem("");
        setDescription("");
        toast.success("Help ticket submitted, we'll reach out soon!");
    };

    const helpForm = () => {
        return (
            <div className="flex flex-col justify-center items-center mt-4 rounded-3xl shadow-md w-fit p-6 m-auto bg-white border">
                <form aria-label="Help Form" onSubmit={handleSubmit}>
                    <label>
                        <div className="font-bold text-xl text-spotGray">Name</div>
                        <input
                            type="text"
                            alt="Name"
                            value={name}
                            className="h-8 w-full border-2 border-gray-400 rounded-md p-2 mb-2 outline-spotYellow"
                            onChange={(e) => setName(e.target.value)}
                        />
                    </label>
                    <div className="flex flex-row">
                        <label className="grow pr-2">
                            <div className="font-bold text-xl text-spotGray">Email</div>
                            <input
                                type="email"
                                alt="Email"
                                value={email}
                                className="h-8 w-full border-2 border-gray-400 rounded-md p-2 mb-2 outline-spotYellow"
                                onChange={(e) => setEmail(e.target.value)}
                            />
                        </label>
                        <label className="grow">
                            <div className="font-bold text-xl text-spotGray">Phone Number</div>
                            <input
                                type="tel"
                                alt="Phone Number"
                                value={phone}
                                className="h-8 w-full border-2 border-gray-400 rounded-md p-2 mb-2 outline-spotYellow"
                                onChange={(e) => setPhone(e.target.value)}
                            />
                        </label>
                    </div>
                    <label>
                        <div className="font-bold text-xl text-spotGray">Organization</div>
                        <input
                            type="text"
                            alt="Organization"
                            value={organization}
                            className="h-8 w-full border-2 border-gray-400 rounded-md p-2 mb-2 outline-spotYellow"
                            onChange={(e) => setOrganization(e.target.value)}
                        />
                    </label>
                    <label>
                        <div className="font-bold text-xl text-spotGray">Request Title</div>
                        <input
                            type="text"
                            alt="Request Title"
                            value={problem}
                            className="h-8 w-full border-2 border-gray-400 rounded-md p-2 mb-2 outline-spotYellow"
                            onChange={(e) => setProblem(e.target.value)}
                        />
                    </label>
                    <label>
                        <div className="font-bold text-xl text-spotGray">Description</div>
                        <textarea
                            value={description}
                            aria-label="Description"
                            className="w-full min-w-full border-2 border-gray-400 rounded-md p-2 my-2 min-h-24 outline-spotYellow"
                            onChange={(e) => setDescription(e.target.value)}
                        />
                    </label>
                    <button
                        className="w-fit h-fit px-4 py-1 rounded-md shadow-md yellow-gradient font-bold"
                        type="submit"
                        aria-label="Submit Help Ticket"
                    >
                        {buttonText}
                    </button>
                </form>
            </div>
        )
    }

    const FaqItem = (title, description) => {
        const [open, setOpen] = useState(false);

        return (
            <div
                className={`border shadow-md rounded-lg p-4 h-fit cursor-pointer transition-all ease-in-out duration-300 ${open && "h-fit"}`}
                onClick={() => setOpen(!open)}
                aria-label="FAQ Item"
            >
                <h3 className="font-semibold text-lg">{title}</h3>
                <p className={`${open ? "flex" : "hidden"}`}>{description}</p>
            </div>
        )
    }

    const itemCreator = (date, title, description) => {
        return (
            <div className="mb-4">
                <h3 className="font-semibold text-lg">{date} - {title}</h3>
                <p className="">{description}</p>
                <hr className="mt-4 w-full" />
            </div>
        )
    }

    return (
        <div className="w-screen flex flex-col items-center pl-20 pr-8 font-rubik">
            <Toaster position='bottom-right' richColors />
            <h1 className="font-bold text-5xl w-full text-center p-8">Help Center</h1>
            <div className="flex flex-col justify-center max-w-[1000px] gap-4">
                <div className="flex flex-col gap-4">
                    <h1 className="text-spotGray text-2xl font-bold p-2 mt-4 w-full">Frequently Asked Questions</h1>
                    <div className="fcc gap-4">
                        {Object.entries(faqs).map(([title, { description }]) => {
                            return (
                                <div className="w-full px-4" key={title}>
                                    {FaqItem(title, description)}
                                </div>
                            );
                        })}
                    </div>
                </div>
                <div className="p-4 pb-0">
                    <h1 className="text-spotGray text-2xl font-bold p-2 mt-4 mb-0 pb-0 w-full">Recent Patch Notes</h1>
                    <h2 className="text-spotGray text-center text-lg font-semibold p-2 pb-8 w-full">Current Version: 0.2.0</h2>
                    {Object.entries(changes).reverse().map(([title, { date, description }]) => {
                        return (
                            <div key={title}>
                                {itemCreator(date, title, description)}
                            </div>
                        );
                    })}
                </div>
                <div className="pb-10">
                    <h1 className="text-spotGray text-2xl font-bold p-2 w-full">Submit Help Ticket</h1>
                    {helpForm()}
                </div>
            </div>
        </div>
    );
};

const faqs = {
    "What is the Live Map?": {
        description: "In the Live Map you can see a live updating map of all the parking spots on campus as well if they are vacant, occupied legally (green spot), or occupied illegally (red spot). If you click on any spot that is occupied you can see information about the vehicle."
    },
    "Can I personalize my dashboard?": {
        description: "Yes! Go to Settings → Personalizations and here you can decide which statistics you want to see and you can even customize how they are arranged."
    },
    "What is the purpose of the Dashboard?": {
        description: "The Dashboard is used by parking enforcement administration to see statistical trends and analytics about the parking enforcement and general parking on campus. You can see the percent occupancy and a detailed summary of the parking violations of any given lot as well as the campus as a whole. You can also activate or deactivate auto-billing for each individual lot. You can also see statistics about your employees and how many citations they have given. "
    },
    "What does turning on auto (auto-billing) do?": {
        description: "When auto-billing is turned on for a certain lot, it is not needed that parking enforcement officer go out physical and issue parking citations. The Spot Parking system will just detect each car that is parked illegally and bill them via their student account or to their home address (from the city database). "
    },
    "Under Settings → My Account, what is the “Violation Time Goal”?": {
        description: "You can set a goal for how long it takes your employees to issue a citation to an illegally parked car."
    },
    "Can I issue tickets from sitting in my office using the admin portal?": {
        description: "Yes! You can automatically bill vehicles that are parked illegally. Just look at the saved camera footage to double check there wasn’t any error with the system and mark it as completed."
    },
    "How do I override/edit a citation that has already been given?": {
        description: "Under the Violations History section, when you click on a license plate you can see all the violations they have received as well as the option to edit any of them. Click the edit button of the right of the screen."
    },
    "Under Settings → Lots, what does the “Grace Period” mean?": {
        description: "The grace period is relevant for when the lot is set to auto - bill. For different lots you might want to give some leeway so you don't ticket students who have parked for a brief time. This allows students who might park temporarily to drop someone off or if they park and realize the are in the wrong lot and end up moving their car."
    },
    "What do the different use permissions mean?": {
        description: 'Each user can have up to three permissions. "Nav" allows the user to use the Spot Nav app. Here they can purchase permits, pay citations, view the live map, and view organization announcements sent to them. Users with the enforcment permission can use the Spot Enforcement app. Here they can issue citations, view the live map, and view organization announcements directed towards the enforcement team. Users with the admin permission can use the Spot Admin app. Here they can they can do all the things that you can do.'
    }
}

const changes = {
    "Initial Creation": {
        date: "March 30, 2024",
        description: "Spot is now live!"
    },
    "New Features": {
        date: "April 1, 2024",
        description: "Spot now has a live map, analytics, and settings page."
    },
    "Bug Fixes": {
        date: "April 3, 2024",
        description: "Fixed a bug where the map would not display properly on some devices."
    },
    "Violation History": {
        date: "April 18, 2024",
        description: "Violation history is now available in the analytics page. You can also edit violations by selecting the edit button and saving."
    },
    "Sidebar": {
        date: "April 21, 2024",
        description: "The sidebar is now the new form of navigation including dropdown menus."
    },
    "New UI": {
        date: "June 10, 2024",
        description: 'All formatting now done with Tailwind CSS. This allows for new sidebar and new "softer" UI throughout the application.'
    },
    "Permit and Citation Management": {
        date: "August 1, 2024",
        description: "Version one of citation and permit management is now live. This includes the ability to add, edit, and remove permits and citations. You are also able to review appeals from violators."
    },
}