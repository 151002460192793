import React, { useState } from "react";
import { fetchAllData, postData } from "../utility/fetcher";
import { Toaster, toast } from "sonner";
import { isObjDifferent } from "../utility/isObjDifferent";

export const Payments = () => {
    const orgData = JSON.parse(localStorage.getItem("orgData"));
    const [tableData, setTableData] = useState(orgData);
    const [saveText, setSaveText] = useState("Save");

    const onEdit = (key, value) => {
        setTableData({
            ...tableData,
            payments: {
                ...tableData.payments,
                [key]: value
            }
        });
    };

    const handleSave = async () => {
        setSaveText("Saving...");
        const toastId = toast.loading("Saving data...");
        const newData = orgData;
        const different = isObjDifferent(newData, tableData);

        if (!different) {
            toast.info("No changes detected.", { id: toastId });
            setSaveText("Save");
            return;
        };

        newData.payments = tableData.payments;

        try {
            console.log("Data to save:", newData);
            const path = "/general/organizations";
            const response = await postData(path, newData);
            toast.success("Payment settings saved successfully!", { id: toastId });
            console.log(response.message);

            const orgName = localStorage.getItem("organization");
            const getResponse = await fetchAllData({ "orgData": `/public/getOrgByName?orgName=${orgName}` });
            const neworgData = getResponse.orgData;
            localStorage.setItem("orgData", JSON.stringify(neworgData));
            setTableData(neworgData);

            setSaveText("Saved");
            setTimeout(() => {
                setSaveText("Save");
            }, 2000);
        } catch (error) {
            console.error("Error saving data:", error);
            toast.error("Permit data failed to save. Please try again.", { id: toastId });
        };
    };

    const handleKeyPress = (e) => {
        if (e.key === "Enter") {
            e.target.blur();
        };
    };

    const formatTitle = (title) => {
        return title.replace(/([A-Z])/g, ' $1').replace(/^./, function (str) { return str.toUpperCase(); });
    };

    return (
        <div className="w-screen pl-24 p-4">
            <div className="fcc">
                <div className="flex flex-col w-full text-center mt-10 mb-4 text-3xl font-bold">Payments</div>
                <div key={'custom'} className="flex flex-row justify-between items-center w-full shadow-md rounded-lg border my-2 p-2 pl-4 py-4 cursor-default">
                    <div className="flex flex-row w-full justify-between items-center gap-4 pr-10">
                        <h2 className='font-bold text-lg'>Custom Payments</h2>
                        <input
                            type="checkbox"
                            checked={tableData.payments['custom'] || false}
                            className="accent-spotYellow"
                            onChange={() => onEdit('custom', !tableData.payments['custom'])}
                        />
                    </div>
                </div>
                {Object.keys(tableData.payments).filter((key) => key !== "custom").map((key, index) => (
                    <div key={index} className={`flex flex-row justify-between cursor-default items-center w-full shadow-md rounded-lg border my-2 p-2 pl-4 py-4 ${!tableData.payments['custom'] && 'opacity-50'}`}>
                        <div className="flex flex-row w-full justify-between items-center gap-4 pr-10">
                            <h2 className='font-bold text-lg'>{formatTitle(key)}</h2>
                            <input
                                type="text"
                                alt={`Link for ${key}`}
                                value={tableData.payments[key] || ""}
                                onKeyDown={handleKeyPress}
                                disabled={!tableData.payments['custom']}
                                onChange={(e) => onEdit(key, e.target.value || "")}
                                className="accent-spotYellow grow max-w-[500px] border rounded-md outline-spotYellow text-right pr-2"
                            />
                        </div>
                    </div>
                ))}
                <button
                    className='h-fit w-fit px-4 py-1 yellow-gradient font-bold text-lg ml-auto rounded-lg shadow-md'
                    onClick={handleSave}
                    aria-label="Save Changes"
                >
                    {saveText}
                </button>
            </div>
            <Toaster position='bottom-right' richColors closeButton />
        </div>
    );
};