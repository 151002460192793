import React, { useEffect, useState } from "react";
import { FaCheck, FaArrowLeft, FaEdit } from "react-icons/fa";
import { fetchAllData, patchData } from "../../utility/fetcher";
import { copyToClipboard } from "../../utility/copyToClipboard";
import "../analytics.css";
import { toast, Toaster } from "sonner";
import { Loading } from "../../utility/Loading";
import { Buffer } from 'buffer';
import bigInt from "big-integer";
import { IoMdArrowDropright } from "react-icons/io";

export const PlateHistory = () => {
    const plateObj = JSON.parse(sessionStorage.getItem("currentPlate"));
    const orgID = JSON.parse(localStorage.getItem("orgData")).orgID;
    const licensePlate = plateObj.licensePlate;
    const state = plateObj.lpState;
    const [showEditor, setShowEditor] = useState(false);
    const [historyData, setHistoryData] = useState([]);
    const [ogData, setOgData] = useState([]);
    const [loading, setLoading] = useState(true);

    const getPlateHistory = async () => {
        const path = { "historyData": `/${orgID}/history?licensePlate=${licensePlate}&lpState=${state}` }
        const response = await fetchAllData(path);
        const data = await response.historyData;
        try {
            const sortedData = data.sort((a, b) => b["timeIn"] - a["timeIn"]);
            setHistoryData(sortedData);
        } catch {
            console.error("Error sorting data");
        };
    };

    useEffect(() => {
        getPlateHistory();
        // eslint-disable-next-line
    }, []);

    const toDate = (input) => {
        if (input === "N/A" || input === "") return ("-")
        const timestamp = parseInt(input, 10) * 1000;
        const date = new Date(timestamp);
        if (isNaN(date.getTime())) {
            return "-";
        } else {
            const newDate = date.toLocaleDateString("en-US", {
                year: "2-digit",
                month: "numeric",
                day: "numeric",
                hour: "numeric",
                minute: "numeric",
            });
            return newDate;
        }
    }

    const handleEdit = (item) => {
        setEditData(item);
        setOgData(item);
        const tempOgVisitID = encodeToBase10(item.licensePlate, item.lpState) + "-" + item.timestamp;
        setOgVisitID(tempOgVisitID);
        setShowEditor(true);
        //window.location.href = "/analytics/violation-history/edit";
    }

    const formatFee = (fee) => {
        if (fee === "") return "";
        return `$${parseFloat(fee).toFixed(2)}`;
    };

    const [editData, setEditData] = useState({});
    const [ogVisitID, setOgVisitID] = useState();

    const encodeToBase10 = (plateNumber, state) => {
        const inputString = `${plateNumber}-${state}`;
        const buffer = Buffer.from(inputString, 'utf-8');
        const base10Number = bigInt(buffer.toString('hex'), 16).toString(10);
        return base10Number;
    };

    const handleEditSave = async (currentData) => {
        if (currentData.timeIn === "" || currentData.timeOut === "" || currentData.lot === "" || currentData.spot === "") {
            toast.error("Please fill out all required fields.");
            return;
        } else if (currentData.ticketWritten === true && currentData.fee === "") {
            toast.error("Please fill out fee.");
            return;
        };

        setShowEditor(false);

        // Encode the license plate and state to a base 10 number
        const plateID = encodeToBase10(licensePlate, state);
        const visitID = plateID + "-" + currentData.timestamp;
        const path = `/${orgID}/history?visitID=${visitID}`;

        // Compare the original data with the edited data
        const changes = Object.keys(currentData).reduce((acc, key) => {
            if (currentData[key] !== ogData[key]) {
                acc[key] = currentData[key];
            };
            return acc;
        }, {});

        if (Object.keys(changes).length !== 0) {
            // Patch data and log response
            const response = await patchData(path, changes);

            if (response.message === "Item successfully updated.") {
                // Update that line item in the table
                const updatedData = historyData.map((item) => {
                    const currentVisitID = encodeToBase10(item.licensePlate, item.lpState) + "-" + item.timestamp;
                    if (currentVisitID === visitID) {
                        return currentData;
                    } else {
                        return item;
                    };
                });

                setHistoryData(updatedData);
                toast.success("Successfully updated.");
            } else {
                toast.error("Error updating item.");
            };
        } else {
            toast.info("No changes detected.");
        }
    };

    // const handleDelete = async (currentData) => {
    //     setShowEditor(false);

    //     // Encode the license plate and state to a base 10 number
    //     const plateID = encodeToBase10(licensePlate, state);
    //     const visitID = plateID + "-" + currentData.timestamp;
    //     const path = `/${orgID}/history?visitID=${visitID}`;

    //     // Patch data and log response
    //     const response = await deleteData(path, currentData); // TODO Make sure this is the right body

    //     if (response.message === "Item successfully deleted.") {
    //         // Update that line item in the table
    //         const updatedData = historyData.filter((item) => {
    //             const currentVisitID = encodeToBase10(item.licensePlate, item.lpState) + "-" + item.timestamp;
    //             return currentVisitID !== visitID;
    //         });

    //         setHistoryData(updatedData);
    //         toast.success("Successfully deleted.");
    //     }
    // };

    const cancelEditor = (currentData) => {
        if (currentData.timeIn === "" || currentData.timeOut === "" || currentData.lot === "" || currentData.spot === "") {
            toast.error("Please fill out all required fields.");
            return;
        } else if (currentData.ticketWritten === true && currentData.fee === "") {
            toast.error("Please fill out fee.");
            return;
        };

        setShowEditor(false);
    };

    const [ticketsWritten, setTicketsWritten] = useState(0);
    const [totalViolations, setTotalViolations] = useState(0);
    const [favoriteLot, setFavoriteLot] = useState("");

    useEffect(() => {
        if (historyData.length !== 0) {
            let tempTicketsWritten = 0;
            let tempTotalViolations = 0;
            let lotsVisited = {};
            historyData.forEach((item) => {
                if (item.ticketWritten) {
                    tempTicketsWritten += 1;
                };

                if (item.violationType !== undefined) {
                    tempTotalViolations += 1;
                };

                if (lotsVisited[item.lot] === undefined) {
                    lotsVisited[item.lot] = 1;
                } else {
                    lotsVisited[item.lot] += 1;
                };
            });

            setTicketsWritten(tempTicketsWritten);
            setTotalViolations(tempTotalViolations);
            setFavoriteLot(Object.keys(lotsVisited).reduce((a, b) => lotsVisited[a] > lotsVisited[b] ? a : b));
            setLoading(false);
        }
    }, [historyData]);

    const Editor = () => {
        const [currentData, setCurrentData] = useState(editData);

        return (
            <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
                <div className="bg-white p-6 rounded-lg shadow-lg w-1/2">
                    <h2 className="text-2xl font-bold mb-4">Edit Violation</h2>
                    <form>
                        <div className="mb-4">
                            <label className="block text-gray-700 text-sm font-bold mb-2">
                                Time In
                            </label>
                            <input
                                type="datetime-local"
                                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                onChange={(e) => setCurrentData({ ...currentData, timeIn: Math.floor(new Date(e.target.value).getTime() / 1000) })}
                                defaultValue={new Date(currentData.timeIn * 1000).toLocaleString("sv-SE").slice(0, 16)}
                            />
                        </div>
                        <div className="mb-4">
                            <label className="block text-gray-700 text-sm font-bold mb-2">
                                Time Out
                            </label>
                            <input
                                type="datetime-local"
                                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                onChange={(e) => setCurrentData({ ...currentData, timeOut: Math.floor(new Date(e.target.value).getTime() / 1000) })}
                                defaultValue={new Date(currentData.timeOut * 1000).toLocaleString("sv-SE").slice(0, 16)}
                            />
                        </div>
                        <div className="mb-4">
                            <label className="block text-gray-700 text-sm font-bold mb-2">
                                Ticket Written Time
                            </label>
                            <input
                                type="datetime-local"
                                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                onChange={(e) => setCurrentData({ ...currentData, ticketWrittenTime: Math.floor(new Date(e.target.value).getTime() / 1000) })}
                                defaultValue={new Date(currentData.ticketWrittenTime * 1000).toLocaleString("sv-SE").slice(0, 16)}
                            />
                        </div>
                        <div className="mb-4">
                            <label className="block text-gray-700 text-sm font-bold mb-2">
                                Lot
                            </label>
                            <input
                                type="text"
                                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                onChange={(e) => setCurrentData({ ...currentData, lot: e.target.value })}
                                defaultValue={currentData.lot}
                            />
                        </div>
                        <div className="mb-4">
                            <label className="block text-gray-700 text-sm font-bold mb-2">
                                Spot
                            </label>
                            <input
                                type="text"
                                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                onChange={(e) => setCurrentData({ ...currentData, spot: e.target.value })}
                                defaultValue={currentData.spot}
                            />
                        </div>
                        <div className="mb-4">
                            <label className="block text-gray-700 text-sm font-bold mb-2">
                                Violation Type
                            </label>
                            <input
                                type="text"
                                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                onChange={(e) => setCurrentData({ ...currentData, violationType: e.target.value })}
                                defaultValue={currentData.violationType}
                            />
                        </div>
                        <div className="mb-4">
                            <label className="block text-gray-700 text-sm font-bold mb-2">
                                Ticket Received
                            </label>
                            <div className="flex items-center">
                                <input
                                    type="radio"
                                    name="ticketReceived"
                                    value="true"
                                    onChange={(e) => setCurrentData({ ...currentData, ticketWritten: e.target.value === "true" })}
                                    className="mr-2 leading-tight"
                                    defaultChecked={currentData.ticketWritten === true}
                                />
                                <span className="text-gray-700">Yes</span>
                                <input
                                    type="radio"
                                    name="ticketReceived"
                                    value="false"
                                    className="ml-4 mr-2 leading-tight"
                                    onChange={(e) => setCurrentData({ ...currentData, ticketWritten: e.target.value === "true" })}
                                    defaultChecked={currentData.ticketWritten === false}
                                />
                                <span className="text-gray-700">No</span>
                            </div>
                        </div>
                        <div className="mb-4">
                            <label className="block text-gray-700 text-sm font-bold mb-2">
                                Fee
                            </label>
                            <input
                                type="text"
                                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                onChange={(e) => {
                                    if (!currentData.violationType) {
                                        setCurrentData({ ...currentData, fee: "" });
                                        e.target.value = "";
                                        toast.error("Please enter a violation type before entering a fee.");
                                    } else {
                                        setCurrentData({ ...currentData, fee: e.target.value });
                                    }
                                }}
                                defaultValue={currentData.fee}
                            />
                        </div>
                        <div className="flex items-center justify-between">
                            <button
                                type="button"
                                className="bg-gray-200 hover:bg-gray-300 text-spotGray font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                                onClick={() => cancelEditor(currentData)}
                            >
                                Cancel
                            </button>
                            <div className="flex flex-row gap-4">
                                {/* <button
                                    type="button"
                                    className="bg-red-400 hover:bg-red-500 hover:shadow-md text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                                    onClick={() => handleDelete(currentData)}
                                >
                                    Delete
                                </button> */}
                                <button
                                    type="button"
                                    className="bg-spotYellow hover:shadow-md text-spotGray font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                                    onClick={() => handleEditSave(currentData)}
                                >
                                    Save
                                </button>
                            </div>
                        </div>
                    </form>
                </div>
                <Toaster richColors />
            </div>
        )
    }

    const [sortOrder, setSortOrder] = useState({
        timeIn: 'asc',
        timeOut: 'asc',
        lot: 'asc',
        spot: 'asc',
        violationType: 'asc'
    });

    const toggleSortOrder = (key) => {
        setSortOrder((prevOrder) => ({
            ...prevOrder,
            [key]: prevOrder[key] === 'asc' ? 'desc' : 'asc'
        }));
    };

    const sortData = (data, key) => {
        const order = sortOrder[key];
        return data.sort((a, b) => {
            if (key === "timeIn" || key === "timeOut" || key === "ticketWrittenTime") {
                return order === 'asc' ? a[key] - b[key] : b[key] - a[key];
            }

            if (key === "fee") {
                if (a[key] === undefined) {
                    return order === 'asc' ? -1 : 1;
                }
                if (b[key] === undefined) {
                    return order === 'asc' ? 1 : -1;
                }
            }

            if (key === "violationType") {
                if (a[key] === undefined) {
                    return order === 'asc' ? -1 : 1;
                }
                if (b[key] === undefined) {
                    return order === 'asc' ? 1 : -1;
                }
            }

            if (a[key] < b[key]) {
                return order === 'asc' ? -1 : 1;
            }
            if (a[key] > b[key]) {
                return order === 'asc' ? 1 : -1;
            }
            return 0;
        });
    };

    const [currentSort, setCurrentSort] = useState("timeIn");

    const handleSort = (key) => {
        toggleSortOrder(key);
        setCurrentSort(key);
        const sortedData = sortData(historyData, key);
        setHistoryData([...sortedData]);
    }

    return (
        <div>
            <div className="flex flex-col ml-24 mt-4 mb-8 font-rubik">
                {loading ? <Loading /> :
                    <div key={"historyTile"}>
                        <h1 className="text-3xl font-bold">Plate History</h1>
                        <h1
                            className="text-md font-medium cursor-pointer"
                            onClick={() => copyToClipboard(licensePlate)}
                            aria-label="Copy license plate number"
                        >
                            {licensePlate}
                        </h1>
                        {historyData.length === 0 ? (
                            <p className="flex items-center justify-center mt-10 w-full text-center font-medium text-lg px-14 py-4">No plate history available.</p>
                        ) : (
                            <div>
                                <div className="flex flex-row justify-between p-4 top-0 bg-white z-40">
                                    <div className="flex flex-col gap-2 text-center">
                                        <h2 className="font-bold">Times on Campus</h2>
                                        <p>{historyData.length}</p>
                                    </div>
                                    <div className="flex flex-col gap-2 text-center">
                                        <h2 className="font-bold">Favorite Lot</h2>
                                        <p>{favoriteLot}</p>
                                    </div>
                                    <div className="flex flex-col gap-2 text-center">
                                        <h2 className="font-bold">Violations Written</h2>
                                        <p>{ticketsWritten}/{totalViolations}</p>
                                    </div>
                                </div>
                                <table className="m-auto mt-4 w-[95%] text-center">
                                    <thead>
                                        <tr style={{
                                            borderBottom: "1px solid #323232",
                                            borderTop: "1px solid #323232",
                                        }}>
                                            <th className="cursor-pointer" onClick={() => { handleSort("timeIn") }}>
                                                <IoMdArrowDropright className={`${currentSort === 'timeIn' ? 'inline-block' : 'hidden'} ${sortOrder.timeIn === 'asc' ? 'rotate-90' : '-rotate-90'}`} />
                                                Time In
                                            </th>
                                            <th className="cursor-pointer" onClick={() => { handleSort("timeOut") }}>
                                                <IoMdArrowDropright className={`${currentSort === 'timeOut' ? 'inline-block' : 'hidden'} ${sortOrder.timeOut === 'asc' ? 'rotate-90' : '-rotate-90'}`} />
                                                Time Out
                                            </th>
                                            <th>Ticket Written Time</th>
                                            <th className="cursor-pointer" onClick={() => { handleSort("lot") }}>
                                                <IoMdArrowDropright className={`${currentSort === 'lot' ? 'inline-block' : 'hidden'} ${sortOrder.lot === 'asc' ? 'rotate-90' : '-rotate-90'}`} />
                                                Lot
                                            </th>
                                            <th className="cursor-pointer" onClick={() => { handleSort("spot") }}>
                                                <IoMdArrowDropright className={`${currentSort === 'spot' ? 'inline-block' : 'hidden'} ${sortOrder.spot === 'asc' ? 'rotate-90' : '-rotate-90'}`} />
                                                Spot
                                            </th>
                                            <th className="cursor-pointer" onClick={() => { handleSort("violationType") }}>
                                                <IoMdArrowDropright className={`${currentSort === 'violationType' ? 'inline-block' : 'hidden'} ${sortOrder.violationType === 'asc' ? 'rotate-90' : '-rotate-90'}`} />
                                                Violation Type
                                            </th>
                                            <th>Ticket Received</th>
                                            <th className="cursor-pointer" onClick={() => { handleSort("fee") }}>
                                                <IoMdArrowDropright className={`${currentSort === 'fee' ? 'inline-block' : 'hidden'} ${sortOrder.fee === 'asc' ? 'rotate-90' : '-rotate-90'}`} />
                                                Fee
                                            </th>
                                            <th>Edit</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            historyData.map((item) => {
                                                let fee = item.fee;
                                                if (item.violationType !== undefined & item.violationType !== "") {
                                                    fee = formatFee(fee === undefined ? 0 : fee)
                                                } else {
                                                    fee = '-';
                                                }
                                                return (
                                                    <tr key={item.timeIn} className="border-b">
                                                        <td>{toDate(item["timeIn"])}</td>
                                                        <td>{toDate(item["timeOut"])}</td>
                                                        <td>{toDate(item["ticketWrittenTime"])}</td>
                                                        <td>{item["lot"]}</td>
                                                        <td>{item["spot"]}</td>
                                                        <td>{item["violationType"]}</td>
                                                        <td>{item["ticketWritten"] === true ? <FaCheck className="w-full" /> : null}</td>
                                                        <td>{fee}</td>
                                                        <td>
                                                            <button
                                                                onClick={() => handleEdit(item)}
                                                                className="bg-transparent cursor-pointer text-gray-500 hover:text-white hover:bg-gray-500 p-1 rounded-lg"
                                                                aria-label="Edit violation"
                                                            >
                                                                <FaEdit />
                                                            </button>
                                                        </td>
                                                    </tr>
                                                );
                                            })}
                                    </tbody>
                                </table>
                            </div>
                        )}
                    </div>
                }
            </div>
            {showEditor ?
                <Editor />
                : null
            }
            <Toaster richColors />
        </div>
    );
}