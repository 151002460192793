/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_ltGLCzKaG",
    "aws_user_pools_web_client_id": "2e69m443tbbqhnlgdfstnv2ksl",
    "aws_cognito_identity_pool_id": "us-east-1:c4dddd22-34a0-41a8-967f-d1a0b1a61df0",
};

export default awsmobile;
