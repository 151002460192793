import * as AmazonCognitoIdentity from 'amazon-cognito-identity-js';
import awsmobile from './aws-exports';
import AWS from 'aws-sdk';

// Pool data from AWS exports
export const poolData = {
    UserPoolId: awsmobile.aws_user_pools_id,
    ClientId: awsmobile.aws_user_pools_web_client_id,
};

export const userPool = new AmazonCognitoIdentity.CognitoUserPool(poolData);
export const user = userPool.getCurrentUser();

export const signUp = async (email, password, attributes) => {
    console.log('Signing up...');

    const attributeList = attributes.map(
        (attr) => new AmazonCognitoIdentity.CognitoUserAttribute(attr)
    );

    return new Promise((resolve, reject) => {
        userPool.signUp(email, password, attributeList, null, (err, result) => {
            console.log('Signing up 2...');
            if (err) {
                console.error(err);
                reject(err.message || JSON.stringify(err));
            } else {
                console.log('User signed up!');
                resolve(result);
            }
        });
    });
};

export const confirmSignUp = async (email, code) => {
    console.log('Confirming sign up...');

    const userData = {
        Username: email,
        Pool: userPool,
    };

    const cognitoUser = new AmazonCognitoIdentity.CognitoUser(userData);

    return new Promise((resolve, reject) => {
        cognitoUser.confirmRegistration(code, true, (err, result) => {
            if (err) {
                console.error(err);
                reject(err.message || JSON.stringify(err));
            } else {
                console.log('User confirmed!');
                resolve(result);
            }
        });
    });
};

export const isLoggedIn = async () => {
    if (user) {
        return new Promise((resolve, reject) => {
            user.getSession((err, session) => {
                if (err) {
                    console.error(err);
                    reject(false);
                } else {
                    console.log('User is logged in!');
                    resolve(session.isValid());
                }
            });
        });
    }
    console.log('User not logged in!');
    return false;
};

export const logout = async () => {
    if (user) {
        user.signOut();
        AWS.config.credentials = null;
        console.log('User logged out!');
    }
};

export const deleteUser = async () => {
    if (user) {
        return new Promise((resolve, reject) => {
            user.deleteUser((err, result) => {
                if (err) {
                    console.error(err);
                    reject(err.message || JSON.stringify(err));
                } else {
                    console.log('User deleted!');
                    resolve(result);
                }
            });
        });
    }
};

export const editAttributes = async (attributes) => {
    console.log('Editing user attributes...');
    if (user) {
        return new Promise((resolve, reject) => {
            user.updateAttributes(attributes, (err, result) => {
                if (err) {
                    console.error(err);
                    reject(err.message || JSON.stringify(err));
                } else {
                    console.log('User attributes updated!');
                    resolve(result);
                }
            });
        });
    }
};

export const changePassword = async (oldPassword, newPassword) => {
    if (user) {
        return new Promise((resolve, reject) => {
            user.changePassword(oldPassword, newPassword, (err, result) => {
                if (err) {
                    console.error(err);
                    reject(err.message || JSON.stringify(err));
                } else {
                    console.log('Password changed!');
                    resolve(result);
                }
            });
        });
    }
};

export const googleSignIn = async () => {
    console.log('Google Sign-In is not directly supported in React JS. Use Firebase or OAuth library for browser-based implementations.');
};

export const googleSignUp = async () => {
    console.log('Google Sign-Up is not directly supported in React JS. Use Firebase or OAuth library for browser-based implementations.');
};

export const googleSignOut = async () => {
    console.log('Google Sign-Out is not directly supported in React JS. Use Firebase or OAuth library for browser-based implementations.');
};

export const googleDeleteUser = async () => {
    console.log('Google Delete User is not directly supported in React JS.');
};