import React, { useState, useEffect } from 'react';
import { Toaster, toast } from 'sonner';
import { postData } from '../../utility/fetcher';

const ViolationEditor = (props) => {
    const [violationData, setViolationData] = useState({});
    const [editedData, setEditedData] = useState({});
    const [dateIn, setDateIn] = useState("");
    const [timeIn, setTimeIn] = useState("");
    const [dateOut, setDateOut] = useState("");
    const [timeOut, setTimeOut] = useState("");
    const [dateWritten, setDateWritten] = useState("");
    const [timeWritten, setTimeWritten] = useState("");
    const orgID = localStorage.getItem("organizationID");

    useEffect(() => {
        // Fetch violation data from session storage and update state
        const dataFromStorage = JSON.parse(sessionStorage.getItem('editData'));
        if (dataFromStorage) {
            setViolationData(dataFromStorage);
            setEditedData(dataFromStorage);

            const dateIn = dataFromStorage["timeIn"] ? dataFromStorage["timeIn"].split("T")[0] : "";
            const timeIn = dataFromStorage["timeIn"] ? dataFromStorage["timeIn"].split("T")[1].substring(0, 5) : "";
            const dateOut = dataFromStorage["timeOut"] ? dataFromStorage["timeOut"].split("T")[0] : "";
            const timeOut = dataFromStorage["timeOut"] ? dataFromStorage["timeOut"].split("T")[1].substring(0, 5) : "";
            const dateWritten = dataFromStorage["ticketWrittenTime"] ? dataFromStorage["ticketWrittenTime"].split("T")[0] : "";
            const timeWritten = dataFromStorage["ticketWrittenTime"] ? dataFromStorage["ticketWrittenTime"].split("T")[1].substring(0, 5) : "";

            setDateIn(dateIn);
            setTimeIn(timeIn);
            setDateOut(dateOut);
            setTimeOut(timeOut);
            setDateWritten(dateWritten);
            setTimeWritten(timeWritten);
        }
    }, []);

    const order = [
        "timeIn",
        "timeOut",
        "ticketWrittenTime",
        "employee",
        "lot",
        "spot",
        "ticketWritten",
        "violationType",
        "fee"
    ]

    //Order the data
    const orderedData = {};
    order.forEach(key => {
        orderedData[key] = violationData[key];
    });

    const handleInputChange = (e) => {
        // Update edited data state when input fields change
        // DateTime format: 2024-02-13T11:48:10.327182
        if (e.target.name === "dateIn") {
            setDateIn(e.target.value);
        } else if (e.target.name === "dateOut") {
            setDateOut(e.target.value);
        } else if (e.target.name === "timeIn") {
            setTimeIn(e.target.value);
        } else if (e.target.name === "timeOut") {
            setTimeOut(e.target.value);
        } else if (e.target.name === "dateWritten") {
            setDateWritten(e.target.value);
        } else if (e.target.name === "timeWritten") {
            setTimeWritten(e.target.value);
        }

        if (e.target.name === "dateIn" || e.target.name === "timeIn") {
            setEditedData({
                ...editedData,
                // eslint-disable-next-line
                ["timeIn"]: (dateIn + "T" + e.target.value + ":00.000000")
            });
        } else if (e.target.name === "dateOut" || e.target.name === "timeOut") {
            setEditedData({
                ...editedData,
                // eslint-disable-next-line
                ["timeOut"]: (dateOut + "T" + e.target.value + ":00.000000")
            });
        } else if (e.target.name === "dateWritten" || e.target.name === "timeWritten") {
            setEditedData({
                ...editedData,
                // eslint-disable-next-line
                ["ticketWrittenTime"]: (dateWritten + "T" + e.target.value + ":00.000000")
            });
        } else {
            setEditedData({
                ...editedData,
                [e.target.name]: e.target.value
            });
        }

        // Clear ticketWrittenTime if ticketWritten is false
        if (e.target.name === "ticketWritten" && e.target.value === "false") {
            setEditedData({
                ...editedData,
                // eslint-disable-next-line
                ["ticketWrittenTime"]: "",
                // eslint-disable-next-line
                ["violationType"]: "N/A",
                // eslint-disable-next-line
                ["fee"]: "",
                [e.target.name]: e.target.value
            });
            setDateWritten("");
            setTimeWritten("");
        }
    };

    const handleSave = () => {
        const listData = JSON.parse(sessionStorage.getItem("violationHistory"));
        const plate = sessionStorage.getItem("currentPlate");

        // Not empty list
        const notEmptyList = [
            "employee",
            "lot",
            "spot"
        ]

        // Check if any of the not empty fields are empty
        let bool = true;
        while (bool === true) {
            for (let i in notEmptyList) {
                if (editedData[notEmptyList[i]] === "") {
                    toast.error(lableFixer(notEmptyList[i]) + " field cannot be empty.");
                }
            }
            bool = false
        }

        // Check if ticketWrittenTime is empty if ticketWritten is true
        if (editedData["ticketWritten"] === "true" && editedData["ticketWrittenTime"] === "") {
            toast.error("Ticket Written Time field cannot be empty if ticket was written.");
            return;
        } else if (editedData["ticketWritten"] === "true" && editedData["violationType"] === "N/A") {
            toast.error("Violation Type cannot be N/A if ticket was written.");
            return;
        } else if (editedData["ticketWritten"] === "false" && editedData["violationType"] !== "N/A") {
            toast.error("Violation Type must be N/A if ticket was not written.");
            return;
        } else if (editedData["ticketWritten"] === "true" && isNaN(editedData["fee"].substring(1))) {
            toast.error("Fee must be a number if ticket was written.");
            return;
        } else if (editedData["ticketWritten"] === "true" && editedData["fee"] === "") {
            toast.error("Fee cannot be empty if ticket was written.");
            return;
        }

        // Check if timeIn is before timeOut
        var currentPlateData = []
        for (let i in listData) {
            if (listData[i]["LicensePlate"] === plate) {
                currentPlateData.push(listData[i])
            }
        }

        const currentHistory = currentPlateData[0].History;
        const index = currentHistory.findIndex(item => item.timeIn === editedData.timeIn);
        currentHistory[index] = editedData;

        try {
            const saveData = async () => {
                console.log("HISTORY Data to save:", currentPlateData[0]);
                const path = `/${orgID}/history`;
                const response = await postData(path, currentPlateData[0]);
                console.log(response.message);
            };

            (async () => {
                await saveData();
                const updatedListData = listData.filter(item => item.LicensePlate !== plate);
                updatedListData.push(currentPlateData[0]);
                sessionStorage.setItem("violationHistory", JSON.stringify(updatedListData));
                props.onButtonClick();
            })();
        } catch (error) {
            console.error("Error saving data:", error);
        }
    };

    const handleDelete = () => {
        const listData = JSON.parse(sessionStorage.getItem("violationHistory"));
        const plate = sessionStorage.getItem("currentPlate");

        var currentPlateData = []
        for (let i in listData) {
            if (listData[i]["LicensePlate"] === plate) {
                currentPlateData.push(listData[i])
            }
        }

        const currentHistory = currentPlateData[0].History;
        const index = currentHistory.findIndex(item => item.timeIn === editedData.timeIn);
        currentHistory.splice(index, 1);

        try {
            const saveData = async () => {
                console.log("HISTORY Data to save:", currentPlateData[0]);
                const path = `/${orgID}/history`;
                const response = await postData(path, currentPlateData[0]);
                console.log(response.message);
                if (response.ok) {
                    console.log("Data saved successfully!");
                } else {
                    console.log("Failed to save data.");
                }
            };

            (async () => {
                await saveData();
                const updatedListData = listData.filter(item => item.LicensePlate !== plate);
                updatedListData.push(currentPlateData[0]);
                sessionStorage.setItem("violationHistory", JSON.stringify(updatedListData));
                props.onButtonClick();
            })();
        } catch (error) {
            console.error("Error saving data:", error);
        }
    };

    const handleCancel = () => {
        props.onButtonClick();
    };

    const lableFixer = (label) => {
        // Fix label to be more readable
        return label.replace(/([A-Z])/g, ' $1').replace(/^./, function (str) { return str.toUpperCase(); });
    };

    // Create violation type dropdown list
    const orgData = JSON.parse(localStorage.getItem("orgData"));
    const violationTypes = orgData["violationTypes"];
    const filteredViolationTypes = violationTypes.filter(type => type !== editedData["violationType"]);

    if (editedData["violationType"] !== "N/A") {
        filteredViolationTypes.push("N/A")
    }

    const editorLabelStyle = "text-md font-bold ml-1"
    const editorInputStyle = "text-sm p-2 border-2 border-gray-700 rounded-md h-8 ml-1 w-full grow focus:outline-none focus:border-yellow-300"

    return (
        <div className='w-[40%] h-fit rounded-3xl p-10 pt-4 m-auto mt-20 shadow-2xl bg-white font-rubik'>
            <h1 className='text-2xl font-bold text-spotGray'>Edit Violation</h1>
            <div className='flex flex-col pointer-events-none opacity-50'>
                <div>
                    <label htmlFor="timeIn" className={editorLabelStyle}>{lableFixer("timeIn")}</label>
                    <div className='flex'>
                        <input
                            type='date'
                            alt='date in'
                            name="dateIn"
                            id="dateIn"
                            className={editorInputStyle}
                            value={dateIn}
                            onChange={handleInputChange}
                            disabled
                        />
                        <input
                            type='time'
                            alt='time in'
                            name="timeIn"
                            id="timeIn"
                            className={editorInputStyle}
                            value={timeIn}
                            onChange={handleInputChange}
                            disabled
                        />
                    </div>
                </div>
                <div className='mt-2'>
                    <label htmlFor="timeOut" className={editorLabelStyle}>{lableFixer("timeOut")}</label>
                    <div className='flex'>
                        <input
                            type='date'
                            alt='date out'
                            name="dateOut"
                            id="dateOut"
                            className={editorInputStyle}
                            value={dateOut}
                            onChange={handleInputChange}
                            disabled
                        />
                        <input
                            type='time'
                            alt='time out'
                            name="timeOut"
                            id="timeOut"
                            className={editorInputStyle}
                            value={timeOut}
                            onChange={handleInputChange}
                            disabled
                        />
                    </div>
                </div>
            </div>
            <div className='mt-2'>
                <label htmlFor="ticketWrittenTime" className={editorLabelStyle}>{lableFixer("ticketWrittenTime")}</label>
                <br />
                <div className='flex'>
                    <input
                        type='date'
                        alt='date written'
                        name="dateWritten"
                        id="dateWritten"
                        className={editorInputStyle}
                        value={dateWritten}
                        onChange={handleInputChange}
                    />
                    <input
                        type='time'
                        alt='time written'
                        name="timeWritten"
                        id="timeWritten"
                        className={editorInputStyle}
                        value={timeWritten}
                        onChange={handleInputChange}
                    />
                </div>
            </div>
            <div className='mt-2'>
                <label htmlFor="employee" className={editorLabelStyle}>{lableFixer("employee")}</label>
                <br />
                <input
                    type='text'
                    alt='employee'
                    name="employee"
                    id="employee"
                    className={editorInputStyle}
                    value={editedData["employee"]}
                    onChange={handleInputChange}
                />
            </div>
            <div className='mt-2'>
                <label htmlFor="lot" className={editorLabelStyle}>{lableFixer("lot")}</label>
                <br />
                <input
                    type='text'
                    alt='lot'
                    name="lot"
                    id="lot"
                    className={editorInputStyle}
                    value={editedData["lot"]}
                    onChange={handleInputChange}
                />
            </div>
            <div className='mt-2'>
                <label htmlFor="spot" className={editorLabelStyle}>{lableFixer("spot")}</label>
                <br />
                <input
                    type='text'
                    alt='spot'
                    name="spot"
                    id="spot"
                    className={editorInputStyle}
                    value={editedData["spot"]}
                    onChange={handleInputChange}
                />
            </div>
            <div className='mt-2'>
                <label htmlFor="ticketWritten" className={editorLabelStyle}>{lableFixer("ticketWritten")}</label>
                <br />
                <select
                    name="ticketWritten"
                    alt='ticket written'
                    id="ticketWritten"
                    className='rounded-md h-8 ml-1 w-full grow mt-2 mb-2 font-bold text-sm pl-2'
                    value={editedData["ticketWritten"]}
                    onChange={handleInputChange}
                >
                    <option value={true}>True</option>
                    <option value={false}>False</option>
                </select>
            </div>
            <div>
                <label htmlFor="violationType" className={editorLabelStyle}>{lableFixer("violationType")}</label>
                <select
                    name="violationType"
                    alt='violation type'
                    id="violationType"
                    className='rounded-md h-8 ml-1 w-full grow mt-2 mb-2 font-bold text-sm pl-2'
                    value={editedData["violationType"]}
                    onChange={handleInputChange}
                >
                    <option value={true}>{editedData["violationType"]}</option>
                    {filteredViolationTypes.map((type, index) => (
                        <option key={index} value={type}>{type}</option>
                    ))}
                </select>
            </div>
            <div>
                <label htmlFor="fee" className={editorLabelStyle}>{lableFixer("fee")}</label>
                <br />
                <input
                    type='text'
                    alt='fee'
                    name="fee"
                    id="fee"
                    className={editorInputStyle}
                    value={editedData["fee"]}
                    onChange={handleInputChange}
                />
            </div>
            <div className='flex flex-row gap-2'>
                <button
                    className='block mx-auto w-[150px] font-bold text-lg bg-gray-100 hover:bg-gray-300 shadow-md rounded-xl mt-6 h-8'
                    onClick={handleCancel}
                    aria-label='Cancel edit'
                >
                    Cancel
                </button>
                <button
                    className='block mx-auto w-[150px] font-bold text-lg bg-red-200 hover:bg-red-300 shadow-md rounded-xl mt-6 h-8'
                    onClick={handleDelete}
                    aria-label='Delete violation'
                >
                    Delete
                </button>
                <button
                    className='block mx-auto w-[150px] font-bold text-lg bg-yellow-200 hover:bg-yellow-300 shadow-md rounded-xl mt-6 h-8'
                    onClick={handleSave}
                    aria-label='Save violation'
                >
                    Save
                </button>
            </div>
            <Toaster richColors />
        </div >
    );
};

export default ViolationEditor;