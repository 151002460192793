import React, { useState } from "react";
import emailjs from "emailjs-com";
import { Toaster, toast } from "sonner";

const ContactUs = () => {
    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [phone, setPhone] = useState("");
    const [school, setSchool] = useState("");
    const [problem, setProblem] = useState("");
    const [description, setDescription] = useState("");

    const handleSubmit = (e) => {
        e.preventDefault();

        const templateParams = {
            name,
            email,
            phone,
            school,
            problem,
            description,
        };

        emailjs
            .send(
                "service_dsu84xg",
                "template_auk2coh",
                templateParams,
                "_mhIZPOql0BHuIkf1"
            )
            .then((response) => {
                console.log("Email sent successfully!");
            })
            .catch((error) => {
                console.error("Error sending email:", error);
                toast.error("Help ticket submission failed. Please try again.");
            });

        // Reset form fields
        setName("");
        setEmail("");
        setPhone("");
        setSchool("");
        setProblem("");
        setDescription("");
        toast.success("Help ticket submitted, we'll reach out soon!");
    };

    const containerStyle = {
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: "white",
        padding: "20px",
        borderRadius: "10px",
        boxShadow: "0 1px 28px 10px rgba(0, 0, 0, 0.1)",
        width: "100%",
        maxWidth: "500px",
        margin: "0 auto",
        zIndex: "999",
        overflow: "hidden",
    };

    const titleStyle = {
        fontSize: "20px",
        fontWeight: "bold",
        marginBottom: "-8px",
        color: "#323232"
    };

    const inputStyle = {
        width: "100%",
        maxWidth: "500px",
        height: "30px",
        borderRadius: "5px",
        border: "1px solid #ccc",
        marginBottom: "10px",
        fontSize: "16px",
    };

    const helpForm = () => {
        return (
            <div style={containerStyle}>
                <h1 style={{ color: "#323232", fontSize: "50px", fontWeight: "bold", marginBottom: "0px", marginTop: "-10px" }}>Contact Us</h1>
                <form aria-label="Help Form" onSubmit={handleSubmit}>
                    <label>
                        <div style={titleStyle}>
                            Name
                        </div>
                        <input
                            type="text"
                            alt="Name"
                            value={name}
                            style={inputStyle}
                            onChange={(e) => setName(e.target.value)}
                        />
                    </label>
                    <br />
                    <div style={{ display: "flex", flexDirection: "row" }}>
                        <label>
                            <div style={titleStyle}>
                                Email
                            </div>
                            <input
                                type="email"
                                alt="Email"
                                value={email}
                                style={{
                                    width: "240px",
                                    height: "30px",
                                    borderRadius: "5px",
                                    border: "1px solid #ccc",
                                    marginBottom: "10px",
                                    marginRight: "10px",
                                    fontSize: "16px",
                                }}
                                onChange={(e) => setEmail(e.target.value)}
                            />
                        </label>
                        <br />
                        <label>
                            <div style={titleStyle}>
                                Phone Number
                            </div>
                            <input
                                type="tel"
                                alt="Phone Number"
                                value={phone}
                                style={{
                                    marginLeft: "5px",
                                    width: "240px",
                                    height: "30px",
                                    borderRadius: "5px",
                                    border: "1px solid #ccc",
                                    marginBottom: "10px",
                                    fontSize: "16px",
                                }}
                                onChange={(e) => setPhone(e.target.value)}
                            />
                        </label>
                    </div>
                    <label>
                        <div style={titleStyle}>
                            School
                        </div>
                        <input
                            type="text"
                            alt="School"
                            value={school}
                            style={inputStyle}
                            onChange={(e) => setSchool(e.target.value)}
                        />
                    </label>
                    <br />
                    <label>
                        <div style={titleStyle}>
                            Request Title
                        </div>
                        <input
                            type="text"
                            alt="Request Title"
                            value={problem}
                            style={inputStyle}
                            onChange={(e) => setProblem(e.target.value)}
                        />
                    </label>
                    <br />
                    <label>
                        <div style={titleStyle}>
                            Description
                        </div>
                        <br />
                        <textarea
                            value={description}
                            aria-label="Description"
                            style={{
                                width: "500px",
                                maxWidth: "500px",
                                minWidth: "500px",
                                height: "150px",
                                minHeight: "150px",
                                borderRadius: "5px",
                                border: "1px solid #ccc",
                                marginBottom: "10px",
                                fontSize: "16px",
                            }}
                            onChange={(e) => setDescription(e.target.value)}
                        />
                    </label>
                    <br />
                    <button
                        style={{
                            width: "100px",
                            height: "35px",
                            borderRadius: "12px",
                            backgroundColor: "#323232",
                            fontSize: "20px",
                            fontWeight: "bold",
                            color: "white",
                            cursor: "pointer",
                            border: "transparent",
                            boxShadow: "0px 0px 18px 4px rgba(0, 0, 0, 0.12)",
                        }}
                        type="submit"
                        aria-label="Submit"
                    >
                        Submit
                    </button>
                </form>
            </div>
        )
    }

    const square = (width, height, x, y, rotation) => {
        return (
            <div style={{
                position: "absolute",
                width: `${width * .75}%`,
                height: `${height * 1.25}%`,
                backgroundColor: "#D9D9D9",
                transform: `rotate(${rotation}deg)`,
                borderRadius: "45px",
                opacity: "0.2",
                boxShadow: "0 0px 36px 18px rgba(0, 0, 0, 1.45)",
                top: `${y}vh`,
                left: `${x}vw`,
                zIndex: "0",
            }}></div>
        );
    }

    return (
        <div style={{ overflow: "visible" }}>
            <div style={{ position: "absolute" }}>
                <Toaster position='bottom-right' richColors />
            </div>
            <div className="contact-us-container" style={{ zIndex: "999" }}>
                {helpForm()}
            </div>
            <div style={{
                position: "absolute",
                width: "100%",
                height: "100%",
                zIndex: "1",
                overflow: "hidden",
            }}>
                {square(50, 50, 20, -45, 40)}
                {square(50, 50, 0, 80, -20)}
                {square(50, 50, 90, 40, -20)}
            </div>
        </div>
    );
};

export default ContactUs;