import React, { useState } from "react";
import { postData } from "../utility/fetcher";
import { Toaster, toast } from "sonner";
import { isObjDifferent } from "../utility/isObjDifferent";

export const ViolationTypes = () => {
    const orgData = JSON.parse(localStorage.getItem("orgData"));
    const [tableData, setTableData] = useState(orgData);
    const [saveText, setSaveText] = useState("Save");

    const onEdit = (key, value) => {
        const newData = {
            ...tableData,
            violationTypes: {
                ...tableData.violationTypes,
                [key]: value
            }
        };
        setTableData(newData);
    };

    const handleSave = async () => {
        setSaveText("Saving...");
        const toastId = toast.loading("Saving data...");
        const newData = orgData;

        const different = isObjDifferent(newData, tableData);

        if (!different) {
            toast.info("No changes detected.", { id: toastId });
            setSaveText("Save");
            return;
        }

        newData.permitTypes = tableData;

        try {
            console.log("Data to save:", newData);
            const path = "/general/organizations";
            const response = await postData(path, newData);
            toast.success("Permit data saved successfully!", { id: toastId });
            console.log(response.message)

            setSaveText("Saved");
            setTimeout(() => {
                setSaveText("Save");
            }, 2000);
        } catch (error) {
            console.error("Error saving data:", error);
            toast.error("Permit data failed to save. Please try again.", { id: toastId });
        }
    };

    const SingleType = ({ title, value }) => {
        const ogBaseFee = value.feeIncrement;
        const [feeInput, setFeeInput] = useState(`$${parseFloat(ogBaseFee).toFixed(2)}`);

        const handleFeeChange = (e) => {
            setFeeInput(e.target.value);
        };

        const handleFeeBlur = () => {
            const rawValue = parseFloat(feeInput.replace(/[^0-9.]/g, '')) || 0;
            const formattedValue = `$${rawValue.toFixed(2)}`;
            setFeeInput(formattedValue);

            // Update state if the value is changed
            if (formattedValue !== `$${parseFloat(ogBaseFee).toFixed(2)}`) {
                onEdit(title, { ...value, feeIncrement: rawValue.toFixed(2).toString() });
            }
        };

        const handleFeeInputClick = () => {
            // Clear value on input click
            setFeeInput("");
        };

        const handleKeyPress = (e) => {
            if (e.key === "Enter") {
                e.target.blur();
            }
        };

        return (
            <div className="flex flex-row w-full justify-between items-center gap-4 pr-10">
                <h2 className='font-bold text-lg'>{title}</h2>
                <div className="frc gap-24">
                    <div className="fcc gap-1">
                        <strong>Active</strong>
                        <input
                            type="checkbox"
                            alt="Active"
                            checked={value.active}
                            onChange={() => onEdit(title, { ...value, active: !value.active })}
                            className="accent-spotYellow cursor-pointer"
                        />
                    </div>
                    <div className="fcc gap-1">
                        <strong>Fee Increment</strong>
                        <input
                            type="text"
                            alt="Fee Increment"
                            value={feeInput}
                            onChange={handleFeeChange}
                            onClick={handleFeeInputClick}
                            onBlur={handleFeeBlur}
                            onKeyDown={handleKeyPress}
                            className="accent-spotYellow text-center border rounded-md outline-spotYellow"
                        />
                    </div>
                    <div className="fcc gap-1">
                        <strong>Number of Warnings</strong>
                        <select
                            value={value.warnings}
                            aria-label="Number of Warnings"
                            onChange={(e) => onEdit(title, { ...value, warnings: e.target.value })}
                            className="accent-spotYellow pl-2 border rounded-md outline-spotYellow w-1/2 h-7"
                        >
                            <option value="0">0</option>
                            <option value="1">1</option>
                            <option value="2">2</option>
                            <option value="3">3</option>
                        </select>
                    </div>
                </div>
            </div>
        );
    };

    return (
        <div className="w-screen pl-24 p-4">
            <div className="fcc">
                <div className="flex flex-col w-full text-center mt-10 mb-4 text-3xl font-bold">Violation Types</div>
                {Object.keys(tableData.violationTypes).map((key, index) => (
                    <div key={index} className="flex flex-row justify-between items-center w-full shadow-md rounded-lg border my-2 p-2 pl-4 py-4">
                        <SingleType title={key} value={tableData.violationTypes[key]} />
                    </div>
                ))}
                <button
                    className='h-fit w-fit px-4 py-1 yellow-gradient font-bold text-lg ml-auto rounded-lg shadow-md'
                    onClick={handleSave}
                    aria-label="Save Changes"
                >
                    {saveText}
                </button>
            </div>
            <Toaster position='bottom-right' richColors closeButton />
        </div>
    );
};