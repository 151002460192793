import React, { useEffect, useState } from "react";
import { Navigate } from "react-router-dom";
import { CognitoUser, AuthenticationDetails } from "amazon-cognito-identity-js";
import UserPool from "../utility/UserPool";
import Cookies from "js-cookie";
import { fetchAllData } from "../utility/fetcher";
import "./login.css";
import { isLoggedIn, logout, userPool, googleSignIn } from '../utility/awsMethods';
import * as AWS from 'aws-sdk/global';
import * as AmazonCognitoIdentity from 'amazon-cognito-identity-js';
import { GoogleLogin } from '@react-oauth/google';
import { toast, Toaster } from "sonner";

const Login = () => {
    const [organization, setOrganization] = useState("");
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [errorMessage, setErrorMessage] = useState("");
    const [cover, setCover] = useState(false)
    const [signInOrgs, setSignInOrgs] = useState([]);
    const [buttonMessage, setButtonMessage] = useState("Login");
    const jsCookie = Cookies.get("token");

    const storeData = async () => {
        try {
            const result = await fetchAllData({ "signInOrgs": "/public/getOrganizations" });
            setSignInOrgs(result.signInOrgs.organizations);
        } catch (error) {
            console.error("Error fetching data:", error);
        }
    };

    const handleSubmit = async (event) => {
        try {
            event.preventDefault();
        } catch (error) {
            console.log("Submitting Demo");
        }

        const currentOrg = email === "demo@spotparking.app" ? "Demo" : organization;

        if (currentOrg === "" && email !== "demo@spotparking.app") {
            setErrorMessage("Please select an organization.");
            return;
        } else if (email === "") {
            setErrorMessage("Please enter an email.");
            return;
        } else if (password === "") {
            setErrorMessage("Please enter a password.");
            return;
        }

        setButtonMessage("Logging in...");
        const tempEmail = email.trim().toLowerCase();
        var authenticationData = {
            Username: tempEmail,
            Password: password,
        };

        var authenticationDetails = new AmazonCognitoIdentity.AuthenticationDetails(authenticationData);

        var userData = {
            Username: tempEmail,
            Pool: userPool,
        };

        var cognitoUser = new AmazonCognitoIdentity.CognitoUser(userData);
        cognitoUser.authenticateUser(authenticationDetails, {
            onSuccess: async function (result) {
                // Save User Data to DB
                let allowed = false
                const urls = { "auth": `/public/validateUser?email=${tempEmail.trim().toLowerCase()}&orgName=${currentOrg}&platform=admin` };
                const response = await fetchAllData(urls);

                // Check if user is allowed to access the application
                allowed = response.auth;
                if (!allowed) {
                    setErrorMessage("User not allowed to access this application.");
                    setButtonMessage("Login");
                    return;
                }

                // Store token
                var accessToken = result.getAccessToken().getJwtToken();
                localStorage.setItem('token', accessToken);
                Cookies.set("token", accessToken, { expires: 7 });
                AWS.config.region = 'us-east-1';

                // Store user attributes
                localStorage.setItem("organization", currentOrg);
                cognitoUser.getUserAttributes(function (err, result) {
                    if (err) {
                        console.log(err.message || JSON.stringify(err));
                        return;
                    };
                    if (result) {
                        for (let i = 0; i < result.length; i++) {
                            const name = result[i].getName();
                            const value = result[i].getValue();
                            localStorage.setItem(name, value);
                            console.log(`Stored ${name} as ${value}`);
                        };
                    };
                });

                // Redirect to home page
                window.location.href = "/";
            },

            onFailure: function (err) {
                setErrorMessage(err.message || JSON.stringify(err));
                return;
            },
        });
    };

    // Add this function for Google Sign-In
    // Global AWS Configuration
    AWS.config.update({
        region: 'us-east-1', // Replace with your AWS region
    });

    // Google Sign-In handler
    const handleGoogleSignIn = async (credentialResponse) => {
        try {
            const googleToken = credentialResponse.credential; // Google token
            const clientId = "2e69m443tbbqhnlgdfstnv2ksl"; // Your app client ID
            const identityPoolId = "us-east-1:505c99c4-e14b-407c-9685-38718acca036"; // Your Identity Pool ID

            // Construct the Google token request to Cognito Identity Pool
            const logins = {
                'accounts.google.com': googleToken, // Google token as a federated identity provider
            };

            const cognitoIdentity = new AWS.CognitoIdentity();
            const params = {
                IdentityPoolId: identityPoolId, // Replace with your Identity Pool ID
                Logins: logins,  // Provide the Google token for authentication
            };

            cognitoIdentity.getId(params, (err, data) => {
                if (err) {
                    console.error("Error getting identity ID", err);
                    setErrorMessage("Google Sign-In failed");
                } else {
                    // To assume a role and get AWS credentials (optional, based on your app):
                    const identityId = data.IdentityId;
                    const credentialsParams = {
                        IdentityId: identityId,
                        Logins: logins,
                    };

                    cognitoIdentity.getCredentialsForIdentity(credentialsParams, async (error, credentialsData) => {
                        if (error) {
                            console.error("Error getting credentials", error);
                        } else {
                            const { AccessKeyId, SecretKey, SessionToken } = credentialsData.Credentials;
                            console.log("Cognito credentials:", JSON.stringify(credentialsData));
                            let email = "";

                            AWS.config.credentials = new AWS.CognitoIdentityCredentials({
                                IdentityId: identityId,
                                Logins: logins,
                            });

                            AWS.config.credentials.get(async (err) => {
                                if (err) {
                                    console.error("Error getting AWS credentials", err);
                                } else {
                                    const idToken = googleToken; // Use the Google token as idToken
                                    const decodedToken = JSON.parse(atob(idToken.split('.')[1]));
                                    email = decodedToken.email;
                                    localStorage.setItem('email', email);
                                    console.log(`\x1b[34mEMAIL: ${email}\x1b[0m`);

                                    let allowed = false
                                    const urls = { "auth": `/public/validateUser?email=${email.trim().toLowerCase()}&orgName=${organization}&platform=admin` };
                                    const response = await fetchAllData(urls);

                                    console.log(response)

                                    // Check if user is allowed to access the application
                                    allowed = response.auth;
                                    if (!allowed) {
                                        setErrorMessage("User not allowed to access this application.");
                                        setButtonMessage("Login");
                                        return;
                                    } else {
                                        // Save tokens or take appropriate action
                                        localStorage.setItem('accessKey', AccessKeyId);
                                        localStorage.setItem('secretKey', SecretKey);
                                        localStorage.setItem('sessionToken', SessionToken);
                                        localStorage.setItem('organization', organization);

                                        // Optionally, you can also save the token in cookies for future use
                                        Cookies.set("token", SessionToken, { expires: 7 });

                                        // Redirect to home page or next route
                                        window.location.href = "/";
                                    };
                                };
                            });
                        };
                    });
                };
            });
        } catch (error) {
            console.error("Google Sign-In failed:", error);
            setErrorMessage("Failed to sign in with Google");
        }
    };

    const demoSubmit = async () => {
        setButtonMessage("Logging in...");
        setErrorMessage("");
        setOrganization("Demo");
        setEmail("demo@spotparking.app");
        setPassword("spotparking");

        setTimeout(() => {
            handleSubmit();
        }, 1000);
    };

    useEffect(() => {
        storeData();
    }, []);

    if (jsCookie !== undefined) {
        console.log("Authenticated:", true);
        return <Navigate to="/" />;
    } else {
        console.log("Authenticated:", false);
        Cookies.remove("token");

        const allowedPaths = ["/login", "/contact-us", "/reset-password", "/signup"];
        if (!allowedPaths.includes(window.location.pathname)) {
            window.location.href = "/login";
            sessionStorage.clear();
            localStorage.clear();
        };
    };

    return (
        <div className="bg-gradient-to-t from-spotYellow to-gray-700 h-screen overflow-hidden relative justify-center flex items-center font-rubik">
            <div className={`flex flex-col justify-center items-center gap-4 ${cover ? "z-0" : "z-40"}`}>
                <div className="w-fit h-fit bg-white rounded-3xl p-8 z-50 text-spotGray justify-center items-center shadow-lg">
                    <h1 className="text-5xl font-bold self-center mb-4">Login</h1>
                    <p className="text-2xl text-center mb-6">Log in using credentials provided by <br />the Spot Parking team.</p>
                    <div className="flex flex-col justify-center items-center">
                        <form aria-label="Login Form" onSubmit={handleSubmit} className="w-full flex flex-col justify-center items-center">
                            <label className="w-full select-none">
                                <div className="font-bold text-xl">
                                    Organization
                                </div>
                                <select
                                    value={organization}
                                    aria-label="Select Organization"
                                    className="w-full h-[40px] border border-gray-400 outline-spotYellow rounded-lg pl-2 text-spotGray bg-white mb-4"
                                    onChange={(e) => {
                                        setOrganization(e.target.value)
                                        setErrorMessage("")
                                    }}
                                >
                                    <option value="">Select Organization</option>
                                    {signInOrgs.map((org) => (
                                        <option key={org} value={org}>{org}</option>
                                    ))}
                                </select>
                            </label>
                            <label className={`w-full ${organization ? "opacity-100" : "opacity-50 pointer-events-none"}`}>
                                <div className="font-bold text-xl select-none">
                                    Email
                                </div>
                                <input
                                    type="text"
                                    alt="Email"
                                    value={email}
                                    className="w-full h-[40px] border border-gray-400 outline-spotYellow rounded-lg pl-2 text-spotGray bg-white mb-4"
                                    onChange={(e) => {
                                        setEmail(e.target.value)
                                        setErrorMessage("")
                                    }}
                                />
                            </label>
                            <label className={`w-full ${organization ? "opacity-100" : "opacity-50 pointer-events-none"}`}>
                                <div className="font-bold text-xl select-none">
                                    Password
                                </div>
                                <input
                                    type="password"
                                    alt="Password"
                                    value={password}
                                    className="w-full h-[40px] border border-gray-400 outline-spotYellow rounded-lg pl-2 text-spotGray bg-white mb-4"
                                    onChange={(e) => {
                                        setPassword(e.target.value)
                                        setErrorMessage("")
                                    }}
                                />
                            </label>
                            {errorMessage !== "" ?
                                <p className="text-red-500 fond-bold mb-2">
                                    {errorMessage}
                                </p> :
                                <div className="mb-1">
                                    <br />
                                </div>
                            }
                            <div
                                className={`w-fit px-8 h-12 mb-5 fcc cursor-pointer  bg-gradient-to-r from-spotYellow to-yellow-200 rounded-2xl font-bold text-2xl transition-all duration-100 select-none hover:bg-gradient-to-r hover:from-white hover:to-white hover:text-spotYellow hover:border-2 hover:border-spotYellow hover:shadow-lg ${organization && email && password ? "opacity-100" : "opacity-50 pointer-events-none"}`}
                                onClick={handleSubmit}
                                aria-label="Login Button"
                            >
                                {buttonMessage}
                            </div>

                            {/* Google Sign In Button */}
                            <div
                                className={`${organization ? "opacity-100" : "opacity-50"} select-none`}
                                onClick={() => { !organization && toast.error("Please select an organization first.") }}
                            >
                                <div className={`${organization ? "cursor-pointer" : "cursor-not-allowed pointer-events-none"} `}>
                                    <GoogleLogin
                                        shape="circle"
                                        onSuccess={handleGoogleSignIn}
                                        onError={() => {
                                            console.log('Login Failed');
                                        }}
                                    />
                                </div>
                            </div>
                        </form>
                        <div className="login-bottom-links flex flex-col gap-2 text-center select-none">
                            <p>Don't have an account? <span><a className="ml-2 underline" href="/contact-us"> Contact Us</a></span></p>
                            {/* <p>or <span><a className="ml-1 underline" href="/signup "> Sign Up</a></span></p> */}
                            {/* <a className="ml-2 underline" href="/reset-password">Recover Password</a> */}
                        </div>
                    </div>
                </div>
                <div className="flex flex-row justify-between rounded-3xl h-16 w-full bg-white z-50 shadow-lg select-none">
                    <div className="flex flex-row font-bold text-lg text-center self-center ml-4">
                        <span className=" drop-shadow-lg mr-2">👀</span> Looking for the demo?
                    </div>
                    <div className="flex justify-center items-center bg-spotGray cursor-pointer text-white rounded-2xl w-30 mr-2 h-12 px-2 self-center font-bold text-xl transition-all duration-200 hover:bg-spotYellow hover:text-spotGray hover:shadow-lg" onClick={demoSubmit}>
                        Click Here
                    </div>
                </div>
            </div>
            <div className="absolute w-screen h-screen z-10 overflow-hidden">
                <div className="bg-gray-200/20 rounded-3xl shadow-sm transition-all animate-floatMultiDirection1 absolute w-[70%] h-[50%] top-[70%] left-[70%]" ></div>
                <div className="bg-gray-200/20 rounded-3xl shadow-sm transition-all animate-floatMultiDirection2 absolute w-[40%] h-[50%] top-[70%] -left-[10%] rotate-45" ></div>
                <div className="bg-gray-200/20 rounded-3xl shadow-sm transition-all animate-floatMultiDirection3 absolute w-[50%] h-[45%] -top-[15%] left-[35%]" ></div>
            </div>
            <div className={`absolute top-0 left-0 right-0 bottom-0 transition-all duration-[2s] bg-white ${cover ? "opacity-100 z-50" : "opacity-0 z-0"}`}></div>
            <Toaster richColors />
        </div>
    );
}

export default Login;