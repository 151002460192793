import React, { useEffect, useState } from "react";
import { fetchAllData, postData } from "../utility/fetcher";
import { GoPlus } from "react-icons/go";
import { HiMiniXMark } from "react-icons/hi2";
import { IoMdArrowDropright } from "react-icons/io";

export const CustomDurations = () => {
    const [orgData, setOrgData] = useState();
    const [allCustomDurations, setAllCustomDurations] = useState({});
    const ogOrgData = JSON.parse(localStorage.getItem("orgData"));

    useEffect(() => {
        const getData = async () => {
            const response = await fetchAllData({ "orgData": `/public/getOrgByName?orgName=${ogOrgData.orgName}` });
            setOrgData(response.orgData);
            setAllCustomDurations(response.orgData.customDurations);
        }
        getData();
        // eslint-disable-next-line
    }, []);

    const saveToDatabase = async (data) => {
        const response = await postData("/general/organizations", data);
        console.log(response);
        localStorage.setItem("orgData", JSON.stringify(data));
        setAllCustomDurations(data.customDurations);
    }

    const SingleDuration = ({ duration }) => {
        const [newModal, showNewModal] = useState(false);
        const [open, setOpen] = useState(false);
        const [object, setObject] = useState(allCustomDurations[duration]);

        const closeNewModal = () => {
            showNewModal(false);
            setNewCustomDuration(newCustomDurationBlank);
        }

        const saveNewCustomDuration = async () => {
            const updatedDurations = object.concat(newCustomDuration);
            const newCustomDurations = { ...allCustomDurations, [duration]: updatedDurations };

            setObject(updatedDurations);
            saveToDatabase({ ...orgData, customDurations: newCustomDurations });
            setAllCustomDurations(newCustomDurations);
            closeNewModal();
        }

        const newCustomDurationBlank = {
            name: "",
            startMonth: "",
            startDay: "",
            endMonth: "",
            endDay: "",
        };

        const [newCustomDuration, setNewCustomDuration] = useState(newCustomDurationBlank);

        const deleteCustomDuration = async (durationName) => {
            const updatedDurations = object.filter(d => d.name !== durationName);
            const newCustomDurations = { ...allCustomDurations, [duration]: updatedDurations };

            setObject(updatedDurations);
            saveToDatabase({ ...orgData, customDurations: newCustomDurations });
            setAllCustomDurations(newCustomDurations);
        }

        const deleteTopLevelDuration = async (durationName) => {
            const newCustomDurations = { ...allCustomDurations };
            delete newCustomDurations[durationName];
            saveToDatabase({ ...orgData, customDurations: newCustomDurations });
            setAllCustomDurations(newCustomDurations);
        }

        function getOrdinal(number) {
            // Remove leading zero if present
            number = parseInt(number, 10);

            const suffixes = ["th", "st", "nd", "rd"];
            const mod100 = number % 100;
            const mod10 = number % 10;

            if (mod100 >= 11 && mod100 <= 13) {
                return number + suffixes[0]; // Special case for 11th, 12th, 13th
            }

            return number + (suffixes[mod10] || suffixes[0]);
        }

        return (
            <div key={duration} className="flex flex-col justify-center items-center border w-full p-4 rounded-lg shadow-md relative">
                <div onClick={() => setOpen(!open)} className="flex group flex-row justify-start items-center cursor-pointer w-full gap-2">
                    <div onClick={() => deleteTopLevelDuration(duration)} className="group-hover:opacity-100 opacity-0 absolute flex justify-center items-center -top-3 -right-3 w-6 h-6 rounded-full bg-gray-400 cursor-pointer hover:bg-red-200">
                        <HiMiniXMark className="text-white m-auto group-hover:text-spotGray" size={20} />
                    </div>
                    <IoMdArrowDropright className={`text-2xl text-spotGray ${open ? 'rotate-90' : 'rotate-0'} transition-all duration-100`} />
                    <h2 className="text-xl font-semibold">{duration}</h2>
                </div>
                {open && (
                    <div className="flex flex-col gap-4 w-full mt-4 mb-16">
                        {object.map((duration, index) => {
                            return (
                                <div key={index} className="flex flex-row justify-between items-center border w-full p-4 rounded-lg cursor-pointer shadow-md hover:bg-gray-100 group relative">
                                    <div onClick={() => deleteCustomDuration(duration.name)} className="group-hover:opacity-100 opacity-0 absolute flex justify-center items-center -top-3 -right-3 w-6 h-6 rounded-full bg-gray-400 cursor-pointer hover:bg-red-200">
                                        <HiMiniXMark className="text-white m-auto group-hover:text-spotGray" size={20} />
                                    </div>
                                    <h2 className="text-xl font-semibold">{duration.name}</h2>
                                    <div className="flex flex-row justify-evenly w-72">
                                        <div className="flex flex-col justify-center items-center">
                                            <h3 className="text-lg font-semibold">Start Day</h3>
                                            <div className="flex flex-row gap-1">
                                                <p>{new Date(0, parseInt(duration.startMonth) - 1).toLocaleString('default', { month: 'long' })}</p>
                                                <p>{getOrdinal(duration.startDay)}</p>
                                            </div>
                                        </div>
                                        <div className="flex flex-col justify-center items-center">
                                            <h3 className="text-lg font-semibold">End Day</h3>
                                            <div className="flex flex-row gap-1">
                                                <p>{new Date(0, parseInt(duration.endMonth) - 1).toLocaleString('default', { month: 'long' })}</p>
                                                <p>{getOrdinal(duration.endDay)}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            );
                        })}
                        {/* ADD BUTTON */}
                        <div
                            className="absolute bottom-4 right-4 border w-24 h-12 rounded-lg cursor-pointer fcc hover:shadow-md transition-all duration-100"
                            onClick={(e) => {
                                e.stopPropagation(); // Prevent click event from propagating to parent elements
                                showNewModal(true);
                            }}
                            aria-label="Add Permit"
                        >
                            <GoPlus className="text-6xl m-auto text-gray-400" />
                        </div>
                    </div>
                )}
                {newModal && (
                    <div className="absolute flex h-screen w-screen justify-center items-center">
                        <div onClick={closeNewModal} className="fixed top-0 left-0 w-screen h-screen bg-black bg-opacity-50 z-[10001]"></div>
                        <div className="absolute top-1/2 left-1/2 -translate-x-1/2 translate-y-1/2 bg-white p-8 rounded-lg shadow-lg w-96 z-[10002]">
                            <h2 className="text-2xl font-bold">New {duration} Duration</h2>
                            <div className="flex flex-col gap-4 mt-4">
                                <div className="flex flex-col gap-2 w-full">
                                    <label className="text-lg font-semibold">Title</label>
                                    <input
                                        type="text"
                                        placeholder="Name"
                                        value={newCustomDuration.name}
                                        onChange={(e) => setNewCustomDuration({ ...newCustomDuration, name: e.target.value })}
                                        className="border p-2 rounded-lg outline-spotYellow"
                                    />
                                </div>
                                <div className="flex flex-col gap-2 w-full">
                                    <label className="text-lg font-semibold">Start Day</label>
                                    <div className="flex flex-row gap-4 w-full">
                                        <select
                                            value={newCustomDuration.startMonth}
                                            onChange={(e) => setNewCustomDuration({ ...newCustomDuration, startMonth: e.target.value })}
                                            className="border p-2 rounded-lg outline-spotYellow w-full"
                                        >
                                            <option value="">Month</option>
                                            {['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12'].map((month) => (
                                                <option key={month} value={month}>{month}</option>
                                            ))}
                                        </select>

                                        <select
                                            value={newCustomDuration.startDay}
                                            onChange={(e) => setNewCustomDuration({ ...newCustomDuration, startDay: e.target.value })}
                                            className="border p-2 rounded-lg outline-spotYellow w-full"
                                        >
                                            <option value="">Day</option>
                                            {Array.from({ length: 31 }, (_, i) => (i + 1).toString().padStart(2, '0')).map((day) => (
                                                <option key={day} value={day}>{day}</option>
                                            ))}
                                        </select>
                                    </div>
                                </div>
                                <div className="flex flex-col gap-2 w-full">
                                    <label className="text-lg font-semibold">End Day</label>
                                    <div className="flex flex-row gap-4 w-full">
                                        <select
                                            value={newCustomDuration.endMonth}
                                            onChange={(e) => setNewCustomDuration({ ...newCustomDuration, endMonth: e.target.value })}
                                            className="border p-2 rounded-lg outline-spotYellow w-full"
                                        >
                                            <option value="">Month</option>
                                            {['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12'].map((month) => (
                                                <option key={month} value={month}>{month}</option>
                                            ))}
                                        </select>

                                        <select
                                            value={newCustomDuration.endDay}
                                            onChange={(e) => setNewCustomDuration({ ...newCustomDuration, endDay: e.target.value })}
                                            className="border p-2 rounded-lg outline-spotYellow w-full"
                                        >
                                            <option value="">Day</option>
                                            {Array.from({ length: 31 }, (_, i) => (i + 1).toString().padStart(2, '0')).map((day) => (
                                                <option key={day} value={day}>{day}</option>
                                            ))}
                                        </select>
                                    </div>
                                </div>
                                <div className="flex gap-4">
                                    <button
                                        className="border p-2 rounded-lg bg-gray-100"
                                        onClick={closeNewModal}
                                    >
                                        Cancel
                                    </button>
                                    <button
                                        className="border p-2 rounded-lg bg-gray-100 hover:bg-spotYellow"
                                        onClick={saveNewCustomDuration}
                                    >
                                        Save
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                )}
            </div>
        )
    }

    const [newTopLevelDuration, setNewTopLevelDuration] = useState("");
    const [topLevelModal, showTopLevelModal] = useState(false);

    const handleSaveTopLevelDuraton = async () => {
        const newCustomDurations = { ...allCustomDurations, [newTopLevelDuration]: [] };
        saveToDatabase({ ...orgData, customDurations: newCustomDurations });
        setAllCustomDurations(newCustomDurations);
        setNewTopLevelDuration("");
        closeTopLevelModal();
    }

    const closeTopLevelModal = () => {
        showTopLevelModal(false);
        setNewTopLevelDuration("");
    }

    return (
        <div className="h-screen overflow-hidden">
            <h1 className="text-3xl font-bold mt-8">Custom Durations</h1>
            <div className="flex flex-col gap-4 mt-8 ml-24 mr-8">
                {Object.keys(allCustomDurations).length > 0 && Object.keys(allCustomDurations).map((duration, index) => {
                    return (
                        <SingleDuration key={index} duration={duration} />
                    )
                })}

                {topLevelModal && (
                    <div className="absolute flex h-screen w-screen justify-center items-center">
                        <div onClick={closeTopLevelModal} className="fixed top-0 left-0 w-screen h-screen bg-black bg-opacity-50 z-[10001]"></div>
                        <div className="bg-white p-8 rounded-lg shadow-lg z-[10002]">
                            <h2 className="text-2xl font-bold">New Custom Duration Group</h2>
                            <div className="flex flex-col gap-4 mt-4">
                                <div className="flex flex-col gap-2 w-full">
                                    <label className="text-lg font-semibold">Title</label>
                                    <input
                                        type="text"
                                        placeholder="Name"
                                        value={newTopLevelDuration}
                                        onChange={(e) => setNewTopLevelDuration(e.target.value)}
                                        className="border p-2 rounded-lg outline-spotYellow"
                                    />
                                </div>
                                <div className="flex gap-4">
                                    <button
                                        className="border p-2 rounded-lg bg-gray-100"
                                        onClick={closeTopLevelModal}
                                    >
                                        Cancel
                                    </button>
                                    <button
                                        className="border p-2 rounded-lg bg-gray-100 hover:bg-spotYellow"
                                        onClick={handleSaveTopLevelDuraton}
                                    >
                                        Save
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                )}

                {/* ADD BUTTON */}
                <div
                    className="absolute bottom-8 right-8 border w-24 h-12 rounded-lg cursor-pointer fcc hover:shadow-md transition-all duration-100"
                    onClick={(e) => {
                        e.stopPropagation();
                        showTopLevelModal(true);
                    }}
                    aria-label="Add Permit"
                >
                    <GoPlus className="text-6xl m-auto text-gray-400" />
                </div>
            </div>
        </div>
    );
}