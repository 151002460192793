import React, { useState, useEffect } from "react";
import "./Ticket.css";
import TimeParked from "./TimeParked";
import { useNavigate } from "react-router-dom";
import { FaCheckCircle, FaPrint } from "react-icons/fa";
import { FaSquareMinus } from "react-icons/fa6";
import { fetchAllData, postData } from "../../../utility/fetcher";
import MapView from '../../../liveMap/MapView';
import mapboxgl from 'mapbox-gl';

const Ticket = ({ fullName, map, item }) => {
    const [violationData, setViolationData] = useState(item);
    const [currentHistory, setCurrentHistory] = useState([]);

    useEffect(() => {
        setViolationData(item);
    }, [item]);

    // Destructure the data from violationData
    const {
        spotID,
        cameras,
        licensePlate,
        violationType,
        lot,
        spot,
        timeParked,
        coordinates,
        vehicleType,
        permitNumber
    } = violationData;

    const currentOrgData = JSON.parse(localStorage.getItem("orgData"));
    const baseFee = currentOrgData["baseFee"];
    const orgID = currentOrgData.orgID

    // Fee Input
    const [feeInput, setFeeInput] = useState("");

    const handleFeeChange = (e) => {
        setFeeInput(e.target.value);
    };

    const handleFeeBlur = () => {
        const formattedValue = `$${parseFloat(feeInput).toFixed(2)}`;
        setFeeInput(formattedValue);

        if (feeInput === "") {
            setFeeInput(`$${parseFloat(baseFee).toFixed(2)}`);
        }
    };

    // Clear name input on selection
    const handleFeeInputClick = () => {
        setFeeInput("");
    };

    // Name Input
    const [nameInput, setNameInput] = useState("");
    const handleNameInput = (e) => {
        setNameInput(e.target.value);
    };

    // Clear name input on selection
    const handleNameInputClick = () => {
        setNameInput("");
    };

    const handleNameBlur = () => {
        setNameInput(nameInput)

        if (nameInput === "") {
            setNameInput(fullName);
        }
    }

    // When you click enter then blur
    const handleKeyPress = (e) => {
        if (e.key === "Enter") {
            e.target.blur();
        }
    };

    // Darken Background when printing
    const [isDarkened, setIsDarkened] = useState(false);

    // Print Receipt
    const printReceipt = () => {
        // eslint-disable-next-line
        const options = { hour: "numeric", minute: "numeric", hour12: true, timeZone: "UTC" };
        const time = new Date(timeParked).toLocaleTimeString([], options);
        const date = new Date(timeParked).toLocaleDateString();
        const timeParkedFormatted = `${date} ${time}`;
        const type = vehicleType.charAt(0).toUpperCase() + vehicleType.slice(1)
        const receiptContent = `
<pre>
--------------------------------
       PARKING CITATION
   Citation Number: 00000001
--------------------------------
License Plate: ${licensePlate}

Violation: ${violationType}

Lot: ${lot}

Spot: ${spot}

Vehicle Type: ${type}

Time Parked: ${timeParkedFormatted}

Fee: ${feeInput}

Employee: ${nameInput}
--------------------------------
CITATION MUST BE CLEARED WITHIN
14 CALENDAR DAYS OF ISSUE.
VIOLATIONS OF PARKING
REGULATIONS MAY SUBJECT YOUR
VEHICLE TO BEING BOOTED OR TOWED
AT OWNER'S EXPENSE.
--------------------------------
<pre>
        `;

        const printWindow = window.open("", "_blank");
        if (printWindow) {
            printWindow.document.write(receiptContent);
            printWindow.document.close();
            printWindow.print();
        } else {
            console.error("Failed to open print window");
        }
    };

    // Handle Print
    const handlePrint = () => {
        setIsDarkened(true);
        setTimeout(() => {
            printReceipt();
            setIsDarkened(false);
        }, 1000);
    };
    // };

    const saveToHistory = async (type, deleteReason) => {
        const historyResponse = await fetchAllData({ "plateHistory": `/${orgID}/history?licensePlate=${licensePlate}` });
        const historyArray = historyResponse.plateHistory.history

        let newFeeInput = "";
        if (feeInput.includes("$")) {
            newFeeInput = feeInput.substring(1);
        }

        historyArray.push({
            "employee": nameInput,
            "lot": lot,
            "permitType": "",
            "spot": spot,
            "stolen": "false",
            "ticketWritten": type === "add" ? "true" : "false",
            "ticketWrittenTime": type === "add" ? new Date().toISOString() : "",
            "timeIn": timeParked,
            "timeOut": "",
            "violationType": type === "add" ? violationType : "",
            "fee": type === "add" ? newFeeInput : "",
            "deleteReason": type === "add" ? "" : deleteReason
        });

        const historyBuild = {
            "licensePlate": licensePlate,
            "history": historyArray
        }

        try {
            console.log("HISTORY Data to save:", historyBuild); //TODO: change this to historyData
            const response = await postData(`/${orgID}/history`, historyBuild);
            console.log(response.message);
        } catch (error) {
            console.error("Error saving data:", error);
        }
    };

    const handleCompleteClick = async () => {
        const saveToSpots = async () => {
            const jsonData = {
                "spotID": spotID,
                "cameras": cameras,
                "licensePlate": licensePlate,
                "coordinates": coordinates,
                "permitNumber": permitNumber,
                "lot": lot,
                "spot": spot,
                "ticketWritten": true,
                "timeParked": timeParked,
                "vehicleType": vehicleType,
                "violationType": violationType
            }

            console.log("SPOTS Data to save:", jsonData);
            const response = await postData(`/${orgID}/spots`, jsonData);
            console.log(response.message);
        }

        (async () => {
            await saveToSpots();
            await saveToHistory("add");
        })();

        // Once clicked, modify the ticketWritten value in the violationData
        var violationData = JSON.parse(sessionStorage.getItem("violationData"));
        var foundItem = violationData.find(item => item.licensePlate === licensePlate);
        if (foundItem) {
            violationData = violationData.filter(item => item.licensePlate !== licensePlate);
            foundItem.TicketWritten = true;
            violationData.push(foundItem);
            sessionStorage.setItem("violationData", JSON.stringify(violationData));
        }
    };

    const getHistory = async () => {
        const path = { "violationHistory": `/${orgID}/history?licensePlate=${licensePlate}` }
        const response = await fetchAllData(path);

        if (response.violationHistory.error) {
            setCurrentHistory([]);
        } else {
            const historyData = response.violationHistory.history.sort((a, b) => new Date(b.timeIn) - new Date(a.timeIn));
            const mostRecentViolationVisits = historyData.filter(item => item.violationType !== "").slice(0, 3);
            const visitsToFirstViolation = historyData.indexOf(mostRecentViolationVisits[0]);
            const visitsToSecondViolation = historyData.indexOf(mostRecentViolationVisits[1]) === -1 ? historyData.length - visitsToFirstViolation - 1 : historyData.indexOf(mostRecentViolationVisits[1]) - visitsToFirstViolation;
            const visitsToThirdViolation = historyData.indexOf(mostRecentViolationVisits[2]) - visitsToSecondViolation - visitsToFirstViolation;

            console.log(visitsToFirstViolation, visitsToSecondViolation, visitsToThirdViolation);

            setCurrentHistory({
                "firstViolation": mostRecentViolationVisits[0],
                "secondViolation": mostRecentViolationVisits[1],
                "thirdViolation": mostRecentViolationVisits[2],
                "visitsToFirstViolation": visitsToFirstViolation,
                "visitsToSecondViolation": visitsToSecondViolation,
                "visitsToThirdViolation": visitsToThirdViolation,
                "fullHistory": historyData
            });
        }
    }

    // Checkbox navigation handling
    const ticketitemStyle = "flex rounded-xl m-2 p-1 border shadow-sm"
    const [deleteText, setDeleteText] = useState("Delete");
    const [deleteOpacity, setDeleteOpacity] = useState(1);
    const [showOverlay, setShowOverlay] = useState(false);
    const [deleteReason, setDeleteReason] = useState("");
    const [deleteWarning, setDeleteWarning] = useState("");
    const navigate = useNavigate();

    // useEffect(() => {
    //     getHistory();
    //     // eslint-disable-next-line
    // }, [violationData]);

    // const schoolName = sessionStorage.getItem("currentUniversity");
    const plateHistory = currentHistory

    // Turns the coordinates into a usable format aka string to array
    var string1 = violationData.coordinates.slice(2) // Removes the first two characters
    const cord1 = string1.split(',')[0]; // Splits the string at the comma and takes the first half
    const cord2 = string1.split(',')[1].slice(0, -1); // Same then removes the last character

    // Map data
    const startSpot = new mapboxgl.LngLat(cord1, cord2);
    const zoom = 20;
    const itemClickable = false;
    const buttons = false
    const styling = {
        display: "flex",
        touchAction: "none",
        width: "100%",
        height: "450px",
        borderRadius: "10px",
        marginTop: "0px",
        border: "1px solid #EBEDEF",
        boxShadow: "0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -2px rgba(0, 0, 0, 0.1)", // Tailwind shadow equivalent
    };

    const handleCheck = () => {
        // Once clicked, modify the ticketWritten value in the violationData
        var violationData = JSON.parse(sessionStorage.getItem("violationData"));
        var foundItem = violationData.find(item => item.licensePlate === licensePlate);
        if (foundItem) {
            violationData = violationData.filter(item => item.licensePlate !== licensePlate);
            foundItem.TicketWritten = true;
            violationData.push(foundItem);
            sessionStorage.setItem("violationData", JSON.stringify(violationData));
        }

        setTimeout(() => {
            navigate(-1);
        }, 1000);
    };

    function deleteViolation(deleteReason) {
        try {
            const saveToSpots = async () => {
                // JSON Build
                const newJsonData = {
                    "licensePlate": licensePlate,
                    "coordinates": coordinates,
                    "permitNumber": permitNumber,
                    "lot": lot,
                    "spot": spot,
                    "ticketWritten": false,
                    "timeParked": timeParked,
                    "vehicleType": vehicleType,
                    "violationType": "N/A"
                }

                // Perform POST request here
                console.log("Organization ID:", orgID);
                const path = `/${orgID}/spots`;
                const response = await postData(path, newJsonData);
                console.log(response.message);
            }

            (async () => {
                await saveToSpots();
                await saveToHistory("delete", deleteReason);
            })();
        } catch (error) {
            console.error("Error saving data:", error);
        }

        // Once clicked, modify the ticketWritten value in the violationData
        var violationData = JSON.parse(sessionStorage.getItem("violationData"));
        var foundItem = violationData.find(item => item.licensePlate === licensePlate);
        if (foundItem) {
            violationData = violationData.filter(item => item.licensePlate !== licensePlate);
            foundItem.TicketWritten = true;
            violationData.push(foundItem);
            sessionStorage.setItem("violationData", JSON.stringify(violationData));
        }
    }

    const handleDelete = async () => {
        setDeleteOpacity(0.5);
        setDeleteText("Deleting...");
        setShowOverlay(true);
    };

    const handleReasonChange = (event) => {
        setDeleteReason(event.target.value);
    };

    const handleDeleteConfirm = async () => {
        if (deleteReason === "") {
            setDeleteWarning("Please select a reason.");
            return;
        }

        // Save data to history
        setShowOverlay(false);
        deleteViolation(deleteReason);
        window.history.back();
    };

    const handleDeleteCancel = () => {
        setShowOverlay(false);
        setDeleteOpacity(1);
        setDeleteText("Delete");
    };

    const formatDate = (input) => {
        const date = new Date(input);
        let month = String(date.getMonth() + 1).padStart(2, '0'); // Months are 0-based, so add 1
        let day = String(date.getDate()).padStart(2, '0');
        let year = String(date.getFullYear()).slice(-2);
        return `${month}-${day}-${year}`;
    };

    const formatFee = (fee) => {
        return `$${parseFloat(fee).toFixed(2)}`;
    };

    const generatePlateHistoryTable = () => {
        return (
            <div className='z-20 w-full fcc bg-white/50 backdrop-blur-md shadow-md border rounded-2xl p-2'>
                <h1 className='text-2xl font-bold text-center my-2'>Plate History</h1>
                <p className="w-full text-center font-bold text-gray-500">Current Day</p>
                <div className={`bg-gray-400 w-[2px] ${currentHistory.visitsToFirstViolation > 10 ? "h-6" : "h-3"}`}></div>
                <p className="w-full text-center text-gray-500 text-sm">{currentHistory.visitsToFirstViolation} clean visits</p>
                {currentHistory.firstViolation &&
                    <div className="fcc">
                        <div className={`bg-gray-400 w-[2px] ${currentHistory.visitsToFirstViolation > 10 ? "h-6" : "h-3"}`}></div>
                        <div className="w-full frc gap-1 text-center text-gray-500">
                            <p className="font-bold">{currentHistory.firstViolation.violationType}</p>
                            <p>violation on {formatDate(currentHistory.firstViolation.timeIn)}</p>
                            <p>({formatFee(currentHistory.firstViolation.fee)})</p>
                        </div>
                        <div className={`bg-gray-400 w-[2px] ${currentHistory.visitsToSecondViolation > 10 ? "h-6" : "h-3"}`}></div>
                    </div>
                }
                <p className="w-full text-center text-gray-500 text-sm">{currentHistory.visitsToSecondViolation} clean visits</p>
                {currentHistory.secondViolation &&
                    <div className="fcc">
                        <div className={`bg-gray-400 w-[2px] ${currentHistory.visitsToSecondViolation > 10 ? "h-6" : "h-3"}`}></div>
                        <div className="w-full frc gap-1 text-center text-gray-500">
                            <p className="font-bold">{currentHistory.secondViolation.violationType}</p>
                            <p>violation on {formatDate(currentHistory.secondViolation.timeIn)}</p>
                            <p>({formatFee(currentHistory.secondViolation.fee)})</p>
                        </div>
                    </div>
                }
                {currentHistory.thirdViolation &&
                    <div className="fcc">
                        <div className={`bg-gray-400 w-[2px] ${currentHistory.visitsToThirdViolation > 10 ? "h-6" : "h-3"}`}></div>
                        <p className="w-full text-center text-gray-500 text-sm">{currentHistory.visitsToThirdViolation} clean visits</p>
                        <div className={`bg-gray-400 w-[2px] ${currentHistory.visitsToThirdViolation > 10 ? "h-6" : "h-3"}`}></div>
                        <div className="w-full frc gap-1 text-center text-gray-500">
                            <p className="font-bold">{currentHistory.thirdViolation.violationType}</p>
                            <p>violation on {formatDate(currentHistory.thirdViolation.timeIn)}</p>
                            <p>({formatFee(currentHistory.thirdViolation.fee)})</p>
                        </div>
                    </div>
                }

            </div >
        );
    };

    // No data available result
    if (!violationData) {
        return <div className="flex justify-center text-2xl my-auto w-[502px] font-bold mx-auto mr-4 p-4 h-fit">Select a violation to view ticket.</div>;
    }

    return (
        <div className="w-full flex flex-col gap-4 mb-8 font-rubik max-w-md">
            <div className="flex justify-center w-full items-center flex-col bg-white/50 backdrop-blur-md border shadow-md rounded-3xl max-w-2xl">
                <h2 className="flex justify-center items-center text-2xl font-bold pb-0 mt-2">Ticket Information</h2>
                <div className="flex flex-col w-full p-2">
                    <div className="flex flex-row w-full">
                        <div className="flex flex-col w-full rounded-xl m-2 p-1 border shadow-sm">
                            <p className="font-bold ml-1">Plate #:</p>
                            <p className="ml-1">{licensePlate}</p>
                        </div>
                        <div className="flex flex-col w-full rounded-xl m-2 p-1 border shadow-sm">
                            <p className="font-bold ml-1">Parking Violation:</p>
                            <p className="ml-1">{violationType}</p>
                        </div>
                    </div>
                    <div className="flex flex-row">
                        <div className="flex flex-col w-full rounded-xl m-2 p-1 border shadow-sm">
                            <p className="font-bold ml-1">Spot Number:</p>
                            <p className="ml-1">{lot}-{spot}</p>
                        </div>
                        <div className="flex flex-col w-full rounded-xl m-2 p-1 border shadow-sm">
                            <p className="font-bold ml-1">Time Parked:</p>
                            <p className="ml-1"><TimeParked dateTime={timeParked} /></p>
                        </div>
                    </div>
                    <div className="flex flex-row">
                        <div className="flex flex-col w-full rounded-xl m-2 p-1 pr-3 border shadow-sm">
                            <p className="font-bold ml-1">Fee:</p>
                            <input
                                type="text"
                                alt="Fee Input"
                                value={feeInput}
                                placeholder={`$${parseFloat(baseFee).toFixed(2)}`}
                                onChange={handleFeeChange}
                                onClick={handleFeeInputClick}
                                onBlur={handleFeeBlur}
                                onKeyDown={handleKeyPress}
                                className="border border-gray-500 outline-spotYellow rounded-md h-8 pl-2 mx-1 my-1 w-full"
                            />
                        </div>
                        <div className="flex flex-col w-full rounded-xl m-2 p-1 pr-3 border shadow-sm">
                            <p className="font-bold ml-1">Employee Name:</p>
                            <input
                                type="text"
                                alt="Name Input"
                                value={nameInput}
                                placeholder={fullName}
                                onChange={handleNameInput}
                                onClick={handleNameInputClick}
                                onBlur={handleNameBlur}
                                onKeyDown={handleKeyPress}
                                className="border border-gray-500 outline-spotYellow rounded-md h-8 pl-2 mx-1 my-1 w-full"
                            />
                        </div>
                    </div>
                </div>
            </div>
            {/* PLATE HISTORY */}
            <div className="fcc w-full">
                {plateHistory.length === 0 ? null : generatePlateHistoryTable()}
            </div>
            {/* MAP */}
            {map === undefined ? (
                <div className="flex justify-center items-center flex-col shadow-2xl rounded-3xl max-w-2xl z-20">
                    <MapView //TODO: This doesn't work right. It doesn't show the selected polygon.
                        startSpot={startSpot}
                        zoom={zoom}
                        styling={styling}
                        buttons={buttons}
                        itemClickable={itemClickable}
                        highlight={violationData.spotID}
                        refresh={false}
                        lot={violationData.lot}
                    />
                </div>
            ) : null}
            <div className="flex items-center flex-row gap-2 z-50 w-full justify-between">
                <div
                    className="yellow-gradient flex grow p-2 justify-center rounded-lg shadow-xl hover:light-gray-gradient group transition-all duration-200 ease-in-out cursor-pointer"
                    onClick={() => { handleCompleteClick() }}>
                    <div className="button-text-container">
                        <FaCheckCircle aria-label="Mark Completed" size={30} className="mr-2 text-spotGray transition-all duration-200" onClick={handleCheck} />
                        Mark Completed
                    </div>
                </div>
                <div
                    className="yellow-gradient flex grow p-2 justify-center rounded-lg shadow-xl hover:light-gray-gradient group transition-all duration-200 ease-in-out cursor-pointer"
                    style={{ opacity: deleteOpacity }}
                    onClick={handleDelete}>
                    <div className="button-text-container">
                        <FaSquareMinus aria-label="Delete" size={30} className="mr-2 text-spotGray transition-all duration-200" />
                        {deleteText}
                    </div>
                </div>
                <div
                    className="yellow-gradient flex grow p-2 justify-center rounded-lg shadow-xl hover:light-gray-gradient group transition-all duration-200 ease-in-out cursor-pointer"
                    onClick={handlePrint}
                    disabled={isDarkened}>
                    <div className="button-text-container">
                        <FaPrint aria-label="Print" size={30} className="mr-2 text-spotGray transition-all duration-200" />
                        Print
                    </div>
                </div>
            </div>
            {showOverlay && (
                <div className="fixed top-0 right-0 bottom-0 left-0 bg-black/50 justify-center items-center flex z-[1000]">
                    <div className="bg-white w-fit p-4 h-fit rounded-xl shadow-md text-center fcc gap-2 font-rubik">
                        <h3 className="text-lg">Select Reason for Deleting</h3>
                        <select aria-label="Reason for Deleting" value={deleteReason} className="pl-2" onChange={handleReasonChange}>
                            <option value="">Select Reason</option>
                            <option value="Duplicate">Duplicate</option>
                            <option value="Wrong Plate">Wrong Plate</option>
                            <option value="No Car">No Car</option>
                            <option value="Other">Other</option>
                        </select>
                        {deleteWarning === "" ? <br /> : <p style={{ color: "red" }}>{deleteWarning}</p>}
                        <div className="frc gap-2">
                            <button aria-label="Cancel" className="bg-gray-200 rounded-lg shadow-md hover:bg-gray-300 w-fit px-2 py-1" onClick={handleDeleteCancel}>Cancel</button>
                            <button aria-label="Confirm" className="bg-red-200 rounded-lg shadow-md hover:bg-red-300 w-fit px-2 py-1" onClick={handleDeleteConfirm}>Confirm</button>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default Ticket;
