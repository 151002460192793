import React, { useState } from "react";
import ListItem from "./listItem";
import Ticket from "./ticket/Ticket";
import { IoSparklesSharp } from "react-icons/io5";
import { HiMiniSparkles } from "react-icons/hi2";
import { IoIosInformationCircle } from "react-icons/io";
import { FaFileCircleXmark, FaFaceMeh, FaHourglassEnd } from 'react-icons/fa6';
import { MdWrongLocation } from "react-icons/md";

export const CurrentViolations = () => {
    // const navigate = useNavigate();
    const [filterOption, setFilterOption] = useState("timeParkedAscending");
    const [searchTerm, setSearchTerm] = useState("");
    const [modalOpen, setModalOpen] = useState(false);
    const listData = JSON.parse(sessionStorage.getItem("violationData"));

    if (listData.length === 0) {
        window.location.href = "/dashboard";
    }

    // Filter out the data that is not a violation or has already been ticketed
    const validListData = listData.filter(
        (item) => (item.licensePlate !== "" && item.violationType !== "")
    );

    // Handle item click
    const [activeViolation, setActiveViolation] = useState(null);
    const handleItemClick = (item) => {
        sessionStorage.setItem('itemData', JSON.stringify(item));
        setActiveViolation(item);
    };

    // Handle filter change
    const handleFilterChange = (event) => {
        setFilterOption(event.target.value);
    };

    // Apply filter
    const applySorter = (data) => {
        switch (filterOption) {
            case "timeParkedAscending":
                return data.sort(
                    (a, b) => new Date(a.timeParked) - new Date(b.timeParked)
                );
            case "timeParkedDescending":
                return data.sort(
                    (a, b) => new Date(b.timeParked) - new Date(a.timeParked)
                );
            case "violationType":
                return data.sort(
                    (a, b) => a.violationType.localeCompare(b.violationType)
                );
            case "lot":
                return data.sort(
                    (a, b) => a.lot.localeCompare(b.lot, undefined, { numeric: true })
                );
            default:
                return data;
        }
    };

    const sortedListData = applySorter(validListData);

    // Update search term
    const handleSearch = (event) => {
        setSearchTerm(event.target.value);
    };

    // Filter data based on search term
    // const filteredListData = sortedListData.filter((item) =>
    //     item.licensePlate.toLowerCase().includes(searchTerm.toLowerCase()) && item.licensePlate !== "#UNKN#"
    // );

    const filteredListData = sortedListData

    sessionStorage.setItem("filteredViolations", JSON.stringify(filteredListData));

    return (
        <div className="h-screen overflow-hidden font-rubik">
            <div className="flex flex-row justify-between">
                <div className="flex flex-col grow h-screen overflow-scroll no-scrollbar">
                    <div className="flex flex-row items-center justify-between mr-4">
                        <h1 className="flex items-center text-xl font-bold justify-center ml-24 mt-4">Violations: {sortedListData.length}</h1>
                        <div className="frc gap-4">
                            <div className="relative">
                                <button
                                    aria-label="Speed Cite"
                                    className="text-spotGray bg-spotGray flex justify-center items-center transition-all duration-150 rounded-full p-3 mt-4 group"
                                    onClick={() => setModalOpen(true)}
                                >
                                    <IoIosInformationCircle size={40} className="absolute group-hover:text-gray-400 text-xl  text-gray-300 transition-all duration-150" />
                                </button>
                            </div>
                            <div className="relative">
                                <button
                                    aria-label="Speed Cite"
                                    className="bg-gray-300 text-spotGray hover:bg-spotYellow transition-all duration-150 rounded-md px-4 py-2 mt-4 group"
                                    onClick={() => window.location.href = "/analytics/speed-cite"}
                                >
                                    Speed Cite
                                    <HiMiniSparkles className="absolute opacity-0 group-hover:opacity-100 top-2 -left-1 text-xl  text-spotGray transition-all duration-150" />
                                    <IoSparklesSharp className="absolute opacity-0 group-hover:opacity-100 bottom-1 -right-2 text-lg text-spotGray transition-all duration-150 delay-[35ms]" />
                                </button>
                            </div>
                            <select
                                value={filterOption}
                                onChange={handleFilterChange}
                                className="pl-2 w-auto mt-4 bg-white z-50 border rounded-md border-gray-300 outline-spotYellow h-8"
                                aria-label="Filter"
                            >
                                <option value="timeParkedAscending">Time Parked (Ascending)</option>
                                <option value="timeParkedDescending">Time Parked (Descending)</option>
                                <option value="violationType">Violation Type</option>
                                <option value="lot">Lot</option>
                            </select>
                            <input
                                type="text"
                                placeholder="Search License Plate"
                                className="rounded-md border h-8 border-gray-300 pl-2 mt-4 outline-spotYellow"
                                value={searchTerm}
                                onChange={handleSearch}
                                alt="Search"
                            />
                        </div>
                    </div>
                    <div>
                        {filteredListData.map((item, index) => (
                            <div key={index} onClick={() => handleItemClick(item)}>
                                <ListItem
                                    licensePlate={item.licensePlate}
                                    violationType={item.violationType}
                                    lot={item.lot}
                                    spot={item.spot}
                                    timeParked={item.timeParked}
                                    coordinates={item.coordinates}
                                    distance={item.distance}
                                    vehicleType={item.vehicleType}
                                    permitNumber={item.permitNumber}
                                />
                            </div>
                        ))}
                        {filteredListData.length === 0 && (
                            <h1 className="text-2xl font-bold text-spotGray mt-20">No violations to cite.</h1>
                        )}
                    </div>
                </div>
                {activeViolation === null ? null :
                    <div className="flex flex-col justify-end pr-10">
                        <Ticket item={activeViolation} fullName={"Admin"} />
                    </div>
                }
            </div>

            {modalOpen && (
                <div className="fixed inset-0 bg-black bg-opacity-50 z-50 flex justify-center items-center">
                    <div className="bg-white border w-96 rounded-lg p-4 relative">
                        <h1 className="text-2xl font-bold">Current Violations</h1>
                        <p className="text-lg mt-4 text-center">View, sort, and filter pending violations. Select to view more.</p>
                        <div className="flex justify-center items-center mb-8">
                            <div className="flex flex-col justify-start items-start mt-4 gap-4">
                                <div className="flex flex-row items-center gap-2">
                                    <FaHourglassEnd size={24} />
                                    <span>Expired Meter</span>
                                </div>
                                <div className="flex flex-row items-center gap-2">
                                    <FaFileCircleXmark size={24} />
                                    <span>No Permit</span>
                                </div>
                                <div className="flex flex-row items-center gap-2">
                                    <MdWrongLocation size={24} />
                                    <span>Wrong Lot</span>
                                </div>
                                <div className="flex flex-row items-center gap-2">
                                    <FaFaceMeh size={24} />
                                    <span>Other</span>
                                </div>
                            </div>
                        </div>
                        <button
                            className="bg-spotYellow absolute bottom-2 right-2 text-spotGray rounded-md px-4 py-2 mt-4"
                            onClick={() => setModalOpen(false)}
                        >
                            Close
                        </button>
                    </div>
                </div>
            )}
        </div>
    );
}