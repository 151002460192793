import React, { useState, useEffect } from "react";
import { motion } from "framer-motion";
import { postData } from "../utility/fetcher";
import { Toaster, toast } from "sonner";
import { fetchAllData } from "../utility/fetcher";
import { SpotEditor } from "./SpotEditor"
import { HiVideoCamera } from "react-icons/hi2";
import { Link } from "react-router-dom";
import lineYellow from "../assets/svgs/lineYellow.svg";
import blackCircle from "../assets/svgs/blackCircle.svg";
import lot from "../assets/lot.jpg";
import "./settings.css";

const AutoButton = ({ lot, index, autoStatusData, setAutoStatusData, handleEdit }) => {
    const status = autoStatusData[lot["lotNum"]];
    const [move, setMove] = useState(status);

    const handleButton = () => {
        setAutoStatusData({
            ...autoStatusData,
            [lot["lotNum"]]: !move
        });

        handleEdit(index, "autoStatus", !move);
        setMove(!move);
    }

    return (
        <div className="autobutton-container" style={{
            backgroundColor: status === true ? "#FFE374" : "#323232",
        }}>
            <motion.div
                id="switch"
                className={`flex w-8 h-6 rounded-md shadow-md cursor-pointer transition-all duration-200 ease-in-out ${move ? "ml-9 hover:ml-7 bg-[#323232]" : "-ml-8 hover:-ml-6 bg-[#FFE374]"}`}
                onClick={handleButton}
                aria-label="Auto Status"
            >
                <img
                    src={status === true ? lineYellow : blackCircle}
                    className="status-icon"
                    alt="status"
                    style={{
                        marginLeft: "8px",
                        marginRight: status === true ? "0px" : "50px",
                    }}
                ></img>
            </motion.div>
        </div>
    )
}

const OnOffButton = ({ lot, index, onOffStatusData, setOnOffStatusData, handleEdit }) => {
    const status = onOffStatusData[lot["lotNum"]];
    const [move, setMove] = useState(status);

    const handleButton = () => {
        setOnOffStatusData({
            ...onOffStatusData,
            [lot["lotNum"]]: !move
        });

        handleEdit(index, "onOff", !move);
        setMove(!move);
    }

    return (
        <div className="autobutton-container" style={{
            backgroundColor: status === true ? "#FFE374" : "#323232",
        }}>
            <motion.div
                id="switch"
                className={`flex w-8 h-6 rounded-md shadow-md cursor-pointer transition-all duration-200 ease-in-out ${move ? "ml-9 hover:ml-7 bg-[#323232]" : "-ml-8 hover:-ml-6 bg-[#FFE374]"}`}
                onClick={handleButton}
                aria-label="On/Off Status"
            >
                <img
                    src={status === true ? lineYellow : blackCircle}
                    className="status-icon"
                    alt="status"
                    style={{
                        marginLeft: "8px",
                        marginRight: status === true ? "0px" : "50px",
                    }}
                ></img>
            </motion.div>
        </div>
    )
}

export const Lots = () => {
    const orgData = JSON.parse(localStorage.getItem("orgData"));
    const lotData = orgData["lots"].sort((a, b) => a.lotNum - b.lotNum)
    const violationData = JSON.parse(sessionStorage.getItem("violationData"));
    const [tableData, setTableData] = useState(lotData);
    const [saveMessage, setSaveMessage] = useState("Save");
    const [checkedIndexes, setCheckedIndexes] = useState([]);
    const [loading, setLoading] = useState(true);
    const [editorOpen, setEditorOpen] = useState(false);

    const handleEdit = (index, field, value) => {
        const updatedTableData = [...tableData];
        updatedTableData[index][field] = value;
        setTableData(updatedTableData);
    };

    const handleCheck = (index) => {
        const updatedIndexes = [...checkedIndexes];
        if (updatedIndexes.includes(index)) {
            updatedIndexes.splice(updatedIndexes.indexOf(index), 1);
        } else {
            updatedIndexes.push(index);
        }
        setCheckedIndexes(updatedIndexes);
    };

    const saveButton = () => {
        const handleSaveClick = async () => {
            setSaveMessage("Saving...");
            const newData = orgData;
            newData["lots"] = tableData;
            const loadingID = toast.loading("Saving data...");

            try {
                const path = "/general/organizations";
                const response = await postData(path, newData);
                console.log(response.message)
                toast.success("Data saved successfully", { id: loadingID });
                setSaveMessage("Saved");
                setTimeout(() => {
                    setSaveMessage("Save");
                }, 2000);
            } catch (error) {
                toast.error("Data failed to save. Please try again.", { id: loadingID });
                console.error("Error saving data:", error);
            }
        };

        return (
            <div>
                <button
                    className="fcc w-[150px] h-8 rounded-lg shadow-md fcc yellow-gradient"
                    onClick={handleSaveClick}
                    aria-label="Save"
                >
                    {saveMessage}
                </button>
            </div>
        );
    };

    var autoStatuses = {}
    function getAutoStatuses(lots) {
        for (let i in lots) {
            autoStatuses[lots[i]["lotNum"]] = lots[i]["autoStatus"];
        }
        return autoStatuses;
    }

    var onOffStatuses = {}
    function getOnOffStatuses(lots) {
        for (let i in lots) {
            onOffStatuses[lots[i]["lotNum"]] = lots[i]["onOff"];
        }
        return onOffStatuses;
    }

    const [autoStatusData, setAutoStatusData] = useState(getAutoStatuses(lotData));
    const [onOffStatusData, setOnOffStatusData] = useState(getOnOffStatuses(lotData));

    const toggleEditor = (type) => {
        setEditorOpen(!editorOpen);
        if (type === "close") {
            setTimeout(() => {
                toast.success("Saved successfully.");
            }, 1000);
        }
    }

    const storeData = async () => {
        try {
            const orgID = orgData.orgID;
            const pathObj = { "schoolData": `/general/organizations?orgID=${orgID}` };
            const response = await fetchAllData(pathObj);
            const lots = response.schoolData.lots;
            setTableData(lots);
            sessionStorage.setItem("orgData", JSON.stringify(response.schoolData));
            setLoading(false);
        } catch (error) {
            console.error("Error getting data:", error);
            toast.error("Data failed to save. Please try again.");
        }
    }

    useEffect(() => {
        storeData();
        // eslint-disable-next-line
    }, []);

    const capacities = {}
    for (let i in violationData) {
        const lotNum = violationData[i].lot;
        if (capacities[lotNum] === undefined) {
            capacities[lotNum] = 1;
        } else {
            capacities[lotNum]++;
        }
    }

    let anyCameras = false
    for (let i in tableData) {
        if (tableData[i].cameras) {
            anyCameras = true
            break
        }
    }

    const tableCellStyle = "p-2 text-center w-auto"

    return (
        <div className={`w-screen flex flex-col justify-center pl-20 font-rubik ${editorOpen && "overflow-clip"}`}>
            <Toaster richColors />
            <h1 className="text-3xl font-bold mt-8 w-full">Lots</h1>
            {loading ? <h1 className="mt-20 w-full text-center">Loading...</h1> :
                <div className="fcc m-0 w-full">
                    <div className="flex flex-col justify-start w-full overflow-auto pb-4 px-8">
                        <table className="table-auto mx-0">
                            <thead>
                                <tr className="table-auto">
                                    <th className="">Select</th>
                                    <th className="">Lot No.</th>
                                    <th className="min-w-48">Lot Name</th>
                                    <th className="">Capacity</th>
                                    <th className="">Active Days</th>
                                    <th className="">Start Time</th>
                                    <th className="">End Time</th>
                                    <th className="w-36">Grace Period</th>
                                    {anyCameras && <th className="">Cameras</th>}
                                    <th className="">Auto Status</th>
                                    <th className="pl-4">Active</th>
                                </tr>
                            </thead>
                            <tbody className="my-4">
                                {tableData.map((row, index) => (
                                    <tr key={index} className="p-2 border-b border-gray-300">
                                        <td className="p-2 text-center w-8">
                                            <input
                                                type="checkbox"
                                                alt="Select"
                                                className="select-check"
                                                onChange={(e) => handleCheck(index)}
                                            />
                                        </td>
                                        <td className="p-2 text-center">
                                            <input
                                                type="text"
                                                alt="Lot Number"
                                                value={row.lotNum}
                                                className="text-center w-12 outline-spotYellow border-2 rounded-md py-1"
                                                onChange={(e) => handleEdit(index, "lotNum", e.target.value)}
                                            />
                                        </td>
                                        <td className="p-2 text-center w-52">
                                            <input
                                                type="text"
                                                alt="Lot Name"
                                                value={row.lotName}
                                                className="table-cell-input outline-spotYellow border-2 rounded-md py-1 pl-2"
                                                onChange={(e) => handleEdit(index, "lotName", e.target.value)}
                                            />
                                        </td>
                                        <td className={tableCellStyle}>
                                            {capacities[row.lotNum]}
                                        </td>
                                        <td className="p-2 text-center w-52">
                                            <input
                                                type="text"
                                                alt="Active Days"
                                                value={row.activeDays}
                                                className="outline-spotYellow border-2 rounded-md py-1 pl-2"
                                                onChange={(e) => handleEdit(index, "activeDays", e.target.value)}
                                            />
                                        </td>
                                        <td className="p-2 text-center w-52">
                                            <input
                                                type="time"
                                                alt="Start Time"
                                                value={row.startTime}
                                                className="outline-spotYellow border-2 rounded-md py-1 pl-2"
                                                onChange={(e) => handleEdit(index, "startTime", e.target.value)}
                                            />
                                        </td>
                                        <td className="p-2 text-center w-52">
                                            <input
                                                type="time"
                                                alt="End Time"
                                                value={row.endTime}
                                                className="outline-spotYellow border-2 rounded-md py-1 pl-2"
                                                onChange={(e) => handleEdit(index, "endTime", e.target.value)}
                                            />
                                        </td>
                                        <td className="p-2 text-center w-24">
                                            <input
                                                type="text"
                                                alt="Grace Period"
                                                value={row.gracePeriod}
                                                className="text-center w-20 outline-spotYellow border-2 rounded-md py-1"
                                                onChange={(e) => handleEdit(index, "gracePeriod", e.target.value)}
                                            />
                                        </td>
                                        {anyCameras && (
                                            <td className="p-2 text-center">
                                                {row.cameras && (
                                                    <Link
                                                        to="/settings/lots/cameras"
                                                        className="fcc"
                                                        onClick={() => sessionStorage.setItem("activeLot", JSON.stringify(row))}
                                                        aria-label="Cameras"
                                                    >
                                                        <HiVideoCamera size={32} className="cursor-pointer w-12 text-spotGray hover:scale-110 transition-all duration-100 ease-in-out" />
                                                    </Link>
                                                )}
                                            </td>
                                        )}
                                        <td className="mt-2 pl-4">
                                            <AutoButton
                                                lot={row}
                                                index={index}
                                                autoStatusData={autoStatusData}
                                                setAutoStatusData={setAutoStatusData}
                                                handleEdit={handleEdit}
                                            />
                                        </td>
                                        <td className="mt-2 pl-4">
                                            <OnOffButton
                                                lot={row}
                                                index={index}
                                                onOffStatusData={onOffStatusData}
                                                setOnOffStatusData={setOnOffStatusData}
                                                handleEdit={handleEdit}
                                            />
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                    {editorOpen ? (
                        <div className="absolute fcc w-screen h-screen top-0 bottom-0 left-0 right-0 z-[1000] bg-black/40">
                            <SpotEditor
                                image={lot}
                                spotData={spotData}
                                availableSpotTypes={availableSpotTypes}
                                closeEditor={toggleEditor}
                            />
                        </div>
                    ) : null}
                </div>
            }
            {loading ? null :
                <div className="flex flex-row justify-end mt-6 font-bold w-full px-8">
                    {saveButton()}
                </div>
            }
        </div >
    );
};

const spotData = [
    {
        spotID: "001-0001",
        type: "Permit",
        boundingBox: [
            [290, 370],
            [350, 390],
            [450, 340],
            [390, 325]
        ],
    },
    {
        spotID: "001-0002",
        type: "Permit",
        boundingBox: [
            [235, 350],
            [295, 370],
            [385, 325],
            [340, 305]
        ],
    },
    {
        spotID: "001-0003",
        type: "Permit",
        boundingBox: [
            [185, 325],
            [232, 350],
            [340, 310],
            [290, 290]
        ],
    }
];

const availableSpotTypes = ["Permit", "ADA", "Visitor", "Reserved"];