import React from "react";
import { Link } from "react-router-dom";
import { FaArrowLeft } from "react-icons/fa6";

export const BackArrow = ({ path }) => {
    return (
        <Link
            aria-label="Go back"
            to={path}
            className="frc gap-1 text-2xl text-spotGray dark:text-gray-300 z-50 fixed top-0 left-20 pl-6 pt-6">
            <FaArrowLeft />
        </Link>
    );
}