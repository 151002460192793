import React, { useState } from "react";
import { Toaster, toast } from "sonner";
import { postData } from "../utility/fetcher";

export const Appeals = () => {
    const [selectedAppeal, setSelectedAppeal] = useState(null);
    const [action, setAction] = useState(null);
    const [reason, setReason] = useState("");
    const [submitButton, setSubmitButton] = useState("Submit");
    const users = JSON.parse(sessionStorage.getItem("appealingUsers"));
    var appealedCitations = JSON.parse(sessionStorage.getItem("appealedCitations"));
    const [currentAppeals, setCurrentAppeals] = useState(appealedCitations);

    const handleAppealClick = (appeal) => {
        setSelectedAppeal(appeal);
        setAction(null);
        setReason("");
    };

    const handleActionClick = (actionType) => {
        setAction(actionType);
    };

    const handleClose = () => {
        setSelectedAppeal(null);
        setAction(null);
        setReason("");
    };

    const saveToAppealDatabase = async (citationData) => {
        setSubmitButton("Saving...");
        const appeal = citationData.appeals[0];
        const appealBuild = {
            "appealDate": appeal.appealDate,
            "reason": appeal.reason,
            "replyDate": new Date().toISOString(),
            "replyNotes": reason,
            "requestNotes": appeal.requestNotes,
            "reviewedBy": "Admin",
            "status": action === "approve" ? "Approved" : "Denied",
        }

        citationData.appeals[0] = appealBuild;
        let user = null
        for (let i in users) {
            user = users[i];
            const vehicles = user.settings.vehicles;

            for (let j in vehicles) {
                if (vehicles[j].licensePlate === citationData.licensePlate) {
                    const existingCitationIndex = user.citations.findIndex(c => c.citationID === citationData.citationID);
                    if (existingCitationIndex !== -1) {
                        user.citations[existingCitationIndex] = citationData;
                    } else {
                        user.citations.push(citationData);
                    }
                    break
                }
            }
        }

        try {
            const saveData = async () => {
                console.log("Citation data to save:", user);
                const path = "/general/spotUsers"
                const response = await postData(path, user);
                console.log(response.message); //TODO: Add error handling here and all other places
            };

            (async () => {
                await saveData();

                appealedCitations = appealedCitations.length === 1 ? [] : appealedCitations.filter(citation => citation.citationID !== citationData.citationID);

                setCurrentAppeals(appealedCitations);
                handleClose();
                toast.success("Appeal response saved successfully!");
            })();
        } catch (error) {
            console.error("Error saving data:", error);
        }
    };

    return (
        <div className="ml-20 p-8 font-rubik">
            <h1 className="text-4xl rubik-bold pb-6">Pending Appeals</h1>
            <div className="fcc gap-6 w-full">
                {currentAppeals.length === 0 ?
                    <h1 className="mt-8 text-2xl rubik-normal">No appeals to review at this time.</h1> :
                    currentAppeals.map((citation) => {
                        const appeals = citation.appeals;
                        const amount = citation.fee;
                        const formattedFee = amount.includes("$") ? amount : `$${parseFloat(amount).toFixed(2)}`;

                        return (
                            <div key={citation.plate} className="relative flex flex-col w-full bg-gray-200 p-4 rounded-lg shadow-lg max-w-[1000px]">
                                {selectedAppeal && selectedAppeal.citationID === citation.citationID && (
                                    <div className="absolute inset-0 rounded-xl flex items-center justify-center z-10">
                                        <div className="bg-white p-6 rounded-lg shadow-lg">
                                            {action ? (
                                                <div>
                                                    <h2 className="text-2xl mb-4">{action === "approve" ? "Approve Appeal" : "Deny Appeal"}</h2>
                                                    <textarea
                                                        className="w-full p-2 border-2 rounded-lg min-h-12 accent-spotYellow outline-spotYellow"
                                                        placeholder="Enter reason here..."
                                                        value={reason}
                                                        aria-label="Reason for appeal decision"
                                                        onChange={(e) => setReason(e.target.value)}
                                                    />
                                                    <div className="flex gap-4 mt-4">
                                                        <button
                                                            className="bg-green-200 text-spotGray hover:bg-green-500 hover:text-white px-4 py-2 rubik-medium rounded-md"
                                                            onClick={() => saveToAppealDatabase(citation)}
                                                            aria-label="Submit appeal decision"
                                                        >
                                                            {submitButton}
                                                        </button>
                                                        <button
                                                            className="bg-gray-200 text-spotGray hover:bg-gray-500 hover:text-white px-4 py-2 rubik-medium rounded-md"
                                                            onClick={handleClose}
                                                            aria-label="Cancel appeal decision"
                                                        >
                                                            Cancel
                                                        </button>
                                                    </div>
                                                </div>
                                            ) : (
                                                <div>
                                                    <div className="flex gap-4">
                                                        <button
                                                            className="bg-green-200 text-spotGray hover:bg-green-500 hover:text-white px-4 py-2 rubik-medium rounded-md"
                                                            onClick={() => handleActionClick("approve")}
                                                            aria-label="Approve appeal"
                                                        >
                                                            Approve
                                                        </button>
                                                        <button
                                                            className="bg-red-100 text-spotGray hover:bg-red-500 hover:text-white px-4 py-2 rubik-medium rounded-md"
                                                            onClick={() => handleActionClick("deny")}
                                                            aria-label="Deny appeal"
                                                        >
                                                            Deny
                                                        </button>
                                                    </div>
                                                    <button
                                                        className="mt-4 text-gray-700 underline w-full text-center"
                                                        onClick={handleClose}
                                                        aria-label="Close appeal"
                                                    >
                                                        Close
                                                    </button>
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                )}
                                <div className={`frc gap-16 mb-4 transition-all duration-100 ease-in-out ${selectedAppeal && selectedAppeal.citationID === citation.citationID ? "blur-md" : ""}`}>
                                    <div className="flex flex-col items-start gap-2">
                                        <div className="frc gap-2">
                                            <h1 className="text-2xl rubik-medium">Plate #:</h1>
                                            <h1 className="text-2xl font-rubik">{citation.licensePlate}</h1>
                                        </div>
                                        <div className="frc gap-2">
                                            <h1 className="text-lg rubik-medium">Spot:</h1>
                                            <h1 className="text-lg font-rubik">{citation.lot}-{citation.spot}</h1>
                                        </div>
                                        <div className="frc gap-2">
                                            <h1 className="text-lg rubik-medium">Written at:</h1>
                                            <h1 className="text-lg font-rubik">{new Date(citation.timeWritten).toLocaleString()}</h1>
                                        </div>
                                    </div>
                                    <div className="flex flex-col items-end gap-2">
                                        <div className="frc gap-2">
                                            <h1 className="text-2xl rubik-medium">Violation:</h1>
                                            <h1 className="text-2xl font-rubik">{citation.type}</h1>
                                        </div>
                                        <div className="frc gap-2">
                                            <h1 className="text-lg rubik-medium">Fee Amount:</h1>
                                            <h1 className="text-lg font-rubik">{formattedFee}</h1>
                                        </div>
                                        <div className="frc gap-2">
                                            <h1 className="text-lg rubik-medium">Citation ID:</h1>
                                            <h1 className="text-lg font-rubik">{citation.citationID}</h1>
                                        </div>
                                    </div>
                                </div>
                                <div className={`fcc gap-2 w-full transition-all duration-100 ease-in-out ${selectedAppeal && selectedAppeal.citationID === citation.citationID ? "blur-md" : ""}`}>
                                    {appeals.map((appeal) => {
                                        return (
                                            <div key={appeal.appealID} className="flex flex-row justify-center gap-16 w-full bg-gray-100 p-4 rounded-lg shadow-lg cursor-pointer hover:bg-gray-400" onClick={() => handleAppealClick({ ...appeal, citationID: citation.citationID })}>
                                                <div className="fcc gap-2 min-w-fit">
                                                    <div className="frc gap-2">
                                                        <h1 className="text-lg rubik-medium">Appeal Date:</h1>
                                                        <h1 className="text-lg font-rubik">{new Date(appeal.appealDate).toLocaleString()}</h1>
                                                    </div>
                                                    <div className="frc gap-2">
                                                        <h1 className="text-lg rubik-medium">Reason:</h1>
                                                        <h1 className="text-lg font-rubik">{appeal.reason}</h1>
                                                    </div>
                                                </div>
                                                <div className="flex flex-col w-fit gap-2">
                                                    <h1 className="text-xl rubik-medium w-full text-center">Additional Notes:</h1>
                                                    <p className="w-fit text-center rubik-normal break-words">{appeal.requestNotes}</p>
                                                </div>
                                            </div>
                                        );
                                    })}
                                </div>
                            </div>
                        );
                    })}
            </div>
            <Toaster />
        </div>
    );
};