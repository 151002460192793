import React, { useState } from "react";
import events from "../assets/events_full.json";
import NumberTicker from "./NumberTicker.tsx";

const Violators = () => {
    const [dollarAmount, setDollarAmount] = useState(20);

    const allowedSpots = new Set([
        '004-0001', '004-0002', '004-0003', '004-0004', '004-0005',
        '004-0006', '004-0007', '004-0008', '004-0009', '004-0010',
        '004-0011', '004-0012', '004-0013', '004-0014', '004-0015',
        '004-0016', '004-0017'
    ]);

    const { spotViolations, totalViolations, allDates } = React.useMemo(() => {
        let spots = {};
        let allDates = new Set();
        let spotViolations = {};
        let totalViolations = 0;

        events.forEach(event => {
            const { timestamp, changes } = event;
            allDates.add(new Date(timestamp).toDateString());

            Object.entries(changes).forEach(([key, plate]) => {
                if (!spots[key]) {
                    spots[key] = [];
                }
                spots[key].push({ time: timestamp, plate });
            });
        });

        Object.entries(spots).forEach(([key, changes]) => {
            if (allowedSpots.has(key)) {
                spotViolations[key] = changes.reduce((acc, currentChange, i) => {
                    if (i > 0) {
                        const lastChange = changes[i - 1];
                        if (currentChange.plate === "" && lastChange.plate !== "") {
                            const timeDifference = Math.floor((new Date(currentChange.time).getTime() - new Date(lastChange.time).getTime()) / (1000 * 60));
                            if (timeDifference > 30) {
                                acc.push({
                                    timeIn: lastChange,
                                    timeOut: currentChange,
                                    timeParked: timeDifference,
                                });
                                totalViolations++;
                            };
                        };
                    };
                    return acc;
                }, []);
            };
        });

        return { spotViolations, totalViolations, allDates };
    }, [events]);

    const randomDuration = () => Math.random() * (3.5 - 1.5) + 1.5;

    return (
        <div className="p-8 rounded-xl shadow-lg bg-white border flex flex-col gap-4">
            <div className="flex flex-row gap-2">
                <p className="font-bold">Spots Monitored:</p>
                <NumberTicker
                    from={0}
                    target={Object.keys(spotViolations).length}
                    autoStart={true}
                    transition={{ duration: randomDuration(), type: "tween", ease: "easeInOut" }}
                />
            </div>
            <div className="flex flex-row gap-2">
                <p className="font-bold">Total Violations:</p>
                <NumberTicker
                    from={0}
                    target={totalViolations}
                    autoStart={true}
                    transition={{ duration: randomDuration(), type: "tween", ease: "easeInOut" }}
                />
            </div>
            <div className="flex flex-row gap-2">
                <p className="font-bold">Total Days:</p>
                <NumberTicker
                    from={0}
                    target={allDates.size}
                    autoStart={true}
                    transition={{ duration: randomDuration(), type: "tween", ease: "easeInOut" }}
                />
            </div>
            <div className="flex flex-row gap-2">
                <p className="font-bold">Violations/Day:</p>
                <NumberTicker
                    from={0}
                    target={totalViolations / allDates.size}
                    autoStart={true}
                    transition={{ duration: randomDuration(), type: "tween", ease: "easeInOut" }}
                />
            </div>
            <div className="flex flex-col gap-2 justify-center">
                <p className="font-bold">Total Missed Revenue:</p>
                <div className="flex flex-row gap-2 text-xs">
                    <p className="">Violations</p>
                    <p className="">Avg. Ticket Price</p>
                </div>
                <div className="flex flex-row gap-4">
                    <NumberTicker
                        from={0}
                        target={totalViolations}
                        autoStart={true}
                        transition={{ duration: randomDuration(), type: "tween", ease: "easeInOut" }}
                    />
                    <p className="">X</p>
                    <input
                        type="number"
                        value={dollarAmount}
                        onChange={(e) => setDollarAmount(e.target.value)}
                        className="border px-1 w-12 outline-spotYellow"
                        placeholder="Avg. Ticket Price"
                    />
                    <p className="">=</p>
                    <p className="">${dollarAmount * totalViolations}</p>
                </div>
            </div>
        </div>
    );
};

export default Violators;