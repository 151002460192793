import React, { useEffect, useState } from "react";
import { fetchAllData } from "../utility/fetcher";

export const SinglePermit = () => {
    const [allUsers, setAllUsers] = useState([]);
    const [currentPermitUsers, setCurrentPermitUsers] = useState([]);
    const permitData = JSON.parse(sessionStorage.getItem("currentPermit"));

    const storeData = async () => {
        const urls = { "allUsers": "/general/spotUsers" };
        const data = await fetchAllData(urls);
        setAllUsers(data.allUsers);
    }

    useEffect(() => {
        storeData();
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        let builderObj = [];
        allUsers.forEach(user => {
            const currentUserPermits = user.permits;
            const userPersonalInfo = user.settings.personalInfo;
            for (let i in currentUserPermits) {
                if (currentUserPermits[i].type === permitData.permitType) {
                    const expirationDate = new Date(currentUserPermits[i].expiration)
                    const today = new Date();
                    if (expirationDate >= today) {
                        const userObject = {
                            email: user.email,
                            fName: userPersonalInfo.firstName,
                            lName: userPersonalInfo.lastName,
                            permit: currentUserPermits[i]
                        };
                        builderObj.push(userObject);
                        break;
                    }
                }
            }
        });

        builderObj.sort((a, b) => a.lName.localeCompare(b.lName));

        setCurrentPermitUsers(builderObj);
        // eslint-disable-next-line
    }, [allUsers]);

    return (
        <div className="ml-24 p-8">
            <h1 className="w-full text-center font-bold text-3xl p-8">{permitData.permitType} Permit</h1>
            <h2 className="w-full text-center text-2xl pb-4">Current Permits Issued: {currentPermitUsers.length}</h2>
            {currentPermitUsers.length === 0 ? <h1 className="text-center text-2xl">No users have this permit</h1> :
                <table className="w-full text-center border border-gray-400">
                    <thead>
                        <tr className="bg-gray-300 border border-gray-400 shadow-lg">
                            <th>Name</th>
                            <th>Email</th>
                            <th>Expiration Date</th>
                            <th>Confirmation Number</th>
                        </tr>
                    </thead>
                    <tbody>
                        {currentPermitUsers.length !== 0 ? currentPermitUsers.map((user, index) => (
                            <tr key={user.name} className={index % 2 === 0 ? "bg-white" : "bg-gray-100"}>
                                <td>{user.fName} {user.lName}</td>
                                <td>{user.email}</td>
                                <td>{user.permit.expiration}</td>
                                <td>{user.permit.confirmationNumber}</td>
                            </tr>
                        )) : null}
                    </tbody>
                </table>
            }
        </div >
    );
}